
import Vue from 'vue'
import SprFormCheckbox from '@/components/forms/SprFormCheckbox.vue'
import { CheckboxListItem } from '@/types/custom.ts'

export default Vue.extend({
  name: 'SprFormCheckboxList',
  components: { SprFormCheckbox },
  inject: ['$validator'], //need to inject the parent validator or the field will not be available on the parent if it fails. Also inject into any parent components until you get to the top button
  props: {
    value: {
      type: Array as () => Array<CheckboxListItem>,
      default () {
        return []
      }
    },
    label: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    labelLeft: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCheckboxChanged (value: CheckboxListItem) {
      if (this.readonly) return
      value.checked = !value.checked
      this.$emit('input', value)
    }
  }
})
