
import Vue from 'vue'

export default Vue.extend({
  name: 'Logout',
  created () {
    // log em out
    this.$store.dispatch('authentication/logout')
    this.$router.push('/login')
  }
})
