/* eslint-disable @typescript-eslint/camelcase */
const messages = {
  after: (field: string, [target]: any) => `${field} måste vara efter ${target}`,
  alpha: (field: string) => `${field} kan bara innehålla bokstäver`,
  alpha_dash: (field: string) => `${field} kan bara innehålla bokstäver och siffror, bindestreck och understryker`,
  alpha_num: (field: string) => `${field} kan bara innehålla bokstäver och siffror`,
  before: (field: string, [target]: any) => `${field} måste vara före ${target}`,
  between: (field: string, [min, max]: any) => `Fäld "${field}" måste vara mittemellan ${min} och ${max}`,
  confirmed: (field: string, [confirmedField]: any) => `${field} svarade inte på ${confirmedField}`,
  date_between: (field: string, [min, max]: any) => `${field} måste vara mittemellan ${min} ovh ${max}`,
  date_format: (field: string, [format]: any) => `${field} måste vara i ${format}`,
  decimal: (field: string, [decimals = '*'] = []) => `${field} måste vara numeriskt och kan innehålla ${!decimals || decimals === '*' ? '' : ' ' + decimals} decimal`,
  digits: (field: string, [length]: any) => `${field} måste vara numeriskt och exakt ${length} tecken`,
  dimensions: (field: string, [width, height]: any) => `${field} måste vara ${width} pixlar x ${height} pixlar`,
  email: (field: string) => `${field} måste vara giltig e-post`,
  excluded: (field: string) => `${field} måste vara giltig värde`,
  ext: (field: string) => `${field} måste vara giltig fil`,
  image: (field: string) => `${field} måste vara giltig bild`,
  included: (field: string) => `${field} måste vara giltig värde`,
  ip: (field: string) => `${field} måste vara giltig IP-addressen`,
  max: (field: string, [length]: any) => `${field} kan inte vara längre an ${length} tecken`,
  mimes: (field: string) => `${field} måste vara giltig filtyp`,
  min: (field: string, [length]: any) => `${field} måste vara minst ${length} tecken`,
  numeric: (field: string) => `${field} kan innehålla bara siffror`,
  regex: (field: string) => `${field} måste vara giltig vanligt uttryck`,
  required: (field: string) => `${field} är ett obligatoriskt fält`,
  size: (field: string, [size]: any) => `${field} måste vara mindre än ${size}`,
  url: (field: string) => `${field} måste vara giltig  URL-addressen. till exmpel http://www.google.com`,
  finnishdateinpast: (field: string) => `${field} kan inte vara i framtiden`,
  finnishdateinfuture: (field: string) => `${field} måste vara i framtiden`
}

// These are the names of the fields, replaced in '${field}' above.
const attributes = {
  meeting_name: 'Namnet på mötet',
  meeting_type: 'Typ',
  meeting_dateheld: 'Tidpunkt',
  memberposition_position: 'Förtroendeuppdrag',
  memberposition_member: 'En person',
  memberposition_electeddate: 'Vald',
  memberposition_termstart: 'Terminen börjar',
  memberposition_termend: 'Terminen tar slut',
  memberposition_nonmemberfirstname: 'Förnamn',
  memberposition_nonmemberlastname: 'Efternamn',
  memberposition_nonmemberphone: 'Telefon',
  memberposition_nonmemberphonerequired: 'Telefon',
  memberposition_nonmemberemail: 'E-post',
  memberposition_nonmemberemailrequired: 'E-post',
  memberposition_nonmemberpostcode: 'Postnummer',
  memberposition_nonmemberpostoffice: 'Plats',
  memberposition_nonmembercompany: 'Företag',
  memberposition_nonmemberaddress: 'Gatuadress',

  electeddate: 'Tidpunkt',
  termstart: 'Terminen börjar',
  section_phone: 'Telefon',
  section_email: 'E-post',
  section_url: 'Avdelning URL',
  address_address1: 'Gatuadress',
  address_address2: 'Gatuadress 2',
  address_postalcode: 'Postnummer',
  address_city: 'Stad',
  address_country: 'Land'
}

const locale = {
  name: 'sv',
  messages,
  attributes: attributes
}

export default locale