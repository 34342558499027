
import Vue from 'vue'
import Tooltip from '@/components/Tooltip.vue'

export default Vue.extend({
  name: 'SprFormText',
  components: { Tooltip },
  inject: ['$validator'], //need to inject the parent validator or the field will not be available on the parent if it fails. Also inject into any parent components until you get to the top button
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    validations: {
      type: [String, Object],
      default: undefined
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    useTooltip: {
      type: Boolean,
      default: false
    },
    tooltipMessage: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      localValue: '',
      tooltipId: 'tooltipText_'

    }
  },
  methods: {
    generateUUID (): string {
      let d = new Date().getTime()

      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now() //use high-precision timer if available
      }

      const newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })

      return newGuid
    }
  },
  mounted () {
    // local copies to prevent 'props being modified directly' warning.
    this.localValue = this.value
    this.tooltipId = 'tooltipDropDown_' + this.generateUUID()
  }
})
