
import Vue from 'vue'
import { MemberPositionResponse } from '@/types'

export default Vue.extend({
  name: 'MeetingCrmErrors',
  props: {
    memberPositions: {
      type: Array,
      default: () => [] as Array<MemberPositionResponse>
    }
  }
})
