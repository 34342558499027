
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { MemberLightResponse } from '@/types'

export default Vue.extend({
  name: 'MemberBasicInfoRow',
  props: {
    member: {
      type: Object as () => MemberLightResponse,
      default: undefined
    }
  },
  data () {
    return {
      isMoreDetailsShown: false
    }
  },
  computed: {
    fullName (): string {
      return `${this.member.lastName}, ${this.member.firstName}`
    },
    sectionName (): string {
      const section = this.$store.getters['sections/getById'](this.member.sectionId)
      return section ? section.name : ''
    }
  },
  methods: {
    getMembershipStatus (statusId: number): TranslateResult {
      return this.$t(`membershipstatus-${statusId}`)
    },
    fullnameClicked () {
      this.$router.push(`/sections/${this.member.sectionId}/members/${this.member.id}`)
    },
    sectionClicked () {
      this.$router.push(`/sections/${this.member.sectionId}`)
    }
  }
})
