
import Vue from 'vue'
import moment from 'moment'
import { isAddressEmpty } from '@/helpers/addresshelper'
import { MemberSummaryResponse, MemberPositionSummaryResponse, AddressResponse } from '@/types'
import { finnishShortDate } from '@/helpers/datehelper'

export default Vue.extend({
  name: 'MemberInfoRow',
  props: {
    member: {
      type: Object as () => MemberSummaryResponse,
      default: undefined
    },
    showSection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMoreDetailsShown: false
    }
  },
  computed: {
    fullName (): string {
      return `${this.member.firstName} ${this.member.lastName}`
    },
    noPositions () {
      return this.member.memberPositions && this.member.memberPositions.length === 0
    },
    paymentDate (): string {
      return this.member.paymentDate ? finnishShortDate(this.member.paymentDate) : '-'
    },
    membershipApproved (): string {
      return this.member.membershipApproved ? finnishShortDate(this.member.membershipApproved) : '-'
    },
    sectionName (): string {
      const section = this.$store.getters['sections/getById'](this.member.sectionId)
      return section ? section.name : ''
    },
    orderedPositions (): Array<MemberPositionSummaryResponse> {
      // slice to clone sorted array
      if (!this.member.memberPositions) return []
      return this.member.memberPositions.slice(0).sort((a: MemberPositionSummaryResponse, b: MemberPositionSummaryResponse) => {
        const starta: number = a.termStart.valueOf()
        const startb: number = b.termStart.valueOf()
        if (starta > startb) return -1
        if (starta === startb) return 0
        return 1
      })
    },
    hasAddress (): boolean {
      return !isAddressEmpty(this.member.address)
    }
  },
  methods: {
    dob (date: Date) {
      return finnishShortDate(date)
    },
    formatDate (date: Date) {
      return finnishShortDate(date)
    },
    fullnameClicked () {
      this.$router.push(`/sections/${this.member.sectionId}/members/${this.member.id}`)
    }
  }
})
