
import Vue from 'vue'
import authHeader from '../../helpers/auth/auth-header'
import { processJson } from '../../helpers/httphelper'
import SprButton from '@/components/forms/SprButton.vue'
import MemberPositionForm from '@/components/meetings/MemberPositionForm.vue'
import { formatErrors } from '@/helpers/validationhelper'
import { AddMemberPositionRequest, CheckSerialTermLimitsRequest, CheckNonMemberSerialTermLimitsRequest, CheckTermLengthRequest, RequestValidationResult, ErrorResult, MemberPositionResponse } from '../../types'
import { meetingService } from '@/services'
import { MemberPositionFormData, isRequestValidationResult } from '@/types/custom'
import { AlertTimeout, MeetingType } from '@/types/enums'

export default Vue.extend({
  name: 'AddMemberPosition',
  components: { MemberPositionForm, SprButton },
  props: {
    sectionId: {
      type: Number,
      required: true
    },
    meetingId: {
      type: Number,
      required: true
    }
  },
  data (this: Vue) {
    return {
      memberPosition: {} as AddMemberPositionRequest,
      showForm: false
    }
  },
  computed: {
    termEndByMeetingType () {
      // defaultPositionEndDate is set on the Backend MeetingDefaultPositionInfo.GetDefaultPositionEndDate(), accessed via GetMeeting or Add on Meetings Controller.
      const endFromInfo = this.$store.state.meetings.selectedMeeting.meetingDefaultPositionInfo.defaultPositionEndDate

      return endFromInfo
    },
    termStartByMeetingType () {
      // defaultPositionStartDate is set on the Backend MeetingDefaultPositionInfo.GetDefaultPositionStartDate(), accessed via GetMeeting or Add on Meetings Controller.
      const startFromInfo = this.$store.state.meetings.selectedMeeting.meetingDefaultPositionInfo.defaultPositionStartDate

      return startFromInfo
    },
    memberPositionFormData () {
      return {
        memberId: this.memberPosition.memberId,
        positionId: this.memberPosition.positionId,
        nonMemberAddress: this.memberPosition.nonMember.address,
        nonMemberEmail: this.memberPosition.nonMember.email,
        nonMemberFirstname: this.memberPosition.nonMember.firstname,
        nonMemberLastname: this.memberPosition.nonMember.lastname,
        nonMemberCompany: this.memberPosition.nonMember.company,
        nonMemberPhone: this.memberPosition.nonMember.phone,
        nonMemberPostcode: this.memberPosition.nonMember.postcode,
        nonMemberPostoffice: this.memberPosition.nonMember.postoffice,
        termEnd: this.memberPosition.termEnd,
        termStart: this.memberPosition.termStart
      } as MemberPositionFormData
    }
  },
  methods: {
    formatParametrizedError (err: ErrorResult): string {
      const template = err.translationKey ? this.$t(err.translationKey) : err.error
      let errorMessage = template.toString()
      if (err.hasTranslationParameters) {
        const parameters = err.translationParameters
        parameters.forEach(parameter => {
          errorMessage = errorMessage.toString().replace(parameter.key, parameter.value)
        })
      }
      return errorMessage
    },
    getValidationHtml (this: any, err: RequestValidationResult): string {
      const hasAnyParamerizedErrors = this.hasParamerizedErrors(err)
      if (hasAnyParamerizedErrors) {
        const errorMessages = err.errors.map((x: ErrorResult) => this.formatParametrizedError(x))
        const message = this.$t('validation.htmlheadertext').toString().replace('{{ errors }}', formatErrors(errorMessages))
        return message
      }
      const errorMessages = err.errors.map((x: ErrorResult) => x.translationKey ? this.$t(`validation.${x.translationKey}`) : x.error)
      const message = this.$t('validation.htmlheadertext').toString().replace('{{ errors }}', formatErrors(errorMessages))
      return message
    },
    hasParamerizedErrors (this: any, err: RequestValidationResult): boolean {
      let anyParamerizedErrors = false
      err.errors.forEach(element => {
        if (element.hasTranslationParameters) {
          anyParamerizedErrors = true
        }
      })
      return anyParamerizedErrors
    },
    reset (this: any): void {
      this.$validator.reset()
      this.memberPosition = this.getMemberPosition()
      this.showForm = false
    },
    getMemberPosition (this: any): AddMemberPositionRequest {
      const meetingDate = this.$store.state.meetings.selectedMeeting.dateHeld
      meetingDate.setUTCHours(0)
      meetingDate.setUTCMinutes(0)
      meetingDate.setUTCSeconds(0)
      meetingDate.setUTCMilliseconds(0)

      return {
        memberId: undefined,
        positionId: 0,
        meetingId: this.meetingId,
        nonMember: {
          firstname: '',
          lastname: '',
          address: '',
          phone: '',
          email: '',
          company: '',
          postcode: '',
          postoffice: ''
        },
        termStart: this.termStartByMeetingType, // firstOfNextYear, // should default to 1.1.(this year + 1)
        termEnd: this.termEndByMeetingType // endYear // should default to 31.12.(this year + 1)
      } as AddMemberPositionRequest
    },
    onInputMemberPositionForm (val: MemberPositionFormData) {
      if (val.positionId) {
        this.memberPosition.positionId = val.positionId
      }
      this.memberPosition.memberId = val.memberId
      this.memberPosition.nonMember.firstname = val.nonMemberFirstname
      this.memberPosition.nonMember.lastname = val.nonMemberLastname
      this.memberPosition.nonMember.address = val.nonMemberAddress
      this.memberPosition.nonMember.phone = val.nonMemberPhone
      this.memberPosition.nonMember.email = val.nonMemberEmail
      this.memberPosition.nonMember.company = val.nonMemberCompany
      this.memberPosition.nonMember.postcode = val.nonMemberPostcode
      this.memberPosition.nonMember.postoffice = val.nonMemberPostoffice
      this.memberPosition.termStart = val.termStart
      this.memberPosition.termEnd = val.termEnd
    },
    onPositionTypeUpdated (positionType: number) {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      }

      const meetingType = this.$store.state.meetings.selectedMeeting.type.id
      const dateHeld = this.$store.state.meetings.selectedMeeting.dateHeld

      const yearUrl = `${this.$store.state.config.ossiServiceBaseUrl}${this.$store.state.meetings.meetingTypeDefaultYearUrl}`.replace('{meetingType}', meetingType)
      const defaultDateEndForPosition = `${this.$store.state.config.ossiServiceBaseUrl}${this.$store.state.meetings.getDefaultEndDateForPositionUrl}`
        .replace('{dateHeld}', dateHeld.toISOString())
        .replace('{positionId}', positionType.toString())
        .replace('{meetingType}', meetingType)

      const yearPromise = fetch(yearUrl, requestOptions)
        .then(processJson)
        .catch(err => Promise.reject(err))

      const defaultDateEndForPositionPromise = fetch(defaultDateEndForPosition, requestOptions)
        .then(processJson)
        .catch(err => Promise.reject(err))

      return Promise.all([yearPromise, defaultDateEndForPositionPromise])
        .then(([year, defaultDateEnd]) => {
          Vue.set(this.memberPositionFormData, 'termEnd', defaultDateEnd)
        })
        .catch(err => Promise.reject(err))
    },
    onAdd (this: any): void {
      this.showForm = true
    },
    onCancel (this: any): void {
      this.reset()
    },
    onSave (this: any): void {
      this.$store.commit('alert/dataLoading', null, { root: true })
      this.$validator.validateAll()
        .then((success: boolean) => {
          if (!success) {
            return Promise.reject(new Error('Validation failed'))
          }
          // Check the proposed position is within term limits
          const termData: CheckTermLengthRequest = {
            positionId: this.memberPosition.positionId,
            termStart: this.memberPosition.termStart,
            termEnd: this.memberPosition.termEnd
          }

          return meetingService.checkTermLength(termData)
        })
        .then((termLengthValidationResult: RequestValidationResult): Promise<RequestValidationResult> => {
          // Check this position does not exceed serial term limits (eg cannot be a Puheenjohtaja more than 3 times in a row)
          return new Promise((resolve, reject) => {
            // If they are members, check the term limits
            if (this.memberPosition.memberId) {
              const serialData: CheckSerialTermLimitsRequest = {
                meetingId: this.meetingId,
                positionId: this.memberPosition.positionId,
                memberId: this.memberPosition.memberId
              }
              meetingService.checkAddSerialTermLimits(serialData).then((serialTermsValidationResult: RequestValidationResult) => {
                if (!termLengthValidationResult.isValid) {
                  // amalgamate the term validation errors to this one
                  for (const err of termLengthValidationResult.errors) {
                    serialTermsValidationResult.errors.push(err)
                  }
                }
                resolve(serialTermsValidationResult)
              })
            } else {
              // check non member term limits
              const serialData: CheckNonMemberSerialTermLimitsRequest = {
                meetingId: this.meetingId,
                positionId: this.memberPosition.positionId,
                firstName: this.memberPosition.nonMember.firstname,
                lastName: this.memberPosition.nonMember.lastname
              }
              meetingService.checkAddNonMemberSerialTermLimits(serialData).then((serialTermsValidationResult: RequestValidationResult) => {
                if (!termLengthValidationResult.isValid) {
                  // amalgamate the term validation errors to this one
                  for (const err of termLengthValidationResult.errors) {
                    serialTermsValidationResult.errors.push(err)
                  }
                }
                resolve(serialTermsValidationResult)
              })
            }
          })
        })
        .then((result: RequestValidationResult) => {
          //check this member position doesn't already exist
          return new Promise<RequestValidationResult>((resolve, reject) => {
            let existing: MemberPositionResponse | undefined
            if (this.memberPosition.memberId) {
              existing = this.$store.state.meetings.selectedMeeting.memberPositions.find((x: MemberPositionResponse) => x.position?.id === this.memberPosition.positionId && x.member?.id === this.memberPosition.memberId)
            } else {
              existing = this.$store.state.meetings.selectedMeeting.memberPositions.find((x: MemberPositionResponse) => x.position?.id === this.memberPosition.positionId && x.nonMember.firstName?.toLowerCase() === this.memberPosition.nonMember.firstname?.toLowerCase() && x.nonMember.lastName?.toLowerCase() === this.memberPosition.nonMember.lastname?.toLowerCase())
            }

            if (existing) {
              result.errors.push(
                {
                  error: this.$t('editmeeting.memberpositionalreadyexists')
                } as ErrorResult
              )
              result.isValid = false
            }
            if (result.isValid) {
              // All good so far, try saving next.
              resolve(result)
            } else {
              // We have collected all the errors at this point. No point in carrying on to saving, reject and show them
              reject(result)
            }
          })
        })
        .then((result: RequestValidationResult) => {
          // Do the actual saving, if there are no errors
          return new Promise<MemberPositionResponse>((resolve, reject) => {
            this.$store.dispatch('meetings/addPositionOfTrust', this.memberPosition).then((success: MemberPositionResponse) => {
              resolve(success)
            }, (err: any | RequestValidationResult) => {
              reject(err)
            })
          })
        })
        .then((response: MemberPositionResponse) => {
          this.$emit('add-memberposition', response)
          this.reset()
          this.$store.dispatch('alert/success', { message: this.$t('editmeeting.updatesuccess'), timeout: AlertTimeout.SHORT })
          this.$store.commit('alert/dataLoaded', null, { root: true })
        })
        .catch((errors: any | RequestValidationResult) => {
          // Show the errors if there are any
          const checkIsRequestValidationResult = isRequestValidationResult(errors)
          if (checkIsRequestValidationResult) {
            this.$store.dispatch('alert/error', { message: this.getValidationHtml(errors, this.$t), isHtml: true })
          } else {
            this.$store.dispatch('alert/error', { message: this.$t('editmeeting.memberpositionaddfailed') })
          }
          this.$store.commit('alert/dataLoaded', null, { root: true })
        })
    }
  },
  created (this: any): void {
    this.reset()
  }
})
