/* eslint-disable @typescript-eslint/camelcase */
const messages = {
  after: (field: string, [target]: any) => `${field} tulee olla ${target} jälkeen`,
  alpha: (field: string) => `${field} voi sisältää vain kirjaimia`,
  alpha_dash: (field: string) => `${field} voi sisältää vain kirjaimia, numeroita, ja tavu-, tai alaviivoja`,
  alpha_num: (field: string) => `${field} voi sisältää vain kirjaimia ja numeroita`,
  before: (field: string, [target]: any) => `${field} tulee olla ennen ${target}`,
  between: (field: string, [min, max]: any) => `Kentän ${field} tulee olla ${min} ja ${max} väliltä`,
  confirmed: (field: string, [confirmedField]: any) => `${field} ei vastannut ${confirmedField}`,
  date_between: (field: string, [min, max]: any) => `${field} tulee olla ${min} ja ${max} väliltä`,
  date_format: (field: string, [format]: any) => `${field} tulee olla muodossa ${format}`,
  decimal: (field: string, [decimals = '*'] = []) => `${field} tulee olla numeerinen ja voi sisältää${!decimals || decimals === '*' ? '' : ' ' + decimals} desimaalia`,
  digits: (field: string, [length]: any) => `${field} tulee olla numeerinen ja tarkalleen ${length} merkkiä`,
  dimensions: (field: string, [width, height]: any) => `${field} tulee olla ${width} pikseliä kertaa ${height} pikseliä`,
  email: (field: string) => `${field} tulee olla kelvollinen sähköpostiosoite`,
  excluded: (field: string) => `${field} tulee olla kelvollinen arvo`,
  ext: (field: string) => `${field} tulee olla kelvollinen tiedosto`,
  image: (field: string) => `${field} tulee olla kelvollinen kuva`,
  included: (field: string) => `${field} tulee olla kelvollinen arvo`,
  ip: (field: string) => `${field} tulee olla kelvollinen IP-osoite`,
  max: (field: string, [length]: any) => `${field} ei saa olla pidempi kuin ${length} merkkiä`,
  mimes: (field: string) => `${field} tulee olla kelvollinen tiedostotyyppi`,
  min: (field: string, [length]: any) => `${field} tulee olla vähintään ${length} merkkiä`,
  numeric: (field: string) => `${field} voi sisältää vain numeroita`,
  regex: (field: string) => `${field} tulee olla kelvollinen säännöllinen lauseke`,
  required: (field: string) => `${field} on pakollinen kenttä`,
  size: (field: string, [size]: any) => `${field} tulee olla vähemmän kuin ${size}`,
  url: (field: string) => `${field} tulee olla kelvollinen URL-osoite. esim. http://www.google.com`,
  finnishdateinpast: (field: string) => `${field} ei voi olla tulevaisuudessa`,
  finnishdateinfuture: (field: string) => `${field} pitää olla tulevaisuudessa`
}

// These are the names of the fields, replaced in '${field}' above.
const attributes = {
  meeting_name: 'Kokouksen nimi',
  meeting_type: 'Tyyppi',
  meeting_dateheld: 'Ajankohta',
  memberposition_position: 'Luottamustoimi',
  memberposition_member: 'Henkilö',
  memberposition_electeddate: 'Valittu',
  memberposition_termstart: 'Kausi alkaa',
  memberposition_termend: 'Kausi päättyy',
  memberposition_nonmemberfirstname: 'Etunimi',
  memberposition_nonmemberlastname: 'Sukunimi',
  memberposition_nonmemberphone: 'Puhelin',
  memberposition_nonmemberphonerequired: 'Puhelin',
  memberposition_nonmemberemail: 'Sähköposti',
  memberposition_nonmemberemailrequired: 'Sähköposti',
  memberposition_nonmemberpostcode: 'Postinumero',
  memberposition_nonmemberpostoffice: 'Paikkakunta',
  memberposition_nonmembercompany: 'Yritys',
  memberposition_nonmemberaddress: 'Katuosoite',

  electeddate: 'Ajankohta',
  termstart: 'Kausi alkaa',
  section_phone: 'Puhelin',
  section_email: 'Sähköposti',
  section_url: 'Osasto URL',
  address_address1: 'Katuosoite',
  address_address2: 'Katuosoite 2',
  address_postalcode: 'Postinumero',
  address_city: 'Paikkakunta',
  address_country: 'Maa'
}

const locale = {
  name: 'fi',
  messages,
  attributes: attributes
}

export default locale