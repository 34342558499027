
import Vue from 'vue'
import Back from '@/components/Back.vue'
import MembersList from '@/components/members/MembersList.vue'
import SprFormText from '@/components/forms/SprFormText.vue'
import Pager from '@/components/Pager.vue'
import debounce from 'debounce'
import { PagedListResponse, BaseFindMemberRequest, MemberLightResponse } from '@/types'
import { membersService } from '@/services/member.service'

export default Vue.extend({
  name: 'Members',
  components: {
    Back,
    MembersList,
    SprFormText,
    Pager
  },
  props: {
    sectionId: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      memberSearchResult: undefined as undefined | PagedListResponse<MemberLightResponse>,
      filter: { // the actual filter passed to the back end
        name: '',
        skip: 0,
        take: 50
      } as BaseFindMemberRequest,
      abortController: undefined as undefined | AbortController
    }
  },
  computed: {
    debouncedMemberSearch (): Function {
      return debounce(this.getMembers, 800)
    }
  },
  methods: {
    getMembers (): void {
      // if there is currently a request going on, abort it
      if (this.abortController && !this.abortController.signal.aborted) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()

      this.$store.commit('alert/dataLoading', null, { root: true })
      membersService.findAll(this.filter, this.abortController.signal).then((results: PagedListResponse<MemberLightResponse>) => {
        this.memberSearchResult = results
        this.$store.commit('alert/dataLoaded', null, { root: true })
      }, (err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err)
        if (err.name !== 'AbortError') { // do not show error message if its just an abort
          this.$store.dispatch('alert/error', { message: this.$t('members.findfailed') })
          this.$store.commit('alert/dataLoaded', null, { root: true })
        }
      }).catch(err => {
        this.$store.commit('alert/dataLoaded', null, { root: true })
        // eslint-disable-next-line no-console
        console.log(err)
        this.$store.dispatch('alert/error', { message: this.$t('members.findfailed') })
      })
    },
    onNameSelected (filterstr: string): void {
      this.filter.name = filterstr
      this.debouncedMemberSearch()
    },
    pageToStart (): void {
      this.filter.skip = 0
      this.getMembers()
    },
    pageBack (): void {
      // @ts-ignore
      this.filter.skip -= this.filter.take
      this.getMembers()
    },
    pageForward (): void {
      // @ts-ignore
      this.filter.skip += this.filter.take
      this.getMembers()
    },
    pageToEnd (): void {
      if (this.memberSearchResult) {
        // @ts-ignore
        this.filter.skip = Math.floor(this.memberSearchResult.total / this.filter.take) * this.filter.take
        this.getMembers()
      }
    }
  },
  created (this: any): void {
    this.getMembers()
  }
})
