
import moment from 'moment'
import Vue from 'vue'
import Loading from '@/components/Loading.vue'
import { GetDistrictMembersExportRequest, GetSectionMembersExportRequest, MemberSummaryResponse, MemberLightResponse } from '@/types'
import MemberInfoRow from '@/components/members/MemberInfoRow.vue'
import MemberBasicInfoRow from '@/components/members/MemberBasicInfoRow.vue'
import SprButton from '@/components/forms/SprButton.vue'
import { membersService } from '@/services/member.service'
import { MemberFilter } from '@/types/custom'
import { MembershipStatus } from '@/types/enums'

export default Vue.extend({
  name: 'MembersList',
  components: { Loading, MemberInfoRow, MemberBasicInfoRow, SprButton },
  props: {
    searchCriteria: {
      type: Object,
      default () {
        return {
          name: '',
          approved: true,
          hasMembership: true,
          membershipStatus: MembershipStatus.ALL
        } as MemberFilter
      }
    },
    mode: {
      type: String,
      default: ''
    },
    districtId: {
      type: String,
      required: false,
      default: null
    },
    sectionId: {
      type: String,
      required: false,
      default: null
    },
    value: {
      type: Array,
      default: undefined
    },
    listType: {
      type: String,
      default: 'MemberSummaryResponse' // || MemberLightResponse
    },
    showSection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataLoading: false,
      membersExportFile: null
    }
  },
  computed: {
    showExport () {
      if (this.dataLoading) {
        return false
      }
      if (this.mode === 'District') {
        return true
      }
      if (this.mode === 'Section') {
        return true
      }
      return false
    },
    memberInfoComponent (): string {
      return this.listType === 'MemberLightResponse'
        ? 'MemberBasicInfoRow'
        : 'MemberInfoRow'
    },
    noResults (): boolean {
      return !this.value || !this.value.length
    }
  },
  methods: {
    onMembersExport () {
      if (this.mode === 'Section') {
        this.onMembersExportSection()
      } else {
        this.onMembersExportDistrict()
      }
    },
    onMembersExportDistrict () {
      this.dataLoading = true
      const extraMembershipStatuses = this.searchCriteria.extraMembershipStatuses
      const mergedMembershipStatuses = [this.searchCriteria.membershipStatus].concat(extraMembershipStatuses)
      const criteria = {
        name: this.searchCriteria.name,
        hasCurrentMembership: this.searchCriteria.hasMembership,
        isMembershipApproved: this.searchCriteria.approved,
        membershipStatuses: mergedMembershipStatuses,
        districtId: Number(this.districtId)
      } as GetDistrictMembersExportRequest

      membersService.getDistrictMembersExportFile(criteria).then((result: Blob) => {
        const dateAsTimestamp = new Date().toISOString().substr(0, 19).replace('T', '-').replace(':', '-').replace(':', '-')
        const fileName = `DistrictMembersExport-${this.districtId}-${dateAsTimestamp}.csv`
        const dataUrl = window.URL.createObjectURL(result)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = dataUrl
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(dataUrl)
        this.dataLoading = false
      }).catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.dataLoading = false
        this.$store.dispatch('alert/error', { message: this.$t('report.failed') })
      })
    },
    onMembersExportSection () {
      this.dataLoading = true
      const extraMembershipStatuses = this.searchCriteria.extraMembershipStatuses
      const mergedMembershipStatuses = [this.searchCriteria.membershipStatus].concat(extraMembershipStatuses)
      const criteria = {
        name: this.searchCriteria.name,
        hasCurrentMembership: this.searchCriteria.hasMembership,
        isMembershipApproved: this.searchCriteria.approved,
        membershipStatuses: mergedMembershipStatuses,
        sectionId: Number(this.sectionId)
      } as GetSectionMembersExportRequest

      membersService.getSectionMembersExportFile(criteria).then((result: Blob) => {
        const dateAsTimestamp = new Date().toISOString().substr(0, 19).replace('T', '-').replace(':', '-').replace(':', '-')
        const fileName = `SectionMembersExport-${this.sectionId}-${dateAsTimestamp}.csv`
        const dataUrl = window.URL.createObjectURL(result)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = dataUrl
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(dataUrl)
        this.dataLoading = false
      }).catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.dataLoading = false
        this.$store.dispatch('alert/error', { message: this.$t('report.failed') })
      })
    }
  }
})
