
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import SprFormDropdown from '@/components/forms/SprFormDropdown.vue'
import MeetingStatus from '@/components/meetings/MeetingStatus.vue'
import { finnishShortDate } from '../../helpers/datehelper'
import { MeetingSummaryResponse, MemberPositionErrorResponse, MemberPositionResponse } from '@/types'
import { DropdownOptions } from '@/types/custom.ts'
import { CodeCategory } from '@/types/enums'
import { meetingService } from '@/services/meeting.service.ts'

export default Vue.extend({
  name: 'DistrictPositionOfTrustErrors',
  props: {
    districtId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      crmErrors: [] as Array<MemberPositionErrorResponse>
    }
  },
  mounted () {
    // @ts-ignore
    meetingService.getDistrictCrmPositionsErrors(this.districtId).then((result: Array<MemberPositionErrorResponse>) => {
      this.crmErrors = result
    })
  },
  methods: {
    getSection (sectionId: number) {
      const section = this.$store.getters['sections/getById'](sectionId)
      return section?.name
    },
    getPosition (positionId: number): string {
      const position = (this.$store.state.meetings.positionsOfTrust.find((x: MemberPositionResponse) => x.id === positionId))
      return position ? position.name : ''
    },
    onMeetingClicked (error: MemberPositionErrorResponse) {
      this.$router.push(`/sections/${error.sectionId}/meetings/${error.meetingId}`)
    }
  }
})
