
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import DatePicker from '@/components/forms/SprFormDatePicker.vue'
import SprFormText from '@/components/forms/SprFormText.vue'
import SprFormDropdown from '@/components/forms/SprFormDropdown.vue'
import SprFormTextArea from '@/components/forms/SprFormTextArea.vue'
import { MeetingTypeCode } from '@/helpers/codehelper'
import { finnishShortDate } from '../../helpers/datehelper'
import { MeetingType } from '@/types/enums'
import { MeetingDetailsData } from '@/types/custom.ts'

export default Vue.extend({
  name: 'MeetingDetailsForm',
  inject: ['$validator'],
  components: {
    DatePicker,
    SprFormText,
    SprFormDropdown,
    SprFormTextArea
  },
  props: {
    value: {
      type: Object as () => MeetingDetailsData,
      default: null
    },
    isReadOnly: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      meetingTypes: [] as Array<MeetingType>
    }
  },
  computed: {
    localVal (): MeetingDetailsData {
      return this.value as MeetingDetailsData
    },
    meetingTypeString (this: any): string {
      return this.localVal && this.localVal.meetingType ? this.localVal.meetingType.toString() : ''
    },
    dateHeldString () {
      if (!this.localVal) return ''
      return finnishShortDate((this.localVal as MeetingDetailsData).dateHeld)
    },
    dateHeldDate (this: any): Date {
      if (!this.localVal) return new Date()
      return this.localVal.dateHeld
    }
  },
  methods: {
    getMeetingType (type: number): TranslateResult {
      return this.$t(`meetingtype-${type}`)
    },
    onMeetingTypeUpdated (typeId: string) {
      if (typeId && !isNaN(Number(typeId))) {
        (this.localVal as MeetingDetailsData).meetingType = Number(typeId)

        this.$emit('meetingTypeUpdated', Number(typeId))
        // @ts-ignore
        this.update()
      }
    },
    onDateHeldUpdated (date: Date) {
      (this.localVal as MeetingDetailsData).dateHeld = date
      // @ts-ignore
      this.update()
    },
    onNameUpdated (name: string) {
      (this.localVal as MeetingDetailsData).name = name
      // @ts-ignore
      this.update()
    },
    onMoreInfoUpdated (moreInfo: string) {
      (this.localVal as MeetingDetailsData).moreInfo = moreInfo
      // @ts-ignore
      this.update()
    },
    update () {
      this.$emit('input', this.localVal)
    }
  },
  created (this: any): void {
    let types: Array<MeetingTypeCode> = this.$store.state.codes.meetingTypes
    // Type Unknown is for old legacy imported meetings. Do not allow it on new ones.
    if (!this.value.id) {
      types = this.$store.state.codes.meetingTypes.filter((x: MeetingTypeCode) => x.id !== MeetingType.UNKNOWN)
      types = types.filter((x: MeetingTypeCode) => x.id !== MeetingType.CRM)
    }

    const arr: Array<Record<string, any>> = types.map((x: MeetingTypeCode) => {
      return { text: this.$t(`meetingtype-${x.id}`), value: x.id.toString() }
    })

    arr.unshift({ text: `${this.$t('dropdown.choose')}`, value: '' })

    this.meetingTypes = arr
  }
})
