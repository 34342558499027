
import Vue from 'vue'
import { YesNoWarning } from '@/types/custom'

export default Vue.extend({
  name: 'TickCrossWarning',
  props: {
    value: {
      type: Number as () => YesNoWarning,
      default: YesNoWarning.No
    }
  },
  computed: {
    isYes (): boolean {
      return this.value === YesNoWarning.Yes
    },
    isNo (): boolean {
      return this.value === YesNoWarning.No
    },
    isWarning (): boolean {
      return this.value === YesNoWarning.Warning
    }
  }
})
