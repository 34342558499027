// import config from 'config'
import store from '../store'
import { tokenStructureIsValid } from '../helpers/auth/authorizationhelper'
import { LoginInfo, ADLoginInfo } from '@/types/custom'
import axios from 'axios'

function login (loginInfo: LoginInfo): Promise<string> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(loginInfo)
  }

  return axios.post(`${store.state.config.ossiServiceBaseUrl}${store.state.authentication.loginUrl}`, loginInfo, requestOptions)
    .then(response => {
      // Check authentication status
      if (response.status === 200) {
        const token = response.data

        // Resolve with the response
        return Promise.resolve(token)
      } else {
        if (response.status === 401) {
          // Log them out by sending them to the login page
          if (response.data.includes('User has no roles')) {
            location.href = '/login'

            // Reject with an error message
            return Promise.reject(new Error('Auth failed'))
          }
          if (response.data.includes('Password has expired')) {
            location.href = '/login'
            return Promise.reject(Error('Password expired'))
          }
        }
        return Promise.reject(new Error(`Login failed`))
      }
    })
    .then(token => {
      if (token) {
        // validate basic structure
        if (!tokenStructureIsValid(token)) {
          return Promise.reject(new Error('Token structure is invalid'))
        }
        // store jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('authToken', token)
        return Promise.resolve(token)
      }
      return Promise.reject(new Error('No token'))
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

function logout () {
  // remove token from local storage to log user out
  localStorage.removeItem('authToken')
}

/// Takes the response from the AD login request and exchanges an SPR token for it
function adLogin (adLogin: ADLoginInfo): Promise<string> {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.authentication.activeDirectory.adAuthorizeUri}?token=${adLogin.token}&sessionState=${adLogin.sessionState}`

  return axios.get(url, requestOptions)
    .then(response => {
      // Check authentication status
      if (response.status === 200) {
        return response.data
      } else {
        return Promise.reject(new Error('Failed to get token'))
      }
    })
    .then(token => {
      // login successful if there's a jwt token in the response
      if (!token) {
        return Promise.reject(new Error('No token'))
      }

      if (!tokenStructureIsValid(token)) {
        return Promise.reject(new Error('Invalid token'))
      }

      // store jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('authToken', token)
      return token
    })
    .catch(error => {
      return Promise.reject(new Error(`Login Failed - ${error}`))
    })
}

export const userService = {
  login,
  logout,
  adLogin
}
