import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { config, ConfigState } from './config.module'
import { alert, AlertState } from './alert.module'
import { authentication, AuthenticationState } from './authentication.module'
import { codes, CodeState } from './code.module'
import { districts, DistrictState } from './districts.module'
import { sections, SectionState } from './sections.module'
import { members, MemberState } from './members.module'
import { meetings, MeetingState } from './meetings.module'

Vue.use(Vuex)

// add any store state properties in here
export interface RootState {
    config: ConfigState,
    alert: AlertState,
    authentication: AuthenticationState,
    codes: CodeState,
    districts: DistrictState,
    sections: SectionState,
    members: MemberState,
    meetings: MeetingState
}

const store: StoreOptions<RootState> = {
  modules: {
    config,
    alert,
    authentication,
    codes,
    districts,
    sections,
    members,
    meetings
  }
}

export default new Vuex.Store<RootState>(store)
