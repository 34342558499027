
import Vue from 'vue'
import { debounce } from 'debounce'
import MemberSearchCriteria from '@/components/members/MemberSearchCriteria.vue'
import { PagedListResponse, FindMemberRequest, MemberSummaryResponse, SectionActiveRoles } from '@/types'
import MembersList from '@/components/members/MembersList.vue'
import { MemberFilter as SearchCriteria } from '@/types/custom'
import { MembershipStatus } from '@/types/enums'
import Pager from '@/components/Pager.vue'

export default Vue.extend({
  name: 'MemberSearch',
  components: {
    MemberSearchCriteria,
    MembersList,
    Pager
  },
  props: {
    id: {
      type: String,
      default: '0'
    },
    mode: {
      type: String,
      default: 'section'
    },
    hasCurrentMembership: {
      type: Boolean,
      default: undefined
    },
    isMembershipApproved: {
      type: Boolean,
      default: true
    },
    includeMembershipPositions: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      sectionMemberRoles: undefined as undefined | SectionActiveRoles,
      searchCriteria: { // Export Criteria - must be kept in sync with the Filter!
        name: '',
        approved: true,
        hasMembership: true,
        membershipStatus: MembershipStatus.ACTIVE,
        extraMembershipStatuses: [MembershipStatus.NEW]
      } as SearchCriteria,
      memberSearchResult: undefined as undefined | PagedListResponse<MemberSummaryResponse>,
      filter: { // the actual filter passed to the back end
        sections: this.mode === 'section' ? [
          Number(this.id)
        ] : [],
        includeMemberPositions: true,
        name: '',
        districts: this.mode !== 'section' ? [
          Number(this.id)
        ] : [],
        hasCurrentMembership: undefined, // not used after sync with stats on 23.11.2022
        isMembershipApproved: true, // not used after sync with stats on 23.11.2022
        membershipStatuses: [MembershipStatus.ACTIVE],
        membershipTypes: [],
        statuses: [],
        skip: 0,
        take: 50
      } as FindMemberRequest

    }
  },
  created () {
    this.$set(this.filter, 'hasCurrentMembership', true)
    this.$set(this.filter, 'isMembershipApproved', null)
    this.$set(this.filter, 'includeMembershipPositions', true)

    this.getMembers()
  },
  computed: {
    sectionName (): undefined | string {
      if (this.sectionMemberRoles != null) {
        return this.sectionMemberRoles.sectionName
      }
      return ''
    },
    membershipStatuses (): Array<MembershipStatus> {
      return [
        MembershipStatus.ACTIVE,
        MembershipStatus.NEW
      ]
    },
    debouncedMemberSearch (): Function {
      return debounce(this.getMembers, 800)
    },
    memberFilter (): SearchCriteria { // the filter object passed to the memberFilter
      return { // @ts-ignore
        name: this.filter.name, // @ts-ignore
        approved: this.filter.isMembershipApproved, // @ts-ignore
        hasMembership: this.filter.hasCurrentMembership, // @ts-ignore
        membershipStatus: this.filter.membershipStatuses.length ? this.filter.membershipStatuses[0] : undefined
      } as SearchCriteria
    }
  },
  methods: {
    getMembers (): void {
      this.$store.commit('alert/dataLoading', null, { root: true })
      if (this.mode === 'section') {
        this.$store.dispatch('members/findSectionMembers', this.filter).then(s => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          this.memberSearchResult = this.$store.state.members.filteredMembers
        }).catch(err => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          // eslint-disable-next-line no-console
          console.log(err)
          this.$store.dispatch('alert/error', { message: this.$t('members.findfailed') })
        })
      } else {
        this.$store.dispatch('members/findDistrictMembers', this.filter).then(s => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          this.memberSearchResult = this.$store.state.members.filteredMembers
        }).catch(err => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          // eslint-disable-next-line no-console
          console.log(err)
          this.$store.dispatch('alert/error', { message: this.$t('members.findfailed') })
        })
      }
    },
    pageToStart (): void {
      this.filter.skip = 0
      this.getMembers()
    },
    pageBack (): void {
      // @ts-ignore
      this.filter.skip -= this.filter.take
      this.getMembers()
    },
    pageForward (): void {
      // @ts-ignore
      this.filter.skip += this.filter.take
      this.getMembers()
    },
    pageToEnd (): void {
      if (this.memberSearchResult) {
        // @ts-ignore
        this.filter.skip = Math.floor(this.memberSearchResult.total / this.filter.take) * this.filter.take
        this.getMembers()
      }
    },
    onMemberFilterChanged: debounce(function (memberFilter: SearchCriteria) {
      // @ts-ignore
      this.$set(this.filter, 'name', memberFilter.name)
      // @ts-ignore
      this.$set(this.filter, 'hasCurrentMembership', memberFilter.hasMembership)
      if (memberFilter.membershipStatus === 0) {
      // @ts-ignore
        this.$set(this.filter, 'membershipStatuses', [])
      } else {
      // @ts-ignore
        this.$set(this.filter, 'membershipStatuses', [memberFilter.membershipStatus])
      }
      // Need to keep Filter and SearchCriteria in sync! Filter = MemberFind, SearchCriteria = MemberExport
      // @ts-ignore
      this.searchCriteria.name = memberFilter.name
      // @ts-ignore
      this.searchCriteria.approved = memberFilter.approved
      // @ts-ignore
      this.searchCriteria.hasMembership = memberFilter.hasMembership
      // @ts-ignore
      this.searchCriteria.membershipStatus = memberFilter.membershipStatus
      // @ts-ignore
      this.getMembers()

      // @ts-ignore
      this.$emit('onFilterChanged', memberFilter)
    }, 600),
    onMemberFilterReset () {
      this.searchCriteria.name = ''
      this.searchCriteria.approved = true
      this.searchCriteria.hasMembership = undefined // Kaikki
      this.searchCriteria.membershipStatus = MembershipStatus.ACTIVE
      this.searchCriteria.extraMembershipStatuses = [MembershipStatus.NEW] // Handle OR type MembershipStatuses (MultiMembershipStatus)
      this.filter.membershipStatuses = []
      this.filter.membershipTypes = []
      this.filter.statuses = []
      this.filter.take = 50
      this.updateFilter(this.searchCriteria)
    },
    updateFilter (memberSearchCriteria: SearchCriteria) {
      this.$set(this.filter, 'name', memberSearchCriteria.name)
      this.$set(this.filter, 'hasCurrentMembership', memberSearchCriteria.hasMembership)
      this.$set(this.filter, 'isMembershipApproved', memberSearchCriteria.approved)
      if (memberSearchCriteria.membershipStatus === 0) {
        this.$set(this.filter, 'membershipStatuses', [])
      } else {
        this.$set(this.filter, 'membershipStatuses', [memberSearchCriteria.membershipStatus])
      }
      //this.$set(this.filter, 'membershipStatuses', [memberSearchCriteria.membershipStatus])
      this.$set(this.filter, 'skip', 0)
      // Need to keep Filter and SearchCriteria in sync! Filter = MemberFind, SearchCriteria = MemberExport
      this.searchCriteria.name = memberSearchCriteria.name
      this.searchCriteria.approved = memberSearchCriteria.approved
      this.searchCriteria.hasMembership = memberSearchCriteria.hasMembership
      this.searchCriteria.membershipStatus = memberSearchCriteria.membershipStatus
      this.getMembers()
    }
  }
})

