
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import SprFormDropdown from '@/components/forms/SprFormDropdown.vue'
import SprFormDatePicker from '@/components/forms/SprFormDatePicker.vue'
import SprTypeahead from '@/components/forms/SprTypeahead.vue'
import SprFormText from '@/components/forms/SprFormText.vue'
import SprHiddenDateField from '@/components/forms/SprHiddenDateField.vue'
import { getFirstDayOfYear, getLastDayOfYear } from '@/helpers/datehelper'
import { MemberLightResponse, PositionResponse } from '../../types'
import { MemberPositionFormData } from '@/types/custom'
import { membersService } from '@/services'
import { MembershipStatus } from '@/types/enums'

export default Vue.extend({
  name: 'MemberPositionForm',
  inject: ['$validator'], // inject the parent validator as it is the external button that does the validation
  components: { SprFormDropdown, SprFormDatePicker, SprTypeahead, SprFormText, SprHiddenDateField },
  props: {
    sectionId: {
      type: Number,
      required: true
    },
    value: {
      type: Object as () => MemberPositionFormData,
      required: true
    },
    isAdd: {
      type: Boolean,
      default: false
    }
  },
  data (this: Vue) {
    return {
      members: [] as Array<MemberLightResponse>,
      positions: [] as Array<PositionResponse>,
      isMember: true
    }
  },
  computed: {
    localVal (): MemberPositionFormData {
      return Vue.observable(this.value as MemberPositionFormData)
    },
    termEndDate (): Date {
      if (!this.localVal) return new Date()
      return this.localVal.termEnd
    },
    isMemberUniqueName (): string {
      const unique = Date.now()
      return `ismember_${unique}`
    },
    selectedMember (): string {
      if (this.value.memberId) {
        // @ts-ignore
        const member = this.membersList.find(x => x.value === this.value.memberId.toString())

        if (member) {
          return member.text
        }
      }
      return ''
    },
    canShowMemberNonMemberSelection (): boolean {
      // if the position is only for members, hide the choice
      const position = this.positions.find((x: PositionResponse) => x.id === this.value.positionId)
      return position ? !position.requiresMembership : false
    },
    positionTypeString (): string {
      // @ts-ignore
      if (this.value.positionId) {
        // @ts-ignore
        return this.value.positionId.toString()
      } else {
        return ''
      }
    },
    membersList (): Array<Record<string, any>> {
      const memberArr = this.members as Array<MemberLightResponse>
      const retArr = memberArr.filter(x => x.membershipStatus.id === MembershipStatus.ACTIVE).map((member: MemberLightResponse) => {
        return { text: this.getMemberString(member), value: member.id.toString() }
      })

      return retArr
    },
    positionsList (): Array<Record<string, any>> {
      const isMember = this.isMember
      const allowedPositions = this.positions.filter((x: PositionResponse) => {
        return !isMember ? !x.requiresMembership : true // for non members, only allow assigning to non member positions
      }) as Array<PositionResponse>
      let retArr = allowedPositions?.map((position: PositionResponse) => {
        // return { text: position.name.toString(), value: position.id.toString() }
        const positionIdName = this.getPositionIdName(position.id)
        return { text: positionIdName, value: position.id.toString() }
      })

      if (!retArr) retArr = []
      retArr.unshift({ text: `${this.$t('dropdown.choose')}`, value: '' })
      return retArr
    },
    meetingDate (): Date {
      const meetingDate = this.$store.state.meetings.selectedMeeting.dateHeld
      meetingDate.setUTCHours(0)
      meetingDate.setUTCMinutes(0)
      meetingDate.setUTCSeconds(0)
      meetingDate.setUTCMilliseconds(0)
      return meetingDate
    },
    hasNoPhone (): boolean {
      return this.value.nonMemberPhone.length === 0
    },
    hasNoEmail (): boolean {
      return this.value.nonMemberEmail.length === 0
    },
    showErrorNonMemberPhone (): object {
      // email address AND phone number need to be "in error" only if both of the fields are empty.
      if (this.value.nonMemberEmail.length === 0) {
      //console.log('email is empty, phone should be required')
        return { required: true, regex: /^\+?([\(\)0-9\s]+)$/, max: 100 } //return true in :has-errors
      } else {
      //console.log('email has value, phone should not be required')
        return { regex: /^\+?([\(\)0-9\s]+)$/, max: 100 }
      }
    },
    showErrorNonMemberEmail (): string {
      // email address AND phone number need to be "in error" only if both of the fields are empty.
      if (this.value.nonMemberPhone.length === 0) {
      //console.log('phone is empty, email should be required')
        return 'required|email|max:100' //return true in :has-errors
      } else {
      //console.log('phone has value, email should not be required')
        return 'email|max:100'
      }
    }
  },
  methods: {
    getMemberString (member: MemberLightResponse): string {
      let str = `${member.lastName}, ${member.firstName}`
      let substr = ``
      if (member.address && !member.isPublishProhibited) {
        substr += `${member.address}`
        if (substr) { substr += ` ,` }
        substr += `${member.phoneNumber}`
        if (substr) { substr += ` ,` }
        substr += `${member.emailAddress}`
      }

      if (substr) { str += ` (${substr})` }

      return str
    },
    getPositionIdName (type: number): TranslateResult {
      return this.$t(`positionId-${type}`)
    },
    findMemberByName (this: any, name: string): MemberLightResponse | undefined {
      return this.members.find((x: MemberLightResponse) => this.getMemberString(x).toLowerCase() === name)
    },
    findMember (this: any, id: number): MemberLightResponse | undefined {
      return this.members.find((x: MemberLightResponse) => x.id === id)
    },
    findPosition (this: any, id: number): PositionResponse {
      return this.positions.find((x: PositionResponse) => x.id === id)
    },
    onMemberSelected (val: Record<string, any>) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.memberId = Number(val.value)
      // @ts-ignore
      this.send(temp)
    },
    onMemberTextInput (val: string) {
      // if this name does not exist in the list of members, clear the memberposition
      // @ts-ignore
      if (!this.findMemberByName(val)) {
        const temp = Object.assign({}, this.value) as MemberPositionFormData
        temp.memberId = 0
        // @ts-ignore
        this.send(temp)
      }
    },
    onInputPositionType (val: string) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.positionId = Number(val)
      // Set the default term length on changing position
      const position: PositionResponse | null = this.$store.state.meetings.positionsOfTrust.find((x: PositionResponse) => {
        return x.id === temp.positionId
      })

      if (!position) {
        return
      }

      this.$emit('positionTypeUpdated', Number(temp.positionId))

      this.send(temp)
    },
    onInputNonMemberFirstname (val: string) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.nonMemberFirstname = val
      // @ts-ignore
      this.send(temp)
    },
    onInputNonMemberLastname (val: string) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.nonMemberLastname = val
      // @ts-ignore
      this.send(temp)
    },
    onInputNonMemberCompany (val: string) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.nonMemberCompany = val
      // @ts-ignore
      this.send(temp)
    },
    onInputNonMemberAddress (val: string) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.nonMemberAddress = val
      // @ts-ignore
      this.send(temp)
    },
    onInputNonMemberPostcode (val: string) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.nonMemberPostcode = val
      // @ts-ignore
      this.send(temp)
    },
    onInputNonMemberPostoffice (val: string) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.nonMemberPostoffice = val
      // @ts-ignore
      this.send(temp)
    },
    onInputNonMemberPhone (val: string) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.nonMemberPhone = val
      this.localVal.nonMemberPhone = val
      // @ts-ignore
      this.send(temp)
      setTimeout(() => {
        this.$validator.validate('memberposition_nonmemberemail')
        this.$validator.validate('memberposition_nonmemberphone')
      }, 0)
    },
    onInputNonMemberEmail (val: string) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.nonMemberEmail = val
      this.localVal.nonMemberEmail = val
      // @ts-ignore
      this.send(temp)
      setTimeout(() => {
        this.$validator.validate('memberposition_nonmemberemail')
        this.$validator.validate('memberposition_nonmemberphone')
      }, 0)
    },
    onInputNonMemberPhoneRequired (val: string) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.nonMemberPhone = val
      this.localVal.nonMemberPhone = val
      // @ts-ignore
      this.send(temp)
      setTimeout(() => {
        this.$validator.validate('memberposition_nonmemberemailrequired')
        this.$validator.validate('memberposition_nonmemberphonerequired')
      }, 0)
    },
    onInputNonMemberEmailRequired (val: string) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.nonMemberEmail = val
      this.localVal.nonMemberEmail = val
      // @ts-ignore
      this.send(temp)
      setTimeout(() => {
        this.$validator.validate('memberposition_nonmemberemailrequired')
        this.$validator.validate('memberposition_nonmemberphonerequired')
      }, 0)
    },
    onInputTermStart (val: Date) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.termStart = val
      // @ts-ignore
      this.send(temp)
    },
    onInputTermEnd (val: Date) {
      const temp = Object.assign({}, this.value) as MemberPositionFormData
      temp.termEnd = val
      // @ts-ignore
      this.send(temp)
    },
    send (data: MemberPositionFormData) {
      // If member selected, remove non member data and vice versa
      if (this.isMember) {
        data.nonMemberAddress =
        data.nonMemberPostcode =
        data.nonMemberPostoffice =
        data.nonMemberEmail =
        data.nonMemberFirstname =
        data.nonMemberLastname =
        data.nonMemberCompany =
        data.nonMemberPhone = ''
      } else {
        data.memberId = undefined
      }
      this.$emit('input', data)
    }
  },

  created (this: any): void {
    // this.$store.commit('alert/dataLoading', null, { root: true })
    membersService.findBySectionId(Number(this.sectionId))
      .then(
        (sectionMembers: Array<MemberLightResponse>) => {
          this.members = sectionMembers
          // this.$store.commit('alert/dataLoaded', null, { root: true })
        },
        (error: any) => {
          // @ts-ignore
          this.$store.dispatch('alert/error', { message: this.$t('addmemberposition.getmembersfailed') })
          // eslint-disable-next-line no-console
          console.log(error)
          // this.$store.commit('alert/dataLoaded', null, { root: true })
        }
      )

    // only show active positions of trust
    this.positions = this.$store.getters['meetings/getActivePositionsOfTrust']()
  },
  mounted () {
    this.isMember = Boolean(this.value && this.value.memberId !== null)
  }
})
