
import Vue from 'vue'
import MeetingStatusIndicator from '@/components/meetings/MeetingStatus.vue'
import { MemberPositionSummaryResponse } from '@/types'
import { TranslateResult } from 'vue-i18n'
import { finnishShortDate } from '@/helpers/datehelper'
import { MeetingStatus } from '@/types/enums'

export default Vue.extend({
  name: 'ActivePositionsOfTrust',
  components: { MeetingStatusIndicator },
  props: {
    positions: {
      type: Array as () => Array<MemberPositionSummaryResponse>,
      default: []
    },
    showMeetingLink: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasActions (): boolean {
      // there will be more actions in the future. For the moment only one.
      return this.showMeetingLink
    },
    sortedPositions (): Array<MemberPositionSummaryResponse> {
      // sort by meeting status descending
      return this.positions.slice().sort((a: MemberPositionSummaryResponse, b: MemberPositionSummaryResponse) => {
        return b.meetingStatus.id - a.meetingStatus.id
      })
    }
  },
  methods: {
    formatDate (date: Date): string {
      return finnishShortDate(date)
    },
    getPositionIdName (type: number): TranslateResult {
      return this.$t(`positionId-${type}`)
    },
    onViewMeetingClicked (position: MemberPositionSummaryResponse): void {
      this.$emit('viewMeetingClicked', position)
    },
    isApprovedMeeting (position: MemberPositionSummaryResponse): boolean {
      return position.meetingStatus.id === MeetingStatus.APPROVED
    }
  }
})
