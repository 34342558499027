import { CodeCategory } from '@/types/enums'
import { CodeState } from '@/store/code.module.ts'

export function getCode (state: CodeState, id: number, codeCategory: CodeCategory): Code | undefined {
  switch (codeCategory) {
    case CodeCategory.MEETING_STATUS:
      return state.meetingStatuses.find((x: MeetingStatusCode) => x.id === id)
    case CodeCategory.MEETING_TYPE:
      return state.meetingTypes.find((x: MeetingTypeCode) => x.id === id)
    case CodeCategory.MEMBERSHIP_STATUS:
      return state.membershipStatuses.find((x: MembershipStatusCode) => x.id === id)
    case CodeCategory.MEMBERSHIP_TYPE:
      return state.membershipTypes.find((x: MembershipTypeCode) => x.id === id)
    case CodeCategory.MEMBER_POSITION_STATUS:
      return state.membershipPositionStatueses.find((x: MemberPositionStatusCode) => x.id === id)
    case CodeCategory.MEMBER_STATUS:
      return state.memberStatuses.find((x: MemberStatusCode) => x.id === id)
    case CodeCategory.MINUTES_STATUS:
      return state.minutesStatuses.find((x: MinutesStatusCode) => x.id === id)
    case CodeCategory.SECTION_STATUS:
      return state.sectionStatuses.find((x: SectionStatusCode) => x.id === id)
    case CodeCategory.DISTRICT_STATUS:
      return state.districtStatuses.find((x: DistrictStatusCode) => x.id === id)
  }
}

export class Code {
  id: number

  category: CodeCategory

  enumName: string

  constructor (_id: number, _category: CodeCategory, _enumName: string) {
    this.id = _id
    this.category = _category
    this.enumName = _enumName
  }

  getTranslationKey (): string {
    return `${this.category.toLowerCase()}-${this.id}`
  }
}

export class MeetingStatusCode extends Code {
  constructor (_id: number, _enumName: string) {
    super(_id, CodeCategory.MEETING_STATUS, _enumName)
  }
}

export class MeetingTypeCode extends Code {
  constructor (_id: number, _enumName: string) {
    super(_id, CodeCategory.MEETING_TYPE, _enumName)
  }
}

export class MembershipStatusCode extends Code {
  constructor (_id: number, _enumName: string) {
    super(_id, CodeCategory.MEMBERSHIP_STATUS, _enumName)
  }
}

export class MembershipTypeCode extends Code {
  constructor (_id: number, _enumName: string) {
    super(_id, CodeCategory.MEMBERSHIP_TYPE, _enumName)
  }
}

export class MemberPositionStatusCode extends Code {
  constructor (_id: number, _enumName: string) {
    super(_id, CodeCategory.MEMBER_POSITION_STATUS, _enumName)
  }
}

export class MemberStatusCode extends Code {
  constructor (_id: number, _enumName: string) {
    super(_id, CodeCategory.MEMBER_STATUS, _enumName)
  }
}

export class MinutesStatusCode extends Code {
  constructor (_id: number, _enumName: string) {
    super(_id, CodeCategory.MINUTES_STATUS, _enumName)
  }
}

export class SectionStatusCode extends Code {
  constructor (_id: number, _enumName: string) {
    super(_id, CodeCategory.SECTION_STATUS, _enumName)
  }
}

export class DistrictStatusCode extends Code {
  constructor (_id: number, _enumName: string) {
    super(_id, CodeCategory.DISTRICT_STATUS, _enumName)
  }
}