import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import { mainService } from './services/main.service'

// Font awesome core
import { library } from '@fortawesome/fontawesome-svg-core'

// Enable font awesome component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Specify the specific icons the project uses
import {
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faBirthdayCake,
  faCalendar,
  faCalendarAlt,
  faCalendarPlus,
  faCheckCircle,
  faEnvelope,
  faEuroSign,
  faExclamationCircle,
  faExclamationTriangle,
  faFile,
  faFileAlt,
  faFileDownload,
  faGrin,
  faHandshake,
  faIdCard,
  faInfo,
  faInfoCircle,
  faListAlt,
  faMoneyCheckAlt,
  faPencilAlt,
  faPhone,
  faSitemap,
  faSpinner,
  faTasks,
  faTh,
  faTimes,
  faTimesCircle,
  faUser,
  faUserFriends,
  faUserSlash,
  faStar
} from '@fortawesome/free-solid-svg-icons'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/ossi.scss'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import { configureFakeBackend } from './helpers/auth/authorizationhelper'
import validationFi from '@/locales/validation/fi'
import validationSv from '@/locales/validation/sv'

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faBirthdayCake,
  faCalendar,
  faCalendarAlt,
  faCalendarPlus,
  faCheckCircle,
  faEuroSign,
  faExclamationCircle,
  faExclamationTriangle,
  faStar,
  faFile,
  faFileAlt,
  faFileDownload,
  faEnvelope,
  faGrin,
  faHandshake,
  faIdCard,
  faInfo,
  faInfoCircle,
  faListAlt,
  faMoneyCheckAlt,
  faPencilAlt,
  faPhone,
  faSitemap,
  faSpinner,
  faTasks,
  faTh,
  faTimes,
  faTimesCircle,
  faUser,
  faUserFriends,
  faUserSlash
)

// https://bootstrap-vue.js.org/docs/
Vue.use(BootstrapVue)

Vue.use(VeeValidate, {
  i18n,
  dictionary: {
    [validationFi.name]: validationFi,
    [validationSv.name]: validationSv
  }
})

store.subscribe((mutation, state) => {
  if (mutation.type === 'config/setLanguage') {
    i18n.locale = store.state.config.language || 'fi'
  }
})

Vue.config.productionTip = false

function loadVue (loadedOk: boolean) {
  const vue = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')

  if (!loadedOk) {
    // failed to get things required from the back end. Show an error page
    vue.$store.dispatch('alert/error', { message: vue.$t('main.loadfailed'), surviveRouteChange: true })
    vue.$router.push('/error')
  }
}

mainService.handleAfterAuthenticated().then(() => {
  loadVue(true)
}).catch((err) => {
  loadVue(false)
})
