
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { SectionRole } from '@/types'

export default Vue.extend({
  name: 'SectionRoleItem',
  props: {
    roleItem: {
      type: Object as () => SectionRole,
      required: true,
      default: undefined
    }
  },
  computed: {
    editUrl (): string {
      return `/sections/${this.roleItem.sectionId}/members/${this.roleItem.memberId}`
    },
    fullName (): string {
      return `${this.roleItem.memberInfo.firstName} ${this.roleItem.memberInfo.lastName}`
    },
    roleCode (): string {
      const roleId = this.roleItem.role
      return this.getRoleName(roleId).toString()
    }
  },
  methods: {
    fullNameClicked () {
      this.$router.push(`/sections/${this.roleItem.sectionId}/members/${this.roleItem.memberId}`)
    },
    getRoleName (type: number): TranslateResult {
      return this.$t(`role-${type}`)
    }
  }
})
