
import Vue from 'vue'
import MeetingStatus from '@/components/meetings/MeetingStatus.vue'
import { isAddressEmpty, formatAddress } from '@/helpers/addresshelper'
import { MemberPositionSummaryResponse, AddressResponse, MemberSummaryResponse } from '@/types'
import { finnishShortDate } from '@/helpers/datehelper.ts'
import { MemberPositionStatus } from '@/types/enums'

export default Vue.extend({
  name: 'UpcomingPositionsOfTrustSummary',
  components: { MeetingStatus },
  props: {
    positions: {
      type: Array as () => Array<MemberPositionSummaryResponse>,
      required: true
    }
  },
  methods: {
    getPositionTranslation (positionId: number) {
      const positionCode = 'positionId-' + positionId.toString()
      return this.$t(positionCode)
    },
    hasAccessToOtherSections (pos: MemberPositionSummaryResponse) {
      return this.$store.state.authentication.claims.isInRoleSuperAdmin ||
      this.$store.state.authentication.claims.isInRoleKT ||
      this.$store.state.authentication.claims.isInRoleDistrictAdmin ||
        this.$store.state.authentication.claims.isInRoleDistrictUser
    },
    formatDate (date: Date) {
      return finnishShortDate(date)
    },
    gotoMember (member: MemberSummaryResponse) {
      this.$router.push(`/sections/${member.sectionId}/members/${member.id}`)
    },
    gotoMeeting (position: MemberPositionSummaryResponse) {
      this.$router.push(`/sections/${position.meetingSectionId}/meetings/${position.meetingId}`)
    }
  },
  computed: {
    sortedPositions () {
      if (!this.positions) return
      return this.positions
        .filter((x: MemberPositionSummaryResponse) => {
          return x.status.id === MemberPositionStatus.ACTIVE
        })
        .sort((x: MemberPositionSummaryResponse, y: MemberPositionSummaryResponse) => {
          return y.termEnd.getTime() - x.termEnd.getTime()
        })
    }
  }
})
