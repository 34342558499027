
import Vue from 'vue'
import { LoginRequest } from '@/types'
import SprFormText from '@/components/forms/SprFormText.vue'
import SprFormPassword from '@/components/forms/SprFormPassword.vue'
import SprButton from '@/components/forms/SprButton.vue'

export default Vue.extend({
  name: 'LoginForm',
  components: {
    SprFormText,
    SprFormPassword,
    SprButton
  },
  data () {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    updateEmail (val: string): string {
      return val
    },
    onLogin () {
      // todo : validation
      const data: LoginRequest = {
        email: this.email,
        password: this.password
      }
      this.$emit('onLogin', data)
    }
  }
})
