
import Vue from 'vue'
import Back from '@/components/Back.vue'
import DatePicker from '@/components/forms/SprFormDatePicker.vue'
import OfficialLetterRequests from '@/components/sections/OfficialLetterRequests.vue'
import SprButton from '@/components/forms/SprButton.vue'
import Loading from '@/components/Loading.vue'
import { SectionSummaryResponse, ReportBoardMembersRequest, SignedDocumentResponse, OfficialLetterRequestResponse } from '@/types'
import { sectionService } from '@/services/section.service'

export default Vue.extend({
  name: 'Reports',
  components: {
    Back,
    DatePicker,
    SprButton,
    OfficialLetterRequests,
    Loading
  },
  props: {
    sectionId: {
      type: String, // from the url props
      default: '0'
    }
  },
  methods: {
    onBoardMembersReport () {
      this.dataLoading = true
      const data = {
        sectionId: Number(this.sectionId),
        dateFrom: this.searchDate
      } as ReportBoardMembersRequest

      sectionService.getBoardMembersPdf(data).then((result: Blob) => {
        const dataUrl = window.URL.createObjectURL(result)
        window.open(dataUrl)
        this.dataLoading = false
      }).catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.dataLoading = false
        this.$store.dispatch('alert/error', { message: this.$t('report.failed') })
      })
    },
    getOfficialLetterRequests: function () {
      this.dataLoading = true
      sectionService.getOfficialLetterRequests(Number(this.sectionId)).then(result => {
        this.officialLetterRequests = result
        this.dataLoading = false
      }).catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.dataLoading = false
        this.$store.dispatch('alert/error', { message: this.$t('report.failed') })
      })
    },
    onAddNewOfficialLetterRequest: function () {
      this.$router.push(`/sections/${this.sectionId}/reports/addofficialletterrequest`)
    },
    onDeleteOfficialLetterRequest: function (request: OfficialLetterRequestResponse) {
      this.dataLoading = true
      sectionService.deleteLetterRequest(request.id)
        .then((success: boolean) => {
          if (success) {
            this.$store.dispatch('alert/success', { message: this.$t('officialletterrequest.deletesuccess') })
            this.getOfficialLetterRequests()
          } else {
            this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.deletefailed') })
          }
          this.dataLoading = false
        })
    }
  },
  data () {
    return {
      searchDate: new Date(),
      dataLoading: false,
      officialLetterRequests: [] as Array<OfficialLetterRequestResponse>
    }
  },
  created () {
    if (!this.sectionId) {
      this.$router.push('/404')
    }

    this.getOfficialLetterRequests()
  },
  computed: {
    sectionname (): string {
      if (this.$store.state.sections.sectionsLoaded) {
        const section = this.$store.state.sections.all.find((x: SectionSummaryResponse) => x.id === Number(this.sectionId))
        return section ? section.name : ''
      }
      return ''
    }
  }
})
