import { sectionService } from '../services'
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store'
import { SectionSummaryResponse, SectionDetailedResponse, SectionStatisticsResponse, SectionActiveRoles } from '@/types'

export interface SectionState {
  sectionsLoaded: boolean,
  sectionLoaded: boolean,
  all: Array<SectionSummaryResponse>,
  selectedSection: SectionDetailedResponse | null,
  sectionStatistics: SectionStatisticsResponse | null,
  sectionRoles: SectionActiveRoles | null,
  allUrl: string,
  getByIdUrl: string,
  getBoardMembersPdfUrl: string,
  getSigningRightsMembersPdfUrl: string,
  getOfficialLetterRequestsUrl: string,
  getOfficialLetterRequestByIdUrl: string,
  getSectionRolesByIdUrl: string,
  addOfficialLetterRequestUrl: string,
  uploadSignedDocUrl: string,
  removeSignedDocUrl: string,
  approveLetterRequestUrl: string,
  archiveLetterRequestUrl: string,
  deleteLetterRequestUrl: string,
  getSectionStatisticsUrl: string,
  resetSectionUrl: string
}

const getDefaultState = (): SectionState => {
  return {
    sectionsLoaded: false,
    sectionLoaded: false,
    all: [],
    selectedSection: null,
    sectionStatistics: null,
    sectionRoles: null,
    allUrl: '/api/sections',
    getByIdUrl: '/api/sections/{id}',
    getBoardMembersPdfUrl: '/api/sections/boardmembers/pdf',
    getSigningRightsMembersPdfUrl: '/api/sections/signingrightsmembers/{id}/pdf',
    getOfficialLetterRequestsUrl: '/api/sections/getofficialletterrequests/{id}',
    getOfficialLetterRequestByIdUrl: '/api/sections/getofficialletterrequestbyid/{id}',
    getSectionRolesByIdUrl: '/api/sections/sectionroles/{id}',
    addOfficialLetterRequestUrl: '/api/sections/addofficialletterrequest',
    uploadSignedDocUrl: '/api/sections/uploadsigneddocument',
    removeSignedDocUrl: '/api/sections/removesigneddocument/{id}',
    approveLetterRequestUrl: '/api/sections/approveletterrequest',
    archiveLetterRequestUrl: '/api/sections/archiveletterrequest/{id}',
    deleteLetterRequestUrl: '/api/sections/deleteletterrequest/{id}',
    getSectionStatisticsUrl: '/api/sections/{id}/stats',
    resetSectionUrl: '/api/sections/{id}/reset'
  }
}

export const state = getDefaultState()

const actions: ActionTree<SectionState, RootState> = {
  loadAll ({ commit }) {
    commit('loadAllRequest')

    return sectionService.getAll()
      .then(
        sections => commit('loadAllSuccess', sections)
      ).catch(err => {
        commit('loadAllFailure', err)
        return Promise.reject(err)
      })
  },

  loadDetailed (context, sectionId: number) {
    context.commit('loadDetailedRequest')

    return sectionService.getById(sectionId)
      .then(
        (section: SectionDetailedResponse) => {
          context.commit('loadDetailedSuccess', section)
          return Promise.resolve()
        }
      ).catch((err: Error) => {
        context.commit('loadDetailedFailure', err)
        return Promise.reject(err)
      })
  },

  resetSection (context, section: SectionDetailedResponse) {
    return sectionService.resetSection(section.id)
  },

  getSectionRoles (context, sectionId: number) {
    context.commit('loadSectionRoles')

    return sectionService.getSectionRolesById(sectionId)
      .then(
        (section: SectionActiveRoles) => {
          context.commit('loadSectionRolesSuccess', section)
          return Promise.resolve()
        }
      ).catch((err: Error) => {
        context.commit('loadSectionRolesFailure', err)
        return Promise.reject(err)
      })
  },

  getSectionStatistics (context, sectionId: number) {
    context.commit('loadSectionStatisticsRequest')

    return sectionService.getSectionStatistics(sectionId)
      .then(
        (section: SectionStatisticsResponse) => {
          context.commit('loadSectionStatisticsSuccess', section)
          return Promise.resolve()
        }
      ).catch((err: Error) => {
        context.commit('loadSectionStatisticsFailure', err)
        return Promise.reject(err)
      })
  }
}

const mutations: MutationTree<SectionState> = {
  loadAllRequest (state) {
    state.all = []
  },

  loadAllSuccess (state, sections) {
    state.sectionsLoaded = true
    state.all = sections
  },

  loadAllFailure (state, error) {
    state.sectionsLoaded = false
    state.all = []
  },

  loadDetailedRequest (state) {
    state.selectedSection = null
  },

  loadDetailedSuccess (state, section) {
    state.sectionLoaded = true
    state.selectedSection = section
  },

  loadDetailedFailure (state, error) {
    state.sectionLoaded = false
    state.selectedSection = null
  },

  loadSectionRoles (state) {
    state.sectionRoles = null
  },

  loadSectionRolesSuccess (state, roles) {
    state.sectionRoles = roles
  },

  loadSectionRolesFailure (state, error) {
    state.sectionRoles = null
  },

  loadSectionStatisticsRequest (state) {
    state.sectionStatistics = null
  },

  loadSectionStatisticsSuccess (state, stats) {
    state.sectionStatistics = stats
  },

  loadSectionStatisticsFailure (state, error) {
    state.sectionStatistics = null
  },

  reset (state) {
    Object.assign(state, getDefaultState())
  }
}

const getters: GetterTree<SectionState, RootState> = {
  getById: (state: SectionState) => (id: number) => {
    if (state.sectionsLoaded) {
      return state.all.find((x) => x.id === id)
    }
  },
  getByDistrictId: (state: SectionState) => (districtId: number) => {
    return state.all.filter((x) => x.districtId === districtId)
  }
}

export const sections: Module<SectionState, RootState> = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
}
