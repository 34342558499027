
import Vue from 'vue'
import { SectionActiveRoles } from '@/types'
import SectionRoleItem from '@/components/sections/SectionRoleItem.vue'

export default Vue.extend({
  name: 'SectionRolesList',
  components: { SectionRoleItem },
  props: {
    sectionId: {
      type: String,
      required: true,
      default: null
    }
  },
  data () {
    return {
      sectionMemberRoles: {
        sectionExist: false,
        sectionId: -1,
        sectionName: 'Must be fetched!'
      } as SectionActiveRoles
    }
  },
  computed: {
    noResults (): boolean {
      return !this.sectionMemberRoles || !this.sectionMemberRoles.sectionRoles || this.sectionMemberRoles.sectionRoles.length === 0
    },
    sectionName (): undefined | string {
      if (this.sectionMemberRoles != null) {
        return this.sectionMemberRoles.sectionName
      }
      return ''
    }
  },
  created () {
    const sectionId = Number(this.sectionId)
    this.$store.commit('alert/dataLoading', null, { root: true })
    this.$store.dispatch('sections/getSectionRoles', sectionId).then(s => {
      this.$store.commit('alert/dataLoaded', null, { root: true })
      this.sectionMemberRoles = this.$store.state.sections.sectionRoles
    }).catch(err => {
      this.$store.commit('alert/dataLoaded', null, { root: true })
      // eslint-disable-next-line no-console
      console.log(err)
      this.$store.dispatch('alert/error', { message: this.$t('members.findfailed') })
      this.sectionMemberRoles = {
        sectionExist: false,
        sectionId: -1,
        sectionName: 'Must be fetched!'
      } as SectionActiveRoles
    })
  }
})

