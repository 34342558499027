import JwtDecode from 'jwt-decode'
import store from '@/store/index'
import { StringArray, Claims } from '@/types/custom.ts'

// Override the window fetch method to get a fake user list when you call /users/authenticate
export function configureFakeBackend (): void {
  const users = [{ id: 1, email: 'test', password: 'test', firstName: 'Test', lastName: 'User' }]
  const realFetch = window.fetch
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      // wrap in timeout to simulate server api call - REMEMBER TO REMOVE THIS!!!!!
      setTimeout(() => {
        // authenticate
        if (url.toString().endsWith(store.state.authentication.loginUrl) && opts && opts.method === 'POST') {
          // get parameters from post request
          const params = JSON.parse(opts.body ? opts.body.toString() : '{}')

          // find if any user matches login credentials
          const filteredUsers = users.filter(user => {
            return user.email === params.email && user.password === params.password
          })

          if (filteredUsers.length) {
            resolve(new Response('fake-jwt-token'))
          } else {
            // else return error
            reject(new Error('Email or password is incorrect'))
          }
        }

        // fake AD Authentication to the back end
        if (url.toString().indexOf(store.state.authentication.activeDirectory.adAuthorizeUri) > -1 && opts && opts.method === 'GET') {
          resolve(new Response('fake-jwt-token'))
        }
        // demo get users
        if (url.toString().endsWith('/api/users/all')) {
          return users.map(x => {
            x.password = ''
            return x
          })
        }

        // pass through any requests not handled above
        realFetch(url, opts).then(response => resolve(response))
      }, 500)
    })
  }
}

export function tokenStructureIsValid (token: string): boolean {
  if (!token) {
    return false
  }
  return token.split('.').length === 3
}

export function decodeToken (token: string): Claims {
  const claimsTypes = {
    username: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    ossiId: 'OssiId',
    omaId: 'OmaId',
    crmId: 'CrmId',
    sprId: 'SprId',
    sectionId: 'SectionId',
    districtId: 'DistrictId',
    PasswordExpiring: 'PasswordExpiring',
    membershipStatusId: 'MembershipStatusId',
    membershipTypeId: 'MembershipTypeId',
    isInRoleSectionUser: 'IsInRoleSectionUser',
    isInRoleSectionAdmin: 'IsInRoleSectionAdmin',
    isInRoleDistrictUser: 'IsInRoleDistrictUser',
    isInRoleDistrictAdmin: 'IsInRoleDistrictAdmin',
    isInRoleKT: 'IsInRoleKT',
    isInRoleSuperAdmin: 'IsInRoleSuperAdmin'
  }

  const decoded: StringArray = JwtDecode(token)
  const retObj: Claims = {} as Claims

  retObj.username = decoded[claimsTypes.username]
  retObj.ossiId = Number(decoded[claimsTypes.ossiId])
  retObj.omaId = decoded[claimsTypes.omaId]
  retObj.crmId = decoded[claimsTypes.crmId]
  retObj.sprId = decoded[claimsTypes.sprId]
  retObj.sectionId = Number(decoded[claimsTypes.sectionId])
  retObj.districtId = Number(decoded[claimsTypes.districtId])
  retObj.membershipStatusId = Number(decoded[claimsTypes.membershipStatusId])
  retObj.membershipTypeId = Number(decoded[claimsTypes.membershipTypeId])

  retObj.passwordExpiring = Boolean(decoded[claimsTypes.PasswordExpiring])
  retObj.isInRoleSuperAdmin = Boolean(decoded[claimsTypes.isInRoleSuperAdmin]) || false
  retObj.isInRoleKT = Boolean(decoded[claimsTypes.isInRoleKT]) || false
  retObj.isInRoleDistrictAdmin = Boolean(decoded[claimsTypes.isInRoleDistrictAdmin]) || false
  retObj.isInRoleDistrictUser = Boolean(decoded[claimsTypes.isInRoleDistrictUser]) || false
  retObj.isInRoleSectionAdmin = Boolean(decoded[claimsTypes.isInRoleSectionAdmin]) || false
  retObj.isInRoleSectionUser = Boolean(decoded[claimsTypes.isInRoleSectionUser]) || false

  return retObj
}

export function getRoles (token: string): Array<number> {
  const claims: Claims = decodeToken(token)
  const arr = [] as Array<number>

  if (claims.isInRoleSuperAdmin) arr.push(1) //Yuck. Hard coded, but MemberRoles.SUPER_ADMIN compiles, but causes error on front end
  if (claims.isInRoleKT) arr.push(2)
  if (claims.isInRoleDistrictAdmin) arr.push(3)
  if (claims.isInRoleDistrictUser) arr.push(4)
  if (claims.isInRoleSectionAdmin) arr.push(5)
  if (claims.isInRoleSectionUser) arr.push(6)

  return arr
}
