
import Vue from 'vue'
import Back from '@/components/Back.vue'
import SprButton from '@/components/forms/SprButton.vue'
import SprFormFileUpload from '@/components/forms/SprFormFileUpload.vue'
import SprFormDatePicker from '@/components/forms/SprFormDatePicker.vue'
import Loading from '@/components/Loading.vue'
import { finnishShortDateAndTime, convertUTCDateToLocalDate } from '@/helpers/datehelper.ts'
import { OfficialLetterRequestResponse, SignedDocumentResponse, SignedDocumentRequest, OfficialLetterRequestStatusCode, ReportBoardMembersRequest, ApproveOfficialLetterRequestRequest } from '@/types'
import { OfficialLetterRequestStatus } from '@/types/enums'
import { sectionService } from '@/services/section.service.ts'

export default Vue.extend({
  name: 'ProcessOfficialLetterRequest',
  components: {
    Back,
    SprFormFileUpload,
    SprFormDatePicker,
    SprButton,
    Loading
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    getOfficialLetterRequest: function (id: number) {
      this.dataLoading = true
      sectionService.getOfficialLetterRequestById(id).then(result => {
        this.request = result
        this.dataLoading = false
      }).catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.dataLoading = false
        this.$store.dispatch('alert/error', { message: this.$t('report.failed') })
      })
    },
    gotoSection (sectionId: number) {
      this.$router.push(`/sections/${sectionId}`)
    },
    gotoMember (request: OfficialLetterRequestResponse) {
      this.$router.push(`/sections/${request.sectionId}/members/${request.createdByMemberId}`)
    },
    formatDate (date: Date) {
      return finnishShortDateAndTime(date)
    },
    formatStatus (status: OfficialLetterRequestStatusCode) {
      return this.$t(`officialletterrequeststatus-${status.id}`)
    },
    generatePdf () {
      this.dataLoading = true
      const data = {
        sectionId: Number(this.request.sectionId),
        dateFrom: new Date()
      } as ReportBoardMembersRequest

      sectionService.getSigningRightsMembersPdf(this.request.sectionId).then((result: Blob) => {
        const dataUrl = window.URL.createObjectURL(result)
        window.open(dataUrl)
        this.dataLoading = false
      }).catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.dataLoading = false
        this.$store.dispatch('alert/error', { message: this.$t('report.failed') })
      })
    },
    approveRequest () {
      this.$validator.validateAll()
        .then((success: boolean) => {
          if (success) {
            this.dataLoading = true
            const data = {
              officalLetterRequestId: this.request.id,
              expiryDate: this.expiryDate
            } as ApproveOfficialLetterRequestRequest
            sectionService.approveLetterRequest(data)
              .then(() => {
                this.$store.dispatch('alert/success', { message: this.$t('officialletterrequest.approvesuccess'), surviveRouteChange: true })
                this.$router.push('/officialletterrequests')
              }, (err) => {
                // eslint-disable-next-line no-console
                console.log(err)
                this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.approvefailed') })
                this.dataLoading = false
              })
          }
        })
    },
    deleteRequest () {
      this.dataLoading = true
      sectionService.deleteLetterRequest(this.request.id)
        .then((success: boolean) => {
          if (success) {
            this.$store.dispatch('alert/success', { message: this.$t('officialletterrequest.deletesuccess'), surviveRouteChange: true })
            this.$router.push('/officialletterrequests')
          } else {
            this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.deletefailed') })
          }
          this.dataLoading = false
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
          this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.deletefailed') })
          this.dataLoading = false
        })
    },
    archiveRequest () {
      this.dataLoading = true
      sectionService.archiveLetterRequest(this.request.id)
        .then((success: boolean) => {
          if (success) {
            this.$store.dispatch('alert/success', { message: this.$t('officialletterrequest.archivesuccess'), surviveRouteChange: true })
            this.$router.push('/officialletterrequests')
          } else {
            this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.archivefailed') })
          }
          this.dataLoading = false
        })
        .catch((err: Error) => {
          // eslint-disable-next-line no-console
          console.log(err)
          this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.archivefailed') })
          this.dataLoading = false
        })
    },
    onSignedFileUploaded (file: File | null) {
      if (file) {
        this.readFile(file)
      }
    },
    readFile (file: File) {
      const reader = new FileReader()
      this.dataLoading = true
      reader.onload = (readerEvt: ProgressEvent<FileReader>) => {
        const raw = reader.result as string
        // var base64 = btoa(binaryString);
        this.processUploadedFile(file.name, file.type, raw).then(success => {
          if (success) {
            // reload with the file
            this.$store.dispatch('alert/success', { message: this.$t('officialletterrequest.uploadsuccess') })
            this.getOfficialLetterRequest(this.idNumber)
          } else {
            this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.uploadfailed') })
          }
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
          this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.uploadfailed') })
          this.dataLoading = false
        })
      }

      reader.readAsBinaryString(file)
    },
    processUploadedFile (name: string, type: string, rawString: string | null): Promise<SignedDocumentResponse> {
      return new Promise((resolve, reject) => {
        if (!rawString) reject(new Error('No file.'))

        const doc: SignedDocumentRequest = {
          name: name,
          // @ts-ignore-next-line
          officialLetterRequestId: this.idNumber || null,
          blob: rawString ? btoa(rawString) : '',
          fileType: type
        }

        if (doc.name.length > 800) {
          this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.uploadnametoolarge') })
          reject(new Error('File length too long'))
        }

        if (doc.fileType.length > 255) {
          this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.removedocumentfailed') })
          reject(new Error('File type too long'))
        }

        sectionService.uploadSignedDocument(doc).then(success => {
          if (success) {
            resolve(success)
          } else {
            reject(success)
          }
        })
      })
    },
    deleteDocument () {
      this.dataLoading = true
      sectionService.removeSignedDocument(this.request.id)
        .then((success) => {
          // @ts-ignore
          this.request.document = undefined
          this.dataLoading = false
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err)
          this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.uploadtypetoolarge') })
          this.dataLoading = false
        })
    },
    viewDocument () {
      if (this.request.document) {
        window.open(this.request.document.url)
      }
    }
  },
  data () {
    return {
      dataLoading: false,
      request: {} as OfficialLetterRequestResponse,
      expiryDate: new Date() as Date
    }
  },
  created () {
    const today = new Date()
    const oneWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
    this.expiryDate = oneWeek
    this.getOfficialLetterRequest(this.idNumber)
  },
  computed: {
    idNumber (): number {
      return Number(this.id)
    },
    hasSignedDoc (): boolean {
      if (this.request) {
        return Boolean(this.request.document)
      }
      return false
    },
    canProcess (): boolean {
      if (this.request) {
        return this.hasSignedDoc && this.request.statusId === OfficialLetterRequestStatus.REQUESTED
      }
      return false
    },
    canArchive (): boolean {
      if (this.request) {
        return this.hasSignedDoc && this.request.statusId === OfficialLetterRequestStatus.PROCESSED
      }
      return false
    },
    canDeleteDoc (): boolean {
      if (this.request) {
        return this.request.statusId === OfficialLetterRequestStatus.REQUESTED
      }
      return false
    },
    hasExpired (): boolean {
      if (this.request) {
        return this.request.expiryDate ? new Date(this.request.expiryDate) < new Date() : false
      }
      return false
    }
  }
})
