
import Vue from 'vue'
import Back from '@/components/Back.vue'
import MeetingsList from '@/components/meetings/MeetingsList.vue'
import StatisticTile from '@/components/StatisticTile.vue'
import { GetMeetingsRequest, MeetingSummaryResponse, SectionSummaryResponse } from '../types'
import { MeetingStatus, CodeCategory, AlertTimeout } from '@/types/enums'

export default Vue.extend({
  name: 'Meetings',
  components: {
    Back,
    MeetingsList,
    StatisticTile
  },
  props: {
    sectionId: {
      type: String, // from the url props
      default: '0'
    }
  },
  methods: {
    getMeetings () {
      this.$store.commit('alert/dataLoading', null, { root: true })
      this.$store.dispatch('meetings/getMeetings', this.filter).then(s => {
        this.meetings = this.$store.state.meetings.meetings.sort((a: MeetingSummaryResponse, b: MeetingSummaryResponse) => {
          if (!a.created || !b.created) return true
          return b.created.getTime() - a.created.getTime()
        })
        this.$store.commit('alert/dataLoaded', null, { root: true })
      }, err => {
        if (err.status === 403) { // Forbidden
          this.$store.dispatch('alert/error', { message: this.$t('403') })
        } else if (err.status === 500) {
          this.$store.dispatch('alert/error', { message: this.$t('meetings.loadfailed'), timeout: AlertTimeout.PERMANENT })
        } else {
          this.$store.dispatch('alert/error', { message: this.$t('meetings.loadfailed') })
        }
        this.$store.commit('alert/dataLoaded', null, { root: true })
      }).catch(err => {
        /* eslint-disable no-console */
        console.log('caught error', err)
        this.$store.dispatch('alert/error', { message: this.$t('meetings.loadfailed') })
        this.$store.commit('alert/dataLoaded', null, { root: true })
      })
    },
    statisticClicked () {
      /* eslint-disable no-console */
      console.log('clicked')
    }
  },
  data () {
    return {
      meetings: [],
      meetingsLoaded: false,
      meetingColumnsToDisplay: ['Name', 'Type', 'Status', 'Created', 'DateHeld', 'CreatedBy']
    }
  },
  created () {
    if (!this.sectionId) {
      this.$router.push('/404')
    }
    this.getMeetings()
  },
  computed: {
    filter (): GetMeetingsRequest {
      return {
        sections: [Number(this.sectionId)]
      } as GetMeetingsRequest
    },
    totalMeetings (): string {
      return (this.meetings ? this.meetings.length : 0).toString()
    },
    meetingsToReview (): string {
      if (!this.meetings) return '0'

      return this.meetings.filter((x: MeetingSummaryResponse) => {
        return x.status.id === MeetingStatus.SUBMITTED
      }).length.toString()
    },
    meetingsToApprove (): string {
      if (!this.meetings) return '0'

      return this.meetings.filter((x: MeetingSummaryResponse) => {
        return x.status.id === MeetingStatus.REVIEWED
      }).length.toString()
    },
    section (): SectionSummaryResponse {
      return this.$store.state.sections.all.find((x: SectionSummaryResponse) => x.id === Number(this.sectionId))
    }
  }
})
