import { userService } from '../services/user.service'
import { router } from '../router'
import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { LoginRequest } from '@/types'
import { AdInfo, ADLoginInfo, Claims } from '@/types/custom'
import { LoggedInState } from '@/types/enums'
import { decodeToken, getRoles } from '@/helpers/auth/authorizationhelper'

export interface AuthenticationState {
    status: LoggedInState,
    token?: string,
    claims?: Claims,
    rolesArray: Array<number>,
    login: LoginRequest,
    loginUrl: string,
    activeDirectory: AdInfo
}

const token = localStorage.getItem('authToken') || undefined

export const state: AuthenticationState = {
  status: token ? LoggedInState.loggedIn : LoggedInState.loggedOut,
  token: token,
  claims: token ? decodeToken(token) : undefined,
  rolesArray: token ? getRoles(token) : [],
  login: {
    email: '',
    password: ''
  },

  loginUrl: '/api/account/getlogintoken',
  activeDirectory: {
    loginUrl: 'https://login.microsoftonline.com/redcross.fi/oauth2/authorize?client_id=8c94c4a5-f31c-4777-ab0b-0a9871791315&response_type=id_token&scope=openid&nonce=7362CAEA-9CA5-4B43-9BA3-34D7C303EBA7&response_mode=fragment',
    adAuthorizeUri: '/api/account/adauthorize',
    redirectUri: '/adresponse'
  }
}

const actions: ActionTree<AuthenticationState, RootState> = {
  loadBaseData ({ dispatch, commit }): Promise<any> {
    commit('alert/dataLoading', null, { root: true })
    const promises = [
      dispatch('districts/loadAll', null, { root: true }),
      dispatch('sections/loadAll', null, { root: true }),
      dispatch('meetings/getAllPositionsOfTrust', null, { root: true })
    ]

    return Promise.all(promises).then(() => {
      commit('alert/dataLoaded', null, { root: true })
      return Promise.resolve()
    })
  },

  login ({ dispatch, commit }: any, login: LoginRequest) {
    commit('loginRequest', login.email)

    return userService.login(login)
      .then(
        (token: any) => {
          const claims = decodeToken(token)
          const rolesArray = getRoles(token)
          commit('loginSuccess', { token, claims, rolesArray })
          return Promise.resolve()
        }, (err: string) => {
          commit('loginFailure', err)
          return Promise.reject(err)
        })
      .then(() => {
        dispatch('loadBaseData')
      })
      .catch((error: Error) => {
        commit('loginFailure', error)
        return Promise.reject(error)
      })
  },

  adlogin ({ dispatch, commit }: any, adLogin: ADLoginInfo) {
    commit('adLoginRequest')

    return userService.adLogin(adLogin)
      .then(
        (token: any) => {
          const claims = decodeToken(token)
          const rolesArray = getRoles(token)
          commit('loginSuccess', { token, claims, rolesArray })
          return Promise.resolve()
        })
      .then(() => dispatch('loadBaseData'))
      .catch((error: any) => {
        commit('loginFailure', error)
        return Promise.reject(error)
      })
  },

  loginFailure ({ commit }, error) {
    commit('loginFailure', error)
  },

  logout ({ commit }) {
    userService.logout()
    commit('logout')
    commit('districts/reset', null, { root: true })
    commit('meetings/reset', null, { root: true })
    commit('members/reset', null, { root: true })
    commit('sections/reset', null, { root: true })
    commit('sections/reset', null, { root: true })
    // todo : Others
  }
}

const mutations: MutationTree<AuthenticationState> = {
  loginRequest (state, token) {
    state.status = LoggedInState.loggingIn
    state.token = token
  },

  loginSuccess (state, tokenObj) {
    state.status = LoggedInState.loggedIn
    state.token = tokenObj.token
    state.claims = tokenObj.claims
    state.rolesArray = tokenObj.rolesArray
  },

  loginFailure (state) {
    state.status = LoggedInState.loggedOut
    state.token = undefined
    state.claims = undefined
    state.rolesArray = []
  },

  adLoginRequest (state) {
    state.status = LoggedInState.loggingIn
  },

  logout (state) {
    state.status = LoggedInState.loggedOut
    state.token = undefined
    state.claims = undefined
    state.rolesArray = []
  }
}

export const authentication: Module<AuthenticationState, RootState> = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations
}
