// import config from 'config'
import store from '../store'
import { LoggedInState } from '@/types/enums'

function handleAfterAuthenticated (): Promise<any> {
  if (store.state.authentication.status === LoggedInState.loggedIn) {
    const promises = [
      store.dispatch('codes/loadAll'),
      store.dispatch('authentication/loadBaseData', null, { root: true })
    ]
    return Promise.all(promises)
  }
  return Promise.resolve()
}

export const mainService = {
  handleAfterAuthenticated
}
