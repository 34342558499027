import { districtService } from '../services'
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store'
import { DistrictActiveRoles, DistrictSummaryResponse } from '@/types'

export interface DistrictState {
  districtsLoaded: boolean,
  all: Array<DistrictSummaryResponse>,
  allUrl: string,
  statsUrl: string,
  resetUrl: string,
  districtRoles: DistrictActiveRoles | null,
  getDistrictRolesByIdUrl: string
}

const getDefaultState = (): DistrictState => {
  return {
    districtsLoaded: false,
    all: [],
    allUrl: '/api/districts',
    statsUrl: '/api/districts/{id}/stats',
    resetUrl: '/api/districts/{id}/reset',
    districtRoles: null,
    getDistrictRolesByIdUrl: '/api/districts/districtroles/{id}'
  }
}

export const state = getDefaultState()

const actions: ActionTree<DistrictState, RootState> = {
  loadAll ({ commit }) {
    commit('loadAllRequest')

    return districtService.getAll()
      .then(
        districts => commit('loadAllSuccess', districts),
        error => commit('loadAllFailure', error)
      )
  },
  
  getDistrictRoles (context, districtId: number) {
    context.commit('loadDistrictRoles')

    return districtService.getDistrictRolesById(districtId)
      .then(
        (district: DistrictActiveRoles) => {
          context.commit('loadDistrictRolesSuccess', district)
          return Promise.resolve()
        }
      ).catch((err: Error) => {
        context.commit('loadDistrictRolesFailure', err)
        return Promise.reject(err)
      })
  }
}

const mutations: MutationTree<DistrictState> = {
  loadAllRequest (state) {
    state.all = []
  },

  loadAllSuccess (state, districts) {
    state.districtsLoaded = true
    state.all = districts
  },

  loadAllFailure (state, error) {
    state.districtsLoaded = false
    state.all = []
  },
  loadDistrictRoles (state) {
    state.districtRoles = null
  },

  loadDistrictRolesSuccess (state, roles) {
    state.districtRoles = roles
  },

  loadDistrictRolesFailure (state, error) {
    state.districtRoles = null
  },

  reset (state) {
    Object.assign(state, getDefaultState())
  }
}

const getters: GetterTree<DistrictState, RootState> = {
  getById: (state: DistrictState) => (id: number) => {
    return state.all.find((x) => x.id === id)
  }
}

export const districts: Module<DistrictState, RootState> = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
}
