
import Vue from 'vue'
import { finnishDate } from '@/helpers/datehelper.ts'
import { MemberPositionResponse } from '@/types'

export default Vue.extend({
  name: 'MemberPositionDetails',
  props: {
    value: {
      type: Array as () => Array<MemberPositionResponse>,
      default: () => []
    }
  },
  methods: {
    getPosition (positionId: number): string {
      const position = (this.$store.state.meetings.positionsOfTrust.find((x: MemberPositionResponse) => x.id === positionId))
      return position ? position.name : ''
    },
    formatDate (date: Date) {
      return finnishDate(date)
    }
  }
})
