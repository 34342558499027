
import Vue from 'vue'
import authHeader from '../helpers/auth/auth-header'
import { processJson } from '../helpers/httphelper'
import Back from '@/components/Back.vue'
import MeetingDetailsForm from '@/components/meetings/MeetingDetailsForm.vue'
import SprButton from '@/components/forms/SprButton.vue'
import { RequestValidationResult, AddOrUpdateMeetingRequest } from '@/types'
import { isRequestValidationResult, MeetingDetailsData } from '@/types/custom.ts'
import { MeetingType } from '@/types/enums'

export default Vue.extend({
  name: 'AddMeeting',
  components: {
    Back,
    MeetingDetailsForm,
    SprButton
  },
  props: {
    sectionId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      meeting: {
        id: undefined,
        sectionId: Number(this.sectionId),
        name: '',
        meetingTypeId: MeetingType.UNKNOWN,
        dateHeld: new Date(),
        reportedBy: '',
        createdBy: '',
        created: new Date(),
        modified: null,
        minutesId: 0,
        moreInfo: '',
        isLatestMeeting: false,
        memberPositions: []
      } as AddOrUpdateMeetingRequest
    }
  },
  methods: {
    createMeeting () {
      const user = this.$store.state.authentication.claims.username

      this.meeting.reportedBy = user
      this.meeting.createdBy = user
    },
    onMeetingTypeUpdated (meetingType: MeetingType) {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      }

      const url = `${this.$store.state.config.ossiServiceBaseUrl}${this.$store.state.meetings.meetingTypeDefaultYearUrl}`.replace('{meetingType}', meetingType.toString())

      return fetch(url, requestOptions)
        .then(processJson)
        .then(date => {
          this.meeting.dateHeld = date
        }).catch(err => Promise.reject(err))
    },
    onMeetingDetailsUpdated (data: MeetingDetailsData) {
      // Only set the date if the meeting type did not change.
      // otherwise let the process for MeetingType set the default date.
      if (data.meetingType && data.meetingType !== this.meeting.meetingTypeId) {
        this.onMeetingTypeUpdated(data.meetingType)
      } else {
        this.meeting.dateHeld = data.dateHeld
      }

      this.meeting.meetingTypeId = data.meetingType ? data.meetingType : MeetingType.UNKNOWN
      this.meeting.name = data.name
      this.meeting.moreInfo = data.moreInfo
    },
    onDeleteMeeting (): void {
      this.$store.dispatch('meetings/deleteSelectedMeeting')
      this.$router.push('/')
    },
    // On save
    onSave (): void {
      this.$validator.validateAll().then((result) => {
        if (this.$validator.errors.items.length) return
        if (result) {
          this.saveMeeting()
        }
      }, (error) => {
        this.$store.commit('alert/dataLoaded', null, { root: true })
        this.$store.dispatch('alert/error', { message: error })
      })
    },
    // Save the meeting
    saveMeeting (): void {
      this.$store.commit('alert/dataLoading', null, { root: true })
      const id = this.$store.dispatch('meetings/saveMeeting', this.meeting)
        .then((id: number) => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          this.$store.dispatch('alert/success', { message: this.$t('addmeeting.savesuccess'), surviveRouteChange: true })
          this.$router.push(`/sections/${this.sectionId}/meetings/${id}`)
        },
        (error: any | RequestValidationResult) => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          if (isRequestValidationResult(error)) {
            this.displayErrors(error)
          } else {
            this.$store.dispatch('alert/error', { message: this.$t('addmeeting.savefailed') })
          }
        })
    },
    // Display error
    displayErrors (error: RequestValidationResult): void {
      for (const err of error.errors) {
        this.$store.dispatch('alert/error', { message: this.$t(`validation.${err.translationKey}`) })
      }
    }
  },
  created (): void {
    this.createMeeting()
  },
  computed: {
    sectionIdNumber (): number {
      // because sectionId from the url is a string
      return Number(this.sectionId)
    },
    isLoading (): boolean {
      return this.$store.state.alert.dataLoading
    },
    meetingDetails (): MeetingDetailsData {
      return {
        meetingType: this.meeting?.meetingTypeId === MeetingType.UNKNOWN ? undefined : this.meeting?.meetingTypeId,
        dateHeld: this.meeting?.dateHeld,
        name: this.meeting?.name,
        moreInfo: this.meeting?.moreInfo
      } as MeetingDetailsData
    }
  }
})
