
import Vue from 'vue'

export default Vue.extend({
  name: 'Pager',
  props: {
    total: {
      type: Number,
      default: 0
    },
    skip: {
      type: Number,
      default: 0
    },
    take: {
      type: Number,
      default: 10
    }
  },
  methods: {
    onPageToStartClicked () {
      this.$emit('pageToStart')
    },
    onPageBackClicked () {
      this.$emit('pageBack')
    },
    onPageForwardClicked () {
      this.$emit('pageForward')
    },
    onPageToEndClicked () {
      this.$emit('pageToEnd')
    }
  },
  computed: {
    fromPager (): number {
      return this.skip + 1
    },
    toPager (): number {
      return Math.min(this.skip + this.take, this.total)
    },
    pageBackVisible (): boolean {
      return this.skip >= this.take
    },
    pageForwardVisible (): boolean {
      return this.skip + this.take < this.total
    },
    pageLocationVisible (): boolean {
      return this.total > 0
    }
  }
})
