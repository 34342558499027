
import Vue from 'vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap/src/components/VueTypeaheadBootstrap.vue'

export default Vue.extend({
  name: 'SprTypeahead',
  components: { VueTypeaheadBootstrap },
  // eslint-disable-next-line
  $_veeValidate: {
    value (this: any) {
      return this.localVal
    },
    rejectsFalse: true
  },
  props: {
    value: {
      type: [String, Object],
      default: ''
    },
    values: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    maxMatches: {
      type: Number,
      default: 20
    },
    minMatchingChars: {
      type: Number,
      default: 1
    },
    serializer: {
      type: Function,
      default: function (s: any) {
        return s.text ?? s
      }
    },
    // whether the field is required
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localVal (this: any) {
      if (!this.value) return ''
      return (typeof this.value === 'string') ? this.value : this.value.text
    },
    inputClass (this: any) {
      return this.hasError ? 'has-error' : ''
    }
  },
  methods: {
    // when a value is selected from the dropdown
    onHit (val: Record<string, any>) {
      this.$emit('hit', val)
    },
    onInput (val: Record<string, any>) {
      this.$emit('input', val)
    }
  }
})
