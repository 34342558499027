
import Vue from 'vue'
import SwitchLanguage from '@/components/layout/SwitchLanguage.vue'
import tickcrosswarning from '@/components/SPRTickCrossWarning.vue'

export default Vue.extend({
  name: 'Header',
  components: { SwitchLanguage, tickcrosswarning },
  props: {
    option1Value: {
      type: Number,
      required: false,
      default: 3 // 3 - Warning
    }
  },
  computed: {
    bannerVisible (): string {
      return '' //this.$t('join.personalHeaderBanner').toString()
    },
    isLoggedIn (): boolean {
      return this.$store.state.authentication.status === 'LOGGEDIN'
    },
    username (): string {
      return this.$store.state.authentication?.claims?.username
    }
  }
})
