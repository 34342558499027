
import Vue from 'vue'
import SprFormText from '@/components/forms/SprFormText.vue'
import SprFormDropdown from '@/components/forms/SprFormDropdown.vue'
import { DropdownOptions, MemberFilter } from '@/types/custom'
import { MembershipStatus } from '@/types/enums'

export default Vue.extend({
  name: 'MemberSearchCriteria',
  components: { SprFormText, SprFormDropdown },
  props: {
    mode: {
      type: String,
      required: true
    },
    searchCriteria: {
      type: Object,
      required: true,
      default () {
        return {
          name: '',
          approved: true,
          hasMembership: true,
          membershipStatus: MembershipStatus.ACTIVE
        } as MemberFilter
      }
    },
    membershipStatuses: {
      type: Array as () => Array<MembershipStatus>,
      default: [
        MembershipStatus.ALL,
        MembershipStatus.ACTIVE,
        MembershipStatus.RESIGNED,
        MembershipStatus.DEAD,
        MembershipStatus.TRANSFER,
        MembershipStatus.PENDING,
        MembershipStatus.NEW
      ]
    }
  },
  data () {
    return {
      hasSearched: false
    }
  },
  computed: {
    approvedString (): string {
      return this.searchCriteria && this.searchCriteria.approved !== undefined ? this.searchCriteria.approved.toString() : ''
    },
    hasMembershipString (): string {
      return this.searchCriteria && this.searchCriteria.hasMembership !== undefined ? this.searchCriteria.hasMembership.toString() : ''
    },
    membershipStatusString (): string {
      return this.searchCriteria && this.searchCriteria.membershipStatus !== undefined ? this.searchCriteria.membershipStatus.toString() : ''
    },
    membershipList () {
      return [
        { text: this.$t('all'), value: '' },
        { text: this.$t('yes'), value: 'true' },
        { text: this.$t('no'), value: 'false' }
      ]
    },
    membershipStatusList (): Array<DropdownOptions> {
      const initialList = this.membershipStatuses.map((status: MembershipStatus) => {
        return { text: this.$t(`membershipstatus-${status}`), value: status.toString() }
      })

      // const emptyOption = { text: this.$t('all'), value: '0' } as DropdownOptions
      // initialList.splice(0, 0, emptyOption)
      return initialList
    },
    showMembershipStatusCriteria (): boolean {
      if (this.mode === 'District') {
        return false
      }
      if (this.mode === 'Section') {
        return false
      }
      return true
    }
  },
  methods: {
    onNameSelected (val: string): void {
      this.hasSearched = true
      const clone = Object.assign({}, this.searchCriteria) as MemberFilter
      clone.name = val
      this.$emit('input', clone)
    },
    onApprovedSelected (val: string): void {
      this.hasSearched = true
      const clone = Object.assign({}, this.searchCriteria) as MemberFilter
      clone.approved = val.length ? val === 'true' : undefined
      this.$emit('input', clone)
    },
    onHasMembershipSelected (val: string): void {
      this.hasSearched = true
      const clone = Object.assign({}, this.searchCriteria) as MemberFilter
      clone.hasMembership = val.length ? val === 'true' : undefined
      this.$emit('input', clone)
    },
    onMembershipStatusSelected (val: string): void {
      this.hasSearched = true
      const clone = Object.assign({}, this.searchCriteria) as MemberFilter
      clone.membershipStatus = val.length ? Number(val) : undefined
      this.$emit('input', clone)
    },
    onReset (): void {
      this.$emit('reset')
      this.hasSearched = false
    }
  }
})
