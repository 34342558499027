import { render, staticRenderFns } from "./SprHiddenDateField.vue?vue&type=template&id=3641d0aa&scoped=true"
import script from "./SprHiddenDateField.vue?vue&type=script&lang=ts"
export * from "./SprHiddenDateField.vue?vue&type=script&lang=ts"
import style0 from "./SprHiddenDateField.vue?vue&type=style&index=0&id=3641d0aa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3641d0aa",
  null
  
)

export default component.exports