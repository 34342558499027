/*
Ensure these match Model/Dbo/Code.cs enums in the back end.

TODO: Work out a way of maybe dynamically generating this file from the back end? How do we make sure these are consistent?
*/

export enum CodeCategory {
  MEETING_STATUS = 'MeetingStatus',
  MEETING_TYPE = 'MeetingType',
  MEMBERSHIP_STATUS = 'MembershipStatus',
  MEMBERSHIP_TYPE = 'MembershipType',
  MEMBER_POSITION_STATUS = 'MemberPositionStatus',
  MEMBER_STATUS = 'MemberStatus',
  MINUTES_STATUS = 'MinutesStatus',
  SECTION_STATUS = 'SectionStatus',
  DISTRICT_STATUS = 'DistrictStatus'
}

export enum MemberStatus {
  ACTIVE = 101,
  INACTIVE = 102,
  DEAD = 103,
  OMANEW = 104,
  ELOQUANEW = 105,
  REMOVED = 106
}

export enum MemberGender {
  MALE = 201,
  FEMALE = 202,
  OTHER = 203
}

export enum MemberLanguage {
  FINNISH = 301,
  SWEDISH = 302,
  ENGLISH = 303
}

export enum MembershipType {
  UNKNOWN = 400,
  ANNUAL = 401,
  PERMANENT = 402,
  YOUTH = 403,
  HONORARY = 404
}

export enum MembershipStatus {
  ALL = 0,
  UNKNOWN = 500,
  ACTIVE = 501,
  RESIGNED = 502,
  DEAD = 503,
  TRANSFER = 504,
  PENDING = 505,
  NEW = 506
}

export enum SectionStatus {
  // TEST = 600,
  ACTIVE = 601,
  DISBANDED = 602,
  MONITORED = 603
}

export enum SectionLanguage {
  FINNISH = 701,
  SWEDISH = 702,
  BILINGUAL = 703
}

export enum DistrictStatus {
  // TEST = 0,
  ACTIVE = 801,
  DISBANDED = 802
}

export enum DistrictLanguage {
  FINNISH = 901,
  SWEDISH = 902,
  BILINGUAL = 903
}

export enum MeetingType {
  UNKNOWN = 1000,
  AUTUMN = 1001,
  SPRING = 1002,
  BOARD = 1003,
  EXTRAORDINARY = 1004,
  CRM = 1005
}

export enum MeetingStatus {
  DRAFT = 1100,
  SUBMITTED = 1101,
  REVIEWED = 1102,
  APPROVED = 1103,
  ARCHIVED = 1104,
  PENDINGAPPROVAL = 1105
}

export enum MinutesStatus {
  DRAFT = 1200,
  APPROVED = 1201
}

export enum MemberPositionStatus {
  ACTIVE = 1301,
  INACTIVE = 1302
}

export enum PositionStatus {
  ACTIVE = 1401,
  INACTIVE = 1402
}

export enum OfficialLetterRequestStatus {
  REQUESTED = 1501,
  PROCESSED = 1502,
  ARCHIVED = 1503
}

export enum MeetingDisplayStatus {
  UNKNOWN = 0,
  CREATED = 1,
  SUBMITTED = 2,
  REVIEWED = 3,
  LATEST = 4,
  REPLACED = 5,
  ARCHIVED = 6
}

export enum MemberRoles {
  SUPER_ADMIN = 1,
  KT = 2,
  DISTRICT_ADMIN = 3,
  DISTRICT_USER = 4,
  SECTION_ADMIN = 5,
  SECTION_USER = 6
}

export enum AlertTimeout {
  SHORT = 2000,
  NORMAL = 6000,
  LONG = 15000,
  PERMANENT = -1
}

export enum LoggedInState {
  loggedIn = 'LOGGEDIN',
  loggingIn = 'LOGGINGIN',
  loggedOut = 'LOGGEDOUT'
}
