// import config from 'config'
import store from '../store'
import authHeader from '../helpers/auth/auth-header'
import { handleErrors, processJson } from '../helpers/httphelper'
import {
  SectionSummaryResponse,
  SectionDetailedResponse,
  ReportBoardMembersRequest,
  OfficialLetterRequestResponse,
  AddOfficialLetterRequestRequest,
  ApproveOfficialLetterRequestRequest,
  SignedDocumentRequest,
  SignedDocumentResponse,
  SectionStatisticsResponse,
  SectionActiveRoles } from '@/types'

function getAll (): Promise<Array<SectionSummaryResponse>> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.allUrl}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      sections => {
        return sections
      }
    )
}

function getById (id: number): Promise<SectionDetailedResponse> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.getByIdUrl}`.replace('{id}', id.toString())

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      section => {
        return section
      }
    )
}

function getSectionRolesById (id: number): Promise<SectionActiveRoles> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.getSectionRolesByIdUrl}`.replace('{id}', id.toString())

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      section => {
        return section
      }
    )
}

function getBoardMembersPdf (data: ReportBoardMembersRequest): Promise<Blob> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.getBoardMembersPdfUrl}`

  return fetch(url, requestOptions)
    .then(
      (response: Response) => {
        return response.arrayBuffer()
      }
    )
    .then((buffer: ArrayBuffer) => {
      return new Blob([buffer], { type: 'application/pdf' })
    })
}

function getSigningRightsMembersPdf (sectionId: number): Promise<Blob> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.getSigningRightsMembersPdfUrl}`.replace('{id}', sectionId.toString())

  return fetch(url, requestOptions)
    .then(
      (response: Response) => {
        return response.arrayBuffer()
      }
    )
    .then((buffer: ArrayBuffer) => {
      return new Blob([buffer], { type: 'application/pdf' })
    })
}

function getOfficialLetterRequests (sectionId?: number): Promise<Array<OfficialLetterRequestResponse>> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.getOfficialLetterRequestsUrl}`.replace('{id}', sectionId ? sectionId.toString() : '')

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      response => response
    )
}

function getOfficialLetterRequestById (id: number): Promise<OfficialLetterRequestResponse> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.getOfficialLetterRequestByIdUrl}`.replace('{id}', id.toString())

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      response => response
    )
}

function addOfficialLetterRequest (data: AddOfficialLetterRequestRequest): Promise<boolean> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.addOfficialLetterRequestUrl}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      (response: boolean) => response
    )
}

function uploadSignedDocument (this: any, doc: SignedDocumentRequest): Promise<SignedDocumentResponse> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(doc)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.uploadSignedDocUrl}`
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      doc => {
        return (doc as SignedDocumentResponse)
      }
    ).catch(err => Promise.reject(err))
}

function removeSignedDocument (id: number): Promise<boolean> {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.removeSignedDocUrl}`.replace('{id}', id.toString())
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(() => true)
    .catch(err => Promise.reject(err))
}

function approveLetterRequest (request: ApproveOfficialLetterRequestRequest): Promise<boolean> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(request)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.approveLetterRequestUrl}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      () => true
    )
}

function archiveLetterRequest (id: number): Promise<boolean> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.archiveLetterRequestUrl}`.replace('{id}', id.toString())

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      () => true
    )
}

function deleteLetterRequest (id: number): Promise<boolean> {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.deleteLetterRequestUrl}`.replace('{id}', id.toString())

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      () => true
    )
}

function getSectionStatistics (id: number): Promise<SectionStatisticsResponse> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.getSectionStatisticsUrl}`.replace('{id}', id.toString())

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      stats => {
        return stats
      }
    )
}

function resetSection (id: number): Promise<boolean> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.sections.resetSectionUrl}`.replace('{id}', id.toString())

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      () => true
    )
}

export const sectionService = {
  getAll,
  getById,
  getBoardMembersPdf,
  getSigningRightsMembersPdf,
  getOfficialLetterRequests,
  getOfficialLetterRequestById,
  getSectionRolesById,
  addOfficialLetterRequest,
  uploadSignedDocument,
  removeSignedDocument,
  approveLetterRequest,
  archiveLetterRequest,
  deleteLetterRequest,
  getSectionStatistics,
  resetSection
}
