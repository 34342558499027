import { AlertTimeout } from '@/types/enums'
import { RequestValidationResult } from '@/types'
import { TranslateResult } from 'vue-i18n'

// Auth types
export interface LoginInfo {
    email: string,
    password: string
}

export interface ADLoginInfo {
  token: string,
  sessionState: string
}

export interface AdInfo {
  loginUrl: string,
  redirectUri: string,
  adAuthorizeUri: string
}

export const enum YesNoWarning {
  No = 2,
  Warning = 3,
  Yes = 1
}

export interface Claims {
  username: string,
  ossiId: number,
  omaId: string,
  crmId: string,
  sprId: string,
  sectionId: number,
  districtId: number,
  membershipStatusId: number,
  membershipTypeId: number,
  isInRoleSuperAdmin: boolean,
  isInRoleKT: boolean,
  isInRoleDistrictAdmin: boolean,
  isInRoleDistrictUser: boolean,
  isInRoleSectionAdmin: boolean,
  isInRoleSectionUser: boolean,
  passwordExpiring: boolean
}

export interface MeetingDetailsData {
  meetingType?: number,
  dateHeld: Date,
  name: string,
  moreInfo: string
}

// Alerts

export interface AlertOptions {
  message: string,
  surviveRouteChange: boolean,
  timeout: AlertTimeout | number
}

export interface Alert {
    id: number,
    type?: string,
    message: string,
    surviveRouteChange: boolean,
    timeout: AlertTimeout | number,
    createTime: Date,
    isHtml: boolean
}

export class Alert implements Alert {
  constructor (id: number, message: string, type?: string, surviveRouteChange?: boolean, timeout?: AlertTimeout | number, isHtml?: boolean) {
    this.id = id
    this.message = message
    this.type = type
    this.surviveRouteChange = surviveRouteChange !== undefined ? surviveRouteChange : false
    this.timeout = timeout || AlertTimeout.NORMAL
    this.createTime = new Date()
    this.isHtml = isHtml ?? false
  }
}

// type guard
export function isRequestValidationResult (response: any | RequestValidationResult): response is RequestValidationResult {
  return (response.isValid !== undefined && response.errors !== undefined)
}

export interface MeetingFilter {
  sectionId?: number,
  typeId?: number,
  statusIds?: Array<number>,
  meetingDate?: Date
}

export interface MemberFilter {
  name: string,
  approved?: boolean,
  hasMembership?: boolean,
  membershipStatus?: number,
  extraMembershipStatuses?: number[]
}

export interface MemberPositionFormData {
  memberId?: number,
  positionId?: number,
  nonMemberAddress: string,
  nonMemberEmail: string,
  nonMemberCompany: string,
  nonMemberFirstname: string,
  nonMemberLastname: string,
  nonMemberPhone: string,
  nonMemberPostcode: string,
  nonMemberPostoffice: string,
  termEnd: Date,
  termStart: Date
}

export interface DropdownOptions {
  text: string | TranslateResult,
  value: string
}

export interface CheckboxListItem {
  id: string | number,
  label: string,
  checked: boolean
}

// Basic types and enums

export interface BoardMemberData {
  id: number,
  memberId?: number,
  positionId: number
}

// Key value pair
export interface StringArray {
  [index: string]: string
}