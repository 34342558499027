
import Vue from 'vue'
import { debounce } from '@/helpers/httphelper.ts'

export default Vue.extend({
  name: 'SprFormTextArea',
  inject: ['$validator'],
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    validations: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      localVal: ''
    }
  },
  methods: {
    onValChanged () {
      this.$emit('input', this.localVal)
    }
  },
  mounted () {
    this.localVal = this.value
  },
  watch: {
    value () {
      this.localVal = this.value
    }
  }
})
