
import Vue from 'vue'

export default Vue.extend({
  name: 'Tooltip',
  props: {
    tooltipMessage: {
      type: String,
      required: true
    },
    tooltipTargetIdName: {
      type: String,
      required: true
    },
    value: {
      type: Boolean, // Show the tooltip?
      default: false
    }
  }
})
