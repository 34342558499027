
import Vue from 'vue'
import moment from 'moment'
import { MemberFlatResponse, MemberPositionSummaryResponse, ErrorResult } from '@/types'
import { CodeCategory } from '@/types/enums'
import { membersService } from '@/services'
import { formatErrors } from '@/helpers/validationhelper'
import Back from '@/components/Back.vue'
import MemberDetails from '@/components/members/MemberDetails.vue'
import MemberRoleDetails from '@/components/members/MemberRoleDetails.vue'
import MemberPositionDetails from '@/components/members/MemberPositionDetails.vue'

export default Vue.extend({
  name: 'EditMember',
  components: { Back, MemberDetails, MemberRoleDetails, MemberPositionDetails },
  props: {
    sectionId: {
      type: String,
      default: undefined
    },
    memberId: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      member: {} as MemberFlatResponse,
      isRoleSaveInProgress: false
    }
  },
  computed: {
    memberIdNumber (): number {
      return Number(this.memberId)
    },
    orderedPositions (): Array<MemberPositionSummaryResponse> {
      // slice to clone sorted array
      if (!this.member.memberPositions) return [] as Array<MemberPositionSummaryResponse>
      return this.member.memberPositions.slice(0).sort((a: MemberPositionSummaryResponse, b: MemberPositionSummaryResponse) => {
        const starta: number = moment(a.termStart, 'D.M.YYYY').valueOf()
        const startb: number = moment(b.termStart, 'D.M.YYYY').valueOf()
        if (starta > startb) return -1
        if (starta === startb) return 0
        return 1
      })
    },
    canEditRoles (): boolean {
      return this.$store.state.authentication.claims.isInRoleSuperAdmin ||
        this.$store.state.authentication.claims.isInRoleKT ||
        this.$store.state.authentication.claims.isInRoleDistrictAdmin ||
        this.$store.state.authentication.claims.isInRoleSectionAdmin
    }
  },
  created (): void {
    this.loadMember(this.memberIdNumber).then((member: MemberFlatResponse) => {
      this.member = member
    })
  },
  methods: {
    loadMember (memberId: number): Promise<MemberFlatResponse> {
      return new Promise((resolve, reject) => {
        membersService.getById(this.memberIdNumber)
          .then((response: MemberFlatResponse) => {
            resolve(response)
          }).catch((fail: Error) => {
            // eslint-disable-next-line no-console
            console.log(fail)
            this.$store.dispatch('alert/error', { message: this.$t('editmember.loadfailed'), surviveRouteChange: true }, { root: true })
            reject(fail)
          })
      })
    },
    onRoleChanged (roles: Array<number>): void {
      this.isRoleSaveInProgress = true
      membersService.updateRoles(this.memberIdNumber, roles)
        .then((response) => {
          return this.loadMember(this.memberIdNumber)
        }, err => {
          if (err && err.body && err.body.isValid !== 'undefined' && !err.body.isValid) {
            const validationMessage: string = this.$t('validation.htmlheadertext').toString().replace('{{ errors }}', formatErrors(err.body.errors.map((x: ErrorResult) => this.$t(`validation.${x.translationKey}`))))
            this.$store.dispatch('alert/error', { message: validationMessage, isHtml: true })
          }
        })
        .then((member: void | MemberFlatResponse) => {
          this.member = member as MemberFlatResponse
          this.$store.dispatch('alert/success', { message: this.$t('editmember.updatesuccess') })
          this.isRoleSaveInProgress = false
        })
        .catch((fail: any) => {
          // eslint-disable-next-line no-console
          console.log(fail)
          this.$store.dispatch('alert/error', { message: this.$t('editmember.rolesupdatefailed'), surviveRouteChange: true }, { root: true })
          this.isRoleSaveInProgress = false
        })
    }
  }
})
