import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import ErrorPage from './views/Error.vue'
import Login from './views/Login.vue'
import Logout from './views/Logout.vue'
import ADResponse from './views/ADResponse.vue'
import Districts from './views/Districts.vue'
import District from './views/District.vue'
import DistrictMeetings from './views/DistrictMeetings.vue'
import DistrictMembers from './views/DistrictMembers.vue'
import Sections from './views/Sections.vue'
import Section from './views/Section.vue'
import Members from './views/Members.vue'
import SectionMembers from './views/SectionMembers.vue'
import EditMember from './views/EditMember.vue'
import Meetings from './views/Meetings.vue'
import AddMeeting from './views/AddMeeting.vue'
import EditMeeting from './views/EditMeeting.vue'
import Reports from './views/Reports.vue'
import OfficialLetterRequests from './views/OfficialLetterRequests.vue'
import AddOfficialLetterRequest from './views/AddOfficialLetterRequest.vue'
import ProcessOfficialLetterRequest from './views/ProcessOfficialLetterRequest.vue'
import NotFound from './views/NotFound.vue'
import { MemberRoles } from '@/types/enums'
import store from '@/store'

Vue.use(Router)

// add beforeEnter: Authenticate to any route that requires authentication
const Authenticate = (to: any, from: any, next: any) => {
  const loggedIn = localStorage.getItem('authToken')
  if (!loggedIn) {
    return next('/login')
  }

  // if the allowed roles for this url are not in the users array of roles, send them to the homepage
  const accessArray = to.meta.access
  if (!accessArray) {
    next()
    return
  }

  const rolesArray = store.state.authentication.rolesArray
  if (accessArray.filter((x: number) => {
    return rolesArray.indexOf(x) !== -1
  }).length === 0) return next('/404')

  next()
}

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: Authenticate
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout
    },
    {
      path: '/adresponse',
      name: 'adresponse',
      component: ADResponse
    },
    {
      path: '/officialletterrequests',
      name: 'officialletterrequests',
      component: OfficialLetterRequests,
      beforeEnter: Authenticate
    },
    {
      path: '/officialletterrequests/:id',
      name: 'processofficialletterrequest',
      component: ProcessOfficialLetterRequest,
      beforeEnter: Authenticate,
      props: true
    },
    {
      path: '/members',
      name: 'members',
      component: Members,
      beforeEnter: Authenticate,
      meta: {
        access: [
          MemberRoles.SUPER_ADMIN,
          MemberRoles.KT
        ]
      }
    },
    {
      path: '/districts',
      name: 'districts',
      component: Districts,
      beforeEnter: Authenticate,
      meta: {
        access: [
          MemberRoles.SUPER_ADMIN,
          MemberRoles.KT
        ]
      }
    },
    {
      path: '/districts/:districtId',
      name: 'district',
      component: District,
      beforeEnter: Authenticate,
      meta: {
        access: [
          MemberRoles.SUPER_ADMIN,
          MemberRoles.KT,
          MemberRoles.DISTRICT_ADMIN,
          MemberRoles.DISTRICT_USER
        ]
      },
      props: true
    },
    {
      path: '/districts/:districtId/meetings',
      name: 'districtmeetings',
      component: DistrictMeetings,
      beforeEnter: Authenticate,
      meta: {
        access: [
          MemberRoles.SUPER_ADMIN,
          MemberRoles.KT,
          MemberRoles.DISTRICT_ADMIN,
          MemberRoles.DISTRICT_USER
        ]
      },
      props: true
    },
    {
      path: '/districts/:districtId/members',
      name: 'districtmembers',
      component: DistrictMembers,
      beforeEnter: Authenticate,
      meta: {
        access: [
          MemberRoles.SUPER_ADMIN,
          MemberRoles.KT,
          MemberRoles.DISTRICT_ADMIN,
          MemberRoles.DISTRICT_USER
        ]
      },
      props: true
    },
    {
      path: '/districts/:districtId/sections',
      name: 'sections',
      component: Sections,
      beforeEnter: Authenticate,
      meta: {
        access: [
          MemberRoles.SUPER_ADMIN,
          MemberRoles.KT,
          MemberRoles.DISTRICT_ADMIN,
          MemberRoles.DISTRICT_USER
        ]
      },
      props: true
    },
    {
      path: '/sections/:sectionId',
      name: 'section',
      component: Section,
      beforeEnter: Authenticate,
      meta: {
        access: [
          MemberRoles.SUPER_ADMIN,
          MemberRoles.KT,
          MemberRoles.DISTRICT_ADMIN,
          MemberRoles.DISTRICT_USER,
          MemberRoles.SECTION_ADMIN,
          MemberRoles.SECTION_USER
        ]
      },
      props: true
    },
    {
      path: '/sections/:sectionId/members',
      name: 'sectionmembers',
      component: SectionMembers,
      beforeEnter: Authenticate,
      meta: {
        access: [
          MemberRoles.SUPER_ADMIN,
          MemberRoles.KT,
          MemberRoles.DISTRICT_ADMIN,
          MemberRoles.DISTRICT_USER,
          MemberRoles.SECTION_ADMIN,
          MemberRoles.SECTION_USER
        ]
      },
      props: true
    },
    {
      path: '/sections/:sectionId/members/:memberId',
      name: 'editmember',
      component: EditMember,
      beforeEnter: Authenticate,
      props: true
    },
    {
      path: '/sections/:sectionId/meetings',
      name: 'meetings',
      component: Meetings,
      beforeEnter: Authenticate,
      props: true
    },
    {
      path: '/sections/:sectionId/meetings/add',
      name: 'addmeeting',
      component: AddMeeting,
      beforeEnter: Authenticate,
      meta: {
        access: [
          MemberRoles.SUPER_ADMIN,
          MemberRoles.KT,
          MemberRoles.DISTRICT_ADMIN,
          MemberRoles.DISTRICT_USER,
          MemberRoles.SECTION_ADMIN
        ]
      },
      props: true
    },
    {
      path: '/sections/:sectionId/meetings/:meetingId',
      name: 'editmeeting',
      component: EditMeeting,
      beforeEnter: Authenticate,
      props: true
    },
    {
      path: '/sections/:sectionId/reports',
      name: 'reports',
      component: Reports,
      beforeEnter: Authenticate,
      props: true
    },
    {
      path: '/sections/:sectionId/reports/addofficialletterrequest',
      name: 'addofficialletterrequest',
      component: AddOfficialLetterRequest,
      beforeEnter: Authenticate,
      props: true
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // good for not so popular pages
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
      beforeEnter: Authenticate
    },
    {
      path: '*',
      name: '404',
      component: NotFound
    }
  ]
})

export default router
