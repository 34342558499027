
import Vue from 'vue'
import LinkIconBox from '@/components/LinkIconBox.vue'
import StatisticTile from '@/components/StatisticTile.vue'
import SectionSummary from '@/components/sections/SectionSummary.vue'
import ActivePositionsOfTrustSummary from '@/components/sections/ActivePositionsOfTrustSummary.vue'
import UpcomingPositionsOfTrustSummary from '@/components/sections/UpcomingPositionsOfTrustSummary.vue'
import { MemberPositionSummaryResponse, PositionOfTrustResponse, SectionDetailedResponse, SectionStatisticsResponse } from '@/types'
import demoNavTileImage1 from '@/assets/img/demonavtile1.png'
import demoNavTileImage2 from '@/assets/img/demonavtile2.png'
import demoNavTileImage3 from '@/assets/img/demonavtile3.png'
import { TranslateResult } from 'vue-i18n'
import { MeetingStatus } from '@/types/enums'

// Just demo images, waiting for some more design guides

export default Vue.extend({
  name: 'Section',
  components: {
    LinkIconBox,
    StatisticTile,
    SectionSummary,
    ActivePositionsOfTrustSummary,
    UpcomingPositionsOfTrustSummary
  },
  props: {
    sectionId: {
      type: String,
      required: true
    }
  },
  methods: {
    reportsClicked () {
      if (this.section !== null) {
        this.$router.push(`/sections/${this.section.id}/reports`)
      }
    },
    usersClicked () {
      if (this.section !== null) {
        // @ts-ignore - object is possibly null. No it frickin isn't.
        this.$router.push(`/sections/${this.section.id}/members`)
      }
    },
    addMeetingClicked () {
      if (this.section !== null) {
        // @ts-ignore
        this.$router.push(`/sections/${this.section.id}/meetings/add`)
      }
    },
    meetingsClicked () {
      if (this.section !== null) {
        // @ts-ignore
        this.$router.push(`/sections/${this.section.id}/meetings`)
      }
    },
    statisticClicked (name: string) {
      // eslint-disable-next-line no-console
      console.log(`${name} stat was clicked`)
    },
    resetSectionSummary () {
      this.loadSection()
    },
    loadSection () {
      this.$store.commit('alert/dataLoading', null, { root: true })
      const userSectionId = Number(this.sectionId)
      // load the detailed section info and stats into the store
      const promises = [
        this.$store.dispatch('sections/getSectionStatistics', userSectionId),
        this.$store.dispatch('sections/loadDetailed', userSectionId)
      ]

      Promise.all(promises)
        .then(results => {
          this.stats = this.$store.state.sections.sectionStatistics
          this.section = this.$store.state.sections.selectedSection
          this.$store.commit('alert/dataLoaded', null, { root: true })
        })
        .catch((err: any) => {
          // eslint-disable-next-line no-console
          console.log(err)
          this.$store.dispatch('alert/error', { message: this.$t('error'), surviveRouteChange: true })
        })
    }
  },
  data () {
    return {
      section: {} as SectionDetailedResponse,
      demoImage1: demoNavTileImage1,
      demoImage2: demoNavTileImage2,
      demoImage3: demoNavTileImage3,
      stats: {
        totalMembers: 0,
        paidMembers: 0,
        newMembers: 0
      } as SectionStatisticsResponse
    }
  },
  mounted () {
    this.loadSection()
  },
  computed: {
    isSectionEditAccess (): boolean {
      return false // Section can be edited only in the CRM! (CRM 2.x)
    },
    isSectionBasicAccess (): boolean {
      // Can only view the section they are in
      return (
        this.$store.state.authentication.claims.isInRoleSuperAdmin ||
        this.$store.state.authentication.claims.isInRoleKT ||
        this.$store.state.authentication.claims.isInRoleDistrictAdmin ||
        this.$store.state.authentication.claims.isInRoleDistrictUser ||
        this.$store.state.authentication.claims.isInRoleSectionAdmin ||
        this.$store.state.authentication.claims.isInRoleSectionUser
      )
    },
    isSectionReport (): boolean {
      return false // Disabled for now #6129. Replace isSectionReport with isSectionBasicAccess once enabling!
    },
    isCreateMeetingAccess (): boolean {
      return this.$store.state.authentication.claims.isInRoleSectionAdmin
    },
    activePositionsOfTrust (): Array<MemberPositionSummaryResponse> {
      return this.section.positionsOfTrust.filter(x => {
        return x.termStart < new Date() && x.meetingStatus.id === MeetingStatus.APPROVED
      })
    },
    upcomingPositionsOfTrust (): Array<MemberPositionSummaryResponse> {
      return this.section.positionsOfTrust.filter(x => {
        return x.termStart > new Date()
      })
    },
    statSectionTotalMembersValue (): number {
      return this.stats.totalMembers
    },
    statSectionTotalMembersText (): TranslateResult {
      return this.$t('stats.sectiontotalmembers')
    },
    statSectionPaidMembersValue (): number {
      return this.stats.paidMembers
    },
    statSectionPaidMembersText (): TranslateResult {
      return this.$t('stats.paidmembers')
    },
    statSectionNewMembersValue (): number {
      return this.stats.newMembers
    },
    statSectionNewMembersText (): TranslateResult {
      return this.$t('stats.newmembers')
    }
  }
})
