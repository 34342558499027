
import Vue from 'vue'

export default Vue.extend({
  name: 'SprFormPassword',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localValue: ''
    }
  },
  mounted () {
    // local copies to prevent 'props being modified directly' warning.
    this.localValue = this.value
  }
})
