import { AddressResponse } from '@/types'

export function isAddressEmpty (address: AddressResponse): boolean {
  if (!address) return true
  return !address.address1 &&
  !address.address2 &&
  !address.postalCode &&
  !address.city &&
  !address.country
}

export function formatAddress (address: AddressResponse): string {
  let add = ''
  if (address.address1) add = add.concat(address.address1, ' ')
  if (address.address2) add = add.concat(address.address2, ' ')
  if (address.postalCode) add = add.concat(address.postalCode, ' ')
  if (address.city) add = add.concat(address.city, ' ')
  if (address.country) add = add.concat(address.country, ' ')
  return add
}