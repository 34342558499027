
import Vue from 'vue'
import Back from '@/components/Back.vue'
import { SectionSummaryResponse, SectionActiveRoles, FindMemberRequest } from '@/types'
import SectionRolesList from '@/components/sections/SectionRolesList.vue'
import SectionMembersList from '@/components/sections/SectionMembersList.vue'
import { MembershipStatus } from '@/types/enums'
import SprButton from '@/components/forms/SprButton.vue'
import ExcelJS from 'exceljs'
export default Vue.extend({

  name: 'SectionMembers',
  components: {
    Back,
    SectionRolesList,
    SectionMembersList,
    SprButton
  },
  props: {
    sectionId: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      membersList: [],
      memberRolesVisible: false,
      memberListVisible: true,
      filter: { // the actual filter passed to the back end
        sections: [
          Number(this.sectionId)
        ],
        includeMemberPositions: true,
        name: '',
        districts: [],
        hasCurrentMembership: undefined,
        isMembershipApproved: undefined,
        membershipStatuses: [MembershipStatus.ACTIVE],
        membershipTypes: [],
        statuses: [],
        skip: 0,
        take: 0
      } as FindMemberRequest
    }
  },
  computed: {
    canExport (): boolean {
      return this.membersList.length > 0
    },
    sectionname (): string {
      if (this.$store.state.sections.sectionsLoaded) {
        const section = this.$store.state.sections.all.find((x: SectionSummaryResponse) => x.id === Number(this.sectionId))
        return section ? section.name : ''
      }
      return ''
    },
    isSectionAdminAndAbove (): boolean {
      const claims = this.$store.state.authentication.claims
      return claims.isInRoleSuperAdmin ||
        claims.isInRoleKT ||
        claims.isInRoleDistrictAdmin ||
        claims.isInRoleSectionAdmin
    }
  },
  methods: {
    handleMembersUpdated (updatedMembersList: any, filter: any) {
      this.membersList = updatedMembersList
      this.filter = filter
    },
    sectionRolesClicked () {
      this.swapTab('memberroles')
      //      this.$router.push(`/sections/${this.sectionId}/roles`)
    },
    sectionMembersClicked () {
      this.swapTab('memberlist')
    },
    swapTab (tabname: string): void {
      this.memberListVisible = tabname === 'memberlist'
      this.memberRolesVisible = tabname === 'memberroles'
    },
    onExport (filter: any) {
      this.$store.commit('alert/dataLoading', null, { root: true })
      const unpagedFilter = filter
      unpagedFilter.take = 0
      unpagedFilter.skip = 0
      this.$store.dispatch('members/getAllSectionMembers', unpagedFilter).then(s => {
        this.$store.commit('alert/dataLoaded', null, { root: true })
        const memberSearchResult = this.$store.state.members?.filteredMembers
        if (!memberSearchResult || !memberSearchResult.results || memberSearchResult.results === 0) return null

        const workbook = new ExcelJS.Workbook()

        // Add a worksheet to the workbook
        const worksheet = workbook.addWorksheet('Members')

        // Define the column headers
        worksheet.columns = [
          { header: 'FirstName', key: 'firstName', width: 20 },
          { header: 'LastName', key: 'lastName', width: 20 },
          { header: 'Email', key: 'email', width: 30 },
          { header: 'Address 1', key: 'address1', width: 20 },
          { header: 'Address 2', key: 'address2', width: 20 },
          { header: 'City', key: 'city', width: 20 },
          { header: 'Postal Code', key: 'postalCode', width: 20, style: { numFmt: '@' } }, // Set the column as text
          { header: 'Country', key: 'country', width: 20 },
          { header: 'Payment Date', key: 'paymentdate', width: 20, style: { numFmt: 'dd.MM.yyyy' } }, // Set the date format
          { header: 'MemberId', key: 'memberId', width: 20, style: { numFmt: '@' } },
          { header: 'IsEmailAllowed', key: 'isEmailAllowed', width: 20 },
          { header: 'IsSmsAllowed', key: 'isSmsAllowed', width: 20 },
          { header: 'IsContactProhibited', key: 'isContactProhibited', width: 20 },
          { header: 'Date of birth', key: 'birthdate', width: 20, style: { numFmt: 'dd.MM.yyyy' } }

        ]

        // Apply bold font to the header row
        const headerRow = worksheet.getRow(1)
        headerRow.font = { bold: true }

        // Add member data to the worksheet
        memberSearchResult.results.forEach(function (member: any) {
          worksheet.addRow({ firstName: member.firstName,
            lastName: member.lastName,
            email: member.email,
            address1: member.address?.address1,
            address2: member.address?.address2,
            city: member.address?.city,
            postalCode: member.address?.postalCode,
            country: member.address?.country,
            paymentdate: member.paymentDate,
            memberId: member.sprId,
            isEmailAllowed: member.isEmailAllowed ? 'Yes' : 'No',
            isSmsAllowed: member.isSmsAllowed ? 'Yes' : 'No',
            isContactProhibited: member.isContactProhibited ? 'Yes' : 'No',
            birthdate: member.birthDate }
          )
        })

        // Set filters on the header row
        headerRow.eachCell(function (cell) {
          cell.alignment = { vertical: 'middle', horizontal: 'center' }
        })
        worksheet.autoFilter = 'A1:N1' // Apply filters to all columns

        // Generate the Excel file as a binary buffer
        return workbook.xlsx.writeBuffer()
      })
        .then(function (buffer) {
          if (buffer === null) return
          // Create a Blob object with the Excel file buffer
          const link = document.createElement('a')
          link.href = URL.createObjectURL(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
          link.download = 'member_list.xlsx'

          // Append the link to the document body or any other parent element
          document.body.appendChild(link)

          // Trigger the click event to start the download
          link.click()
          link.remove()
        }).catch(err => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          // eslint-disable-next-line no-console
          console.log(err)
          this.$store.dispatch('alert/error', { message: this.$t('members.findfailed') })
        })
    }
  }
})
