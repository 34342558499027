import { Module } from 'vuex'
import { Alert, AlertOptions } from '@/types/custom.ts'

export interface AlertState {
  alerts: Array<Alert>,
  dataLoading: boolean
}

export const state: AlertState = {
  alerts: [],
  dataLoading: false
}
export const alert: Module<AlertState, any> = {
  namespaced: true,
  state: state,
  actions: {
    // Actions are used to commit mutations and can be asynchromous.
    // Actions are triggered from vue components using store.dispatch({name})
    // eg store.dispatch('success')
    // examples are
    // this.$store.dispatch('alert/error', { message: 'Login failed' })
    // this.$store.dispatch('alert/success', { message: 'Login failed', timeout: AlertTimeout.PERMANENT }) //never goes away. Can also pass -1
    // this.$store.dispatch('alert/success', { message: 'Login failed', timeout: 3000 }) //stays for 3 seconds
    // this.$store.dispatch('alert/success', { message: 'Login failed', surviveRouteChange: true }) //stays on the page even after $router.push()

    success ({ commit }, options: AlertOptions) {
      commit('success', options)
    },

    error ({ commit }, options: AlertOptions) {
      commit('error', options)
    },

    warning ({ commit }, options: AlertOptions) {
      commit('warning', options)
    },

    info ({ commit }, options: AlertOptions) {
      commit('info', options)
    },

    remove ({ commit }, id: number) {
      commit('remove', id)
    },

    clear ({ commit }, excludeRouteChangeAlerts: boolean) {
      commit('clear', excludeRouteChangeAlerts)
    }
  },
  mutations: {
    // Mutations change the state of the store. They are synchronous.

    success (state, options: any) {
      state.alerts.push(new Alert(new Date().valueOf(), options.message, 'alert-success', options.surviveRouteChange, options.timeout, options.isHtml))
    },

    error (state, options: any) {
      state.alerts.push(new Alert(new Date().valueOf(), options.message, 'alert-danger', options.surviveRouteChange, options.timeout, options.isHtml))
    },

    warning (state, options: any) {
      state.alerts.push(new Alert(new Date().valueOf(), options.message, 'alert-warning', options.surviveRouteChange, options.timeout, options.isHtml))
    },

    info (state, options: any) {
      state.alerts.push(new Alert(new Date().valueOf(), options.message, 'alert-info', options.surviveRouteChange, options.timeout, options.isHtml))
    },

    remove (state, id: number) {
      if (state.alerts.length) {
        state.alerts.splice(state.alerts.findIndex(x => x.id === id), 1)
      }
    },

    clear (state, excludeRouteChangeAlerts: boolean) {
      if (excludeRouteChangeAlerts) {
        state.alerts = state.alerts.filter(x => x.surviveRouteChange === true)
      } else {
        state.alerts = []
      }
    },

    dataLoading (state) {
      state.dataLoading = true
    },

    dataLoaded (state) {
      state.dataLoading = false
    }
  }
}
