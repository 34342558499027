
import Vue from 'vue'
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue' // @ is an alias to /src
import Alert from '@/components/Alert.vue'
import Loading from '@/components/Loading.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Header,
    Footer,
    Alert,
    Loading
  },
  created () {
    // suggested language handling
    // get language cookie
    // if its not finnish, dispatch config/changelanguage
    // that sets store language
    // plus calls i18n.locale = 'se'
  },
  computed: {
    isDataLoading (): boolean {
      return this.$store.state.alert.dataLoading
    }
  }
})
