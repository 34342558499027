// import config from 'config'
import store from '../store'
import authHeader from '../helpers/auth/auth-header'
import { handleErrors, processJson } from '../helpers/httphelper'
import { MeetingStatusCode, MeetingTypeCode, MembershipStatusCode, MembershipTypeCode, MemberPositionStatusCode, MemberStatusCode, MinutesStatusCode, SectionStatusCode, DistrictStatusCode } from '@/helpers/codehelper'

const requestOptions: RequestInit = {
  method: 'GET',
  headers: authHeader(),
  mode: 'cors'
}

function getMeetingStatuses (): Promise<void | Array<MeetingStatusCode>> {
  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.codes.getMeetingStatusesUrl}`

  return fetch(url, requestOptions)
    .then((response) => handleErrors(response))
    .then((response) => processJson(response))
    .then(
      (result: Array<MeetingStatusCode>) => {
        return (result as Array<MeetingStatusCode>).map(x => new MeetingStatusCode(x.id, x.enumName))
      }
    )
    .catch(err => Promise.reject(err))
}

function getMeetingTypes (): Promise<Array<MeetingTypeCode>> {
  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.codes.getMeetingTypesUrl}`

  return fetch(url, requestOptions)
    .then((response) => handleErrors(response))
    .then((response) => processJson(response))
    .then(
      (result: Array<MeetingTypeCode>) => {
        return (result as Array<MeetingTypeCode>).map(x => new MeetingTypeCode(x.id, x.enumName))
      }
    )
    .catch(err => Promise.reject(err))
}

function getMembershipStatuses (): Promise<Array<MembershipStatusCode>> {
  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.codes.getMembershipStatusesUrl}`

  return fetch(url, requestOptions)
    .then((response) => handleErrors(response))
    .then((response) => processJson(response))
    .then(
      (result: Array<MembershipStatusCode>) => {
        return (result as Array<MembershipStatusCode>).map(x => new MembershipStatusCode(x.id, x.enumName))
      }
    )
    .catch(err => Promise.reject(err))
}

function getMembershipTypes (): Promise<Array<MembershipTypeCode>> {
  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.codes.getMembershipTypesUrl}`

  return fetch(url, requestOptions)
    .then((response) => handleErrors(response))
    .then((response) => processJson(response))
    .then(
      (result: Array<MembershipTypeCode>) => {
        return (result as Array<MembershipTypeCode>).map(x => new MembershipTypeCode(x.id, x.enumName))
      }
    )
    .catch(err => Promise.reject(err))
}

function getMembershipPositionStatuses (): Promise<Array<MemberPositionStatusCode>> {
  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.codes.getMembershipPositionStatuesesUrl}`

  return fetch(url, requestOptions)
    .then((response) => handleErrors(response))
    .then((response) => processJson(response))
    .then(
      (result: Array<MemberPositionStatusCode>) => {
        return (result as Array<MemberPositionStatusCode>).map(x => new MemberPositionStatusCode(x.id, x.enumName))
      }
    )
    .catch(err => Promise.reject(err))
}

function getMemberStatuses (): Promise<Array<MemberStatusCode>> {
  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.codes.getMemberStatusesUrl}`

  return fetch(url, requestOptions)
    .then((response) => handleErrors(response))
    .then((response) => processJson(response))
    .then(
      (result: Array<MemberStatusCode>) => {
        return (result as Array<MemberStatusCode>).map(x => new MemberStatusCode(x.id, x.enumName))
      }
    )
    .catch(err => Promise.reject(err))
}

function getMinutesStatuses (): Promise<Array<MinutesStatusCode>> {
  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.codes.getMinutesStatusesUrl}`

  return fetch(url, requestOptions)
    .then((response) => handleErrors(response))
    .then((response) => processJson(response))
    .then(
      (result: Array<MinutesStatusCode>) => {
        return (result as Array<MinutesStatusCode>).map(x => new MinutesStatusCode(x.id, x.enumName))
      }
    )
    .catch(err => Promise.reject(err))
}

function getSectionStatuses (): Promise<Array<SectionStatusCode>> {
  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.codes.getSectionStatusesUrl}`

  return fetch(url, requestOptions)
    .then((response) => handleErrors(response))
    .then((response) => processJson(response))
    .then(
      (result: Array<SectionStatusCode>) => {
        return (result as Array<SectionStatusCode>).map(x => new SectionStatusCode(x.id, x.enumName))
      }
    )
    .catch(err => Promise.reject(err))
}

function getDistrictStatuses (): Promise<Array<DistrictStatusCode>> {
  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.codes.getDistrictStatusesUrl}`

  return fetch(url, requestOptions)
    .then((response) => handleErrors(response))
    .then((response) => processJson(response))
    .then(
      (result: Array<DistrictStatusCode>) => {
        return (result as Array<DistrictStatusCode>).map(x => new DistrictStatusCode(x.id, x.enumName))
      }
    )
    .catch(err => Promise.reject(err))
}

function getAllCodes (this: any): Promise<Array<any>> {
  requestOptions.headers = authHeader()

  const promises = [
    getMeetingStatuses(),
    getMeetingTypes(),
    getMembershipStatuses(),
    getMembershipTypes(),
    getMembershipPositionStatuses(),
    getMemberStatuses(),
    getMinutesStatuses(),
    getSectionStatuses(),
    getDistrictStatuses()
  ]

  return Promise.all(promises)
}

export const codeService = {
  getAllCodes
}
