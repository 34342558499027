
import Vue from 'vue'

export default Vue.extend({
  name: 'LinkIconBox',
  props: {
    // Note the icons you use should be loaded in main.ts as all icons are not in by default to save loading time.
    // this requires the string name, eg list-alt
    // but you should load in main by the name of the icon in node_modules, eg faListAlt
    fontAwesomeIcon: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconLink (): string {
      if (this.fontAwesomeIcon.length) {
        return this.fontAwesomeIcon
      } else {
        return ''
      }
    }
  }
})
