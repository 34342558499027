
import Vue from 'vue'
import Back from '@/components/Back.vue'
import OfficialLetterRequestList from '@/components/sections/OfficialLetterRequests.vue'
import SprButton from '@/components/forms/SprButton.vue'
import Loading from '@/components/Loading.vue'
import { OfficialLetterRequestResponse, SignedDocumentResponse } from '@/types'
import { sectionService } from '@/services/section.service.ts'

export default Vue.extend({
  name: 'OfficialLetterRequests',
  components: {
    Back,
    OfficialLetterRequestList,
    Loading
  },
  methods: {
    getOfficialLetterRequests: function () {
      this.dataLoading = true
      sectionService.getOfficialLetterRequests().then(result => {
        this.officialLetterRequests = result
        this.dataLoading = false
      }).catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.dataLoading = false
        this.$store.dispatch('alert/error', { message: this.$t('report.failed') })
      })
    },
    onDeleteOfficialLetterRequest: function (request: OfficialLetterRequestResponse) {
      this.dataLoading = true
      sectionService.deleteLetterRequest(request.id)
        .then((success: boolean) => {
          if (success) {
            this.$store.dispatch('alert/success', { message: this.$t('officialletterrequest.deletesuccess') })
            this.getOfficialLetterRequests()
          } else {
            this.$store.dispatch('alert/error', { message: this.$t('officialletterrequest.deletefailed') })
          }
          this.dataLoading = false
        })
    }
  },
  data () {
    return {
      dataLoading: false,
      officialLetterRequests: [] as Array<OfficialLetterRequestResponse>
    }
  },
  created () {
    this.getOfficialLetterRequests()
  }
})
