
import Vue from 'vue'
import Back from '@/components/Back.vue'
import { TranslateResult } from 'vue-i18n'
import { SectionSummaryResponse, MemberSummaryResponse, MeetingSummaryResponse, GetMeetingsRequest, DistrictSummaryResponse } from '@/types'
import { MeetingFilter } from '@/types/custom.ts'
import { CodeCategory, MeetingStatus } from '@/types/enums'
import MeetingsFilter from '@/components/meetings/MeetingsFilter.vue'
import MeetingsList from '@/components/meetings/MeetingsList.vue'
import { membersService } from '@/services'

export default Vue.extend({
  name: 'DistrictMeetings',
  components: {
    Back,
    MeetingsFilter,
    MeetingsList
  },
  props: {
    districtId: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      meetingsFilter: {
        sectionId: 0,
        typeId: 0,
        statusIds: [MeetingStatus.DRAFT, MeetingStatus.SUBMITTED, MeetingStatus.REVIEWED, MeetingStatus.PENDINGAPPROVAL, MeetingStatus.APPROVED, MeetingStatus.ARCHIVED],
        meetingDate: new Date()
      } as MeetingFilter
    }
  },
  computed: {
    districtIdNumber () {
      return Number(this.districtId)
    },
    sections (this: any): Array<SectionSummaryResponse> {
      if (this.districtIdNumber > 0) {
        return this.$store.state.sections.all.filter((x: SectionSummaryResponse) => x.districtId === this.districtIdNumber)
      }
      return this.$store.state.sections.all
    },
    canViewMembers () {
      const claims = this.$store.state.authentication.claims
      return claims.isInRoleSuperAdmin ||
      claims.isInRoleKT ||
      claims.isInRoleDistrictAdmin ||
      claims.isInRoleSectionAdmin
    },
    districtName (): string {
      if (this.$store.state.districts.districtsLoaded) {
        const district = this.$store.state.districts.all.find((x: DistrictSummaryResponse) => x.id === Number(this.districtIdNumber))
        return district ? district.name : ''
      }
      return ''
    },
    meetings (): Array<MeetingSummaryResponse> {
      return this.$store.state.meetings.meetings
    },
    filter (this: any): GetMeetingsRequest {
      return {
        districts: [this.districtIdNumber],
        sections: this.meetingsFilter.sectionId === 0 ? null : [this.meetingsFilter.sectionId],
        types: this.meetingsFilter.typeId === 0 ? null : [this.meetingsFilter.typeId],
        statuses: this.meetingsFilter.statusIds.length === 0 ? null : this.meetingsFilter.statusIds,
        dateHeldFrom: this.meetingsFilter.meetingDate || null
      } as GetMeetingsRequest
    }
  },
  created (this: any) {
    if (this.$route.query) {
      if (this.$route.query.statuses) {
        this.meetingsFilter.statusIds = this.$route.query.statuses.split(',').map((x: string) => Number(x))
      }
    }

    const today = new Date()
    this.meetingsFilter.meetingDate = new Date(today.getUTCFullYear() - 1, today.getUTCMonth(), today.getUTCDate())
    this.loadMeetingsIntoStore()
  },
  methods: {
    loadMeetingsIntoStore (this: any) {
      this.$store.commit('alert/dataLoading', null, { root: true })
      this.$store.dispatch('meetings/getMeetings', this.filter).then((success: any) => {
        this.$store.commit('alert/dataLoaded', null, { root: true })
      },
      (fail: any) => {
        /* eslint-disable no-console */
        console.log('failed to load meetings', fail)
        if (fail.status && fail.status === 403) {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          this.$router.push({ path: '/404' })
        }
      })
    },
    onFilterChanged (this: any, filter: MeetingFilter) {
      this.meetingsFilter.sectionId = filter.sectionId
      this.meetingsFilter.typeId = filter.typeId
      this.meetingsFilter.statusIds = filter.statusIds
      this.meetingsFilter.meetingDate = filter.meetingDate
      this.loadMeetingsIntoStore()
    },
    onSectionClicked (section: SectionSummaryResponse): void {
      this.$router.push(`/sections/${section.id}`)
    },
    onMembersClicked (section: SectionSummaryResponse): void {
      this.$router.push(`/sections/${section.id}/members`)
    },
    onMeetingsClicked (section: SectionSummaryResponse): void {
      this.$router.push(`/sections/${section.id}/meetings`)
    },
    onAddMeetingClicked (section: SectionSummaryResponse): void {
      this.$router.push(`/sections/${section.id}/meetings/add`)
    },
    getStatus (statusId: number): TranslateResult {
      return this.$t(`sectionstatus-${statusId}`)
    }
  }
})
