
import Vue from 'vue'
import SprFormText from '@/components/forms/SprFormText.vue'
import { isAddressEmpty } from '@/helpers/addresshelper'
import { AddressResponse } from '@/types'

export default Vue.extend({
  name: 'AddressEditor',
  components: { SprFormText },
  props: {
    value: {
      type: Object as () => AddressResponse,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  methods: {
    address1Changed (newVal: string): void {
      this.localVal.address1 = newVal
      this.addressChanged()
    },
    address2Changed (newVal: string): void {
      this.localVal.address2 = newVal
      this.addressChanged()
    },
    postalCodeChanged (newVal: string): void {
      this.localVal.postalCode = newVal
      this.addressChanged()
    },
    cityChanged (newVal: string): void {
      this.localVal.city = newVal
      this.addressChanged()
    },
    countryChanged (newVal: string): void {
      this.localVal.country = newVal
      this.addressChanged()
    },
    addressChanged () {
      // @ts-ignore-next-line
      this.$emit('input', this.localVal)
    }
  },
  computed: {
    localVal () {
      return this.value || {
        address1: '',
        address2: '',
        postalCode: '',
        city: '',
        country: ''
      } as AddressResponse
    }
  }
})
