
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import SprButton from '@/components/forms/SprButton.vue'
import { SignedDocumentResponse, OfficialLetterRequestResponse, OfficialLetterRequestStatusCode } from '@/types'
import { finnishShortDate } from '@/helpers/datehelper.ts'
import { OfficialLetterRequestStatus } from '@/types/enums'

export default Vue.extend({
  name: 'OfficialLetterRequests',
  components: { SprButton },
  props: {
    requests: {
      type: Array as () => Array<OfficialLetterRequestResponse>,
      required: true
    }
  },
  methods: {
    formatDate (date: Date) {
      return finnishShortDate(date)
    },
    formatStatus (status: OfficialLetterRequestStatusCode): TranslateResult {
      return this.$t(`officialletterrequeststatus-${status.id}`)
    },
    isStatusRequested (status: OfficialLetterRequestStatusCode): boolean {
      return status.id === OfficialLetterRequestStatus.REQUESTED
    },
    downloadDocument (request: OfficialLetterRequestResponse) {
      window.open(request.document.url)
    },
    gotoDocument (request: OfficialLetterRequestResponse) {
      this.$router.push(`/officialletterrequests/${request.id}`)
    },
    canDelete (x: OfficialLetterRequestResponse) {
      const userId = this.$store.state.authentication.claims?.ossiId
      if (!userId) {
        return false
      }
      return this.isKTOrAbove ||
             (x.statusId === OfficialLetterRequestStatus.REQUESTED && x.createdByMemberId === userId)
    },
    deleteDocument (request: OfficialLetterRequestResponse) {
      const txt = this.$t('officialletterrequest.deleteconfirm').toString()
      if (confirm(txt)) {
        this.$emit('onDelete', request)
      }
    },
    isExpired (request: OfficialLetterRequestResponse): boolean {
      return request.expiryDate ? new Date(request.expiryDate) < new Date() : false
    }
  },
  computed: {
    sortedRequests () {
      if (!this.requests) return
      return this.requests
        .filter((x: OfficialLetterRequestResponse) => {
          return x.statusId === OfficialLetterRequestStatus.REQUESTED || x.statusId === OfficialLetterRequestStatus.PROCESSED
        })
        .sort((x: OfficialLetterRequestResponse, y: OfficialLetterRequestResponse) => {
          return y.created.getTime() - x.created.getTime()
        })
    },
    isKTOrAbove () {
      return (
        this.$store.state.authentication.claims.isInRoleSuperAdmin ||
        this.$store.state.authentication.claims.isInRoleKT)
    }
  }
})
