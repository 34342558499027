// import config from 'config'
import store from '../store'
import authHeader from '../helpers/auth/auth-header'
import { handleErrors, processJson } from '../helpers/httphelper'
import { GetDistrictMembersExportRequest, GetSectionMembersExportRequest, FindMemberRequest, BaseFindMemberRequest, PagedListResponse, MemberSummaryResponse, MemberFlatResponse, MemberLightResponse, UpdateRoleRequest, RequestValidationResult } from '@/types'

function getAllSectionMembers (this: any, filter: FindMemberRequest): Promise<PagedListResponse<MemberSummaryResponse>| Error> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(filter)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.members.getAllSectionMembersUrl}`
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(processJson)
      .then((pagedresult) => {
        resolve(pagedresult as PagedListResponse<MemberSummaryResponse>)
      }).catch((error: any) => {
        reject(error)
      })
  })
}
function getAllDistrictMembers (this: any, filter: FindMemberRequest): Promise<PagedListResponse<MemberSummaryResponse> | Error> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(filter)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.members.getAllDistrictMembersUrl}`
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(processJson)
      .then((pagedresult) => {
        resolve(pagedresult as PagedListResponse<MemberSummaryResponse>)
      }).catch((error: any) => {
        reject(error)
      })
  })
}

function findDistrictMembers (this: any, filter: FindMemberRequest): Promise<PagedListResponse<MemberSummaryResponse> | Error> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(filter)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.members.findDistrictMembersUrl}`
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(processJson)
      .then((pagedresult) => {
        resolve(pagedresult as PagedListResponse<MemberSummaryResponse>)
      }).catch((error: any) => {
        reject(error)
      })
  })
}

function findSectionMembers (this: any, filter: FindMemberRequest): Promise<PagedListResponse<MemberSummaryResponse> | Error> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(filter)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.members.findSectionMembersUrl}`
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(processJson)
      .then((pagedresult) => {
        resolve(pagedresult as PagedListResponse<MemberSummaryResponse>)
      }).catch((error: any) => {
        reject(error)
      })
  })
}

function findAll (this: any, filter: BaseFindMemberRequest, signal?: AbortSignal): Promise<PagedListResponse<MemberLightResponse>> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(filter),
    signal: signal
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.members.findAllUrl}`
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(processJson)
      .then((pagedresult) => {
        resolve(pagedresult as PagedListResponse<MemberLightResponse>)
      }).catch((error: any) => {
        reject(error)
      })
  })
}

function findBySectionId (this: any, id: number): Promise<Array<MemberLightResponse>> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.members.findBySectionIdUrl}`.replace('{id}', id.toString())
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(processJson)
      .then((members) => {
        resolve(members as Array<MemberLightResponse>)
      }).catch((error: any) => {
        reject(error)
      })
  })
}

function getById (this: any, id: number): Promise<MemberFlatResponse> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.members.getByIdUrl}`.replace('{id}', id.toString())
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(processJson)
      .then((member) => {
        resolve(member as MemberFlatResponse)
      }, (fail) => {
        reject(Error('failed to get member'))
      }).catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log('Fetch failed', error)
        reject(Error(`Unable to get member - ${error}`))
      })
  })
}

function getDistrictMembersExportFile (this: any, filter: GetDistrictMembersExportRequest): Promise<Blob> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(filter)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.members.getDistrictMembersExportFileUrl}`
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(
        (response: Response) => {
          return response.arrayBuffer()
        }
      )
      .then((buffer: ArrayBuffer) => {
        resolve(new Blob([buffer], { type: 'text/csv' }))
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log('Fetch failed', error)
        reject(Error(`Unable to get member export - ${error}`))
      })
  }
  )
}

function getSectionMembersExportFile (this: any, filter: GetSectionMembersExportRequest): Promise<Blob> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(filter)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.members.getSectionMembersExportFileUrl}`
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(
        (response: Response) => {
          return response.arrayBuffer()
        }
      )
      .then((buffer: ArrayBuffer) => {
        resolve(new Blob([buffer], { type: 'text/csv' }))
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log('Fetch failed', error)
        reject(Error(`Unable to get member export - ${error}`))
      })
  }
  )
}

function updateRoles (memberId: number, roles: Array<number>) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      memberId: memberId,
      roles: roles
    } as UpdateRoleRequest)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.members.updateRolesUrl}`
  return new Promise<void>((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(processJson)
      .then(() => {
        resolve()
      }, (fail) => {
        if (fail) {
          reject(fail)
        }
        reject(Error('Unable to update roles'))
      }).catch((error: any) => {
        reject(Error(`Unable to update roles - ${error}`))
      })
  })
}

export const membersService = {
  getAllSectionMembers,
  getAllDistrictMembers,
  findDistrictMembers,
  findSectionMembers,
  findAll,
  findBySectionId,
  getById,
  getDistrictMembersExportFile,
  getSectionMembersExportFile,
  updateRoles
}
