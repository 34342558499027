
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'Switchlanguage',
  computed: {
    ...mapState({
      language: (state: any) => state.config.language
    }),
    fiSelected (): boolean {
      return this.$store.state.config.language === 'fi'
    },
    svSelected (): boolean {
      return this.$store.state.config.language === 'sv'
    }
  },
  methods: {
    changeLanguage (lang: string): void {
      this.$store.commit('config/setLanguage', lang)
      localStorage.setItem('preferredLanguage', lang)
    }
  }
})
