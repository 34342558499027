import { meetingService } from '../services'
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store'
import { AddOrUpdateMeetingRequest, MeetingDetailResponse, MinutesFileRequest, MinutesDocumentResponse, GetMeetingsRequest, AddMemberPositionRequest, UpdateMemberPositionRequest, MemberPositionResponse, MeetingMinutesRequest, Code, PositionResponse, MinutesResponse, MeetingSummaryResponse } from '@/types'
import { MeetingType, PositionStatus } from '@/types/enums'
import { MeetingDetailsData } from '@/types/custom'

export interface MeetingState {
  meetingsLoaded: boolean,
  selectedMeeting: MeetingDetailResponse | undefined,
  allUrl: string,
  isFileUploading: boolean,
  meetings: Array<MeetingSummaryResponse>,
  positionsOfTrust: Array<PositionResponse>,
  meetingsUrl: string,
  addMeetingUrl: string,
  getMeetingUrl: string,
  deleteMeetingUrl: string,
  getMeetingsUrl: string,
  addPositionOfTrustUrl: string,
  removePositionOfTrustUrl: string,
  updatePositionOfTrustUrl: string,
  canEditMeetingUrl: string,
  getAllPositionsOfTrustUrl: string,
  updateMeetingUrl: string,
  submitMeetingUrl: string,
  reviewedMeetingUrl: string,
  archivedMeetingUrl: string,
  approveMeetingUrl: string,
  addOrUpdateMeetingMinutesUrl: string,
  uploadDocUrl: string,
  removeDocUrl: string,
  getDistrictCrmPositionsErrorsUrl: string,
  checkTermLengthUrl: string,
  checkAddSerialTermLimitsUrl: string,
  checkAddNonMemberSerialTermLimitsUrl: string,
  meetingTypeDefaultYearUrl: string,
  getDefaultEndDateForPositionUrl: string
}

/*
STATE

These are the properties available at this.$store.state.meetings.

Do not ever manipulate them via this.$store.state.meetings. Instead use an Action below
*/
const getDefaultState = (): MeetingState => {
  return {
    meetingsLoaded: false,
    selectedMeeting: undefined,
    allUrl: '/api/meetings/all',
    isFileUploading: false,
    meetings: [],
    positionsOfTrust: [],
    meetingsUrl: '/api/sections/meetings',
    meetingTypeDefaultYearUrl: '/api/Meetings/getmeetingtypedefaultyear/{meetingType}',
    getDefaultEndDateForPositionUrl: '/api/Meetings/getdefaultenddateforposition/{meetingType}/{dateHeld}/{positionId}',
    addMeetingUrl: '/api/meetings/add',
    getMeetingUrl: '/api/meetings/{meetingid}',
    deleteMeetingUrl: '/api/meetings/deletemeeting',
    getMeetingsUrl: '/api/meetings',
    addPositionOfTrustUrl: '/api/meetings/addpositionoftrust',
    removePositionOfTrustUrl: '/api/meetings/removepositionoftrust/{id}',
    updatePositionOfTrustUrl: '/api/meetings/updatepositionoftrust',
    canEditMeetingUrl: '/api/meetings/caneditmeeting/{id}',
    getAllPositionsOfTrustUrl: '/api/meetings/getallpositionsoftrust',
    updateMeetingUrl: '/api/meetings/update',
    submitMeetingUrl: '/api/meetings/submitmeeting',
    reviewedMeetingUrl: '/api/meetings/reviewedmeeting',
    archivedMeetingUrl: '/api/meetings/archivedmeeting',
    approveMeetingUrl: '/api/meetings/approvemeeting',
    addOrUpdateMeetingMinutesUrl: '/api/meetings/addorupdatemeetingminutes',
    uploadDocUrl: '/api/meetings/uploaddoc',
    removeDocUrl: '/api/meetings/removedoc/{id}',
    getDistrictCrmPositionsErrorsUrl: '/api/meetings/getdistrictcrmpositionserrors/{districtId}',
    checkTermLengthUrl: '/api/meetings/checktermlength',
    checkAddSerialTermLimitsUrl: '/api/meetings/checkaddserialtermlimits',
    checkAddNonMemberSerialTermLimitsUrl: '/api/meetings/checkaddnonmemberserialtermlimits'
  }
}

export const state = getDefaultState()

/*
ACTIONS

Actions are called by front end components to do something to the data. They can be asynchronous operations.
These are essentially events and contain the business logic.
An Action can dispatch multiple mutations. It does not change the state in itself.

These are called by this.$store.dispatch('meetings/<nameofaction>')
*/
const actions: ActionTree<MeetingState, RootState> = {

  // Create the initial meeting
  saveMeeting (context, initialMeeting: AddOrUpdateMeetingRequest) {
    if (!initialMeeting) return Promise.reject(Error('No meeting selected'))
    return meetingService.addMeeting(initialMeeting as AddOrUpdateMeetingRequest)
      .then(
        (result) => {
          return new Promise((resolve, reject) => {
            if (result && (result as MeetingDetailResponse).id) {
              context.commit('deleteSelectedMeeting')
              context.commit('saveMeeting', result)
              resolve(result.id)
            } else {
              reject(result)
            }
          })
        }
      ).catch(err => Promise.reject(err))
  },

  deleteMeeting (context, meetingId) {
    if (!context.state.selectedMeeting) return Promise.reject(Error('No meeting Id supplied'))
    return meetingService.deleteMeeting(meetingId)
      .then(() => {
        context.commit('deleteSelectedMeeting')
        return Promise.resolve()
      }, (err: Error) => {
        return Promise.reject(err)
      }).catch(err => Promise.reject(err))
  },

  updateMeeting (context, meeting: MeetingDetailResponse) {
    return meetingService.updateMeeting(meeting)
  },

  submitMeeting (context, meetingId: number) {
    return meetingService.submitMeeting(meetingId)
  },

  reviewedMeeting (context, meetingId: number) {
    return meetingService.reviewedMeeting(meetingId)
  },
  archivedMeeting (context, meetingId: number) {
    return meetingService.archivedMeeting(meetingId)
  },
  approveMeeting (context, meetingId: number) {
    return meetingService.approveMeeting(meetingId)
  },

  updateMeetingMinutesSentByMail (context, val: boolean) {
    const minutes = context.state?.selectedMeeting?.minutes

    if (!minutes) return

    const data = {
      id: minutes.id || null,
      meetingId: minutes.meetingId,
      status: minutes.status,
      isSentByMail: val
    } as MeetingMinutesRequest

    return meetingService.addOrUpdateMeetingMinutes(data).then((minutes: MinutesResponse) => {
      context.commit('addOrUpdateMeetingMinutes', minutes)
      return Promise.resolve(true)
    }).catch(err => Promise.reject(err))
  },

  addOrUpdateMeetingMinutes (context, minutes: MeetingMinutesRequest) {
    return meetingService.addOrUpdateMeetingMinutes(minutes).then((minutes: MinutesResponse) => {
      context.commit('addOrUpdateMeetingMinutes', minutes)
      return Promise.resolve(minutes)
    }).catch(err => Promise.reject(err))
  },

  getMeeting (context, id) {
    return meetingService.getMeeting(id)
      .then(
        meeting => {
          context.commit('saveMeeting', meeting)
          return Promise.resolve(meeting)
        },
        fail => {
          // eslint-disable-next-line no-console
          console.log('failed', fail)
          return Promise.reject(fail)
        }
      ).catch(err => {
        // eslint-disable-next-line no-console
        console.log('fail', err)
        return Promise.reject(err)
      })
  },

  deleteSelectedMeeting ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('deleteSelectedMeeting')
      resolve(true)
    })
  },

  uploadDoc (context, doc: MinutesFileRequest) {
    context.commit('uploadingDoc')
    return new Promise((resolve, reject) => {
      if (!doc) {
        context.commit('uploadDocFailure', 'no doc supplied')
        reject(new Error('failed to upload doc'))
      }

      if (!context.state.selectedMeeting?.minutes?.documents) {
        context.commit('uploadDocFailure', 'no documents to add')
        reject(new Error('failed to upload doc'))
      }

      meetingService.uploadDoc(doc)
        .then(
          docWithUrl => {
            context.commit('uploadDoc', docWithUrl)
            resolve(docWithUrl)
          }
        ).catch(err => {
          context.commit('uploadDocFailure', err)
          reject(err)
        })
    })
  },

  removeDoc (context, docId: number): Promise<boolean | Error> {
    //first remove from blob storage, then from here
    return meetingService.removeDoc(docId)
      .then(
        (success) => {
          context.commit('removeDoc', docId)
          return Promise.resolve(success)
        }
      ).catch((err: Error) => Promise.reject(err))
  },

  getMeetings ({ commit }, filter: GetMeetingsRequest) {
    commit('getMeetingsRequest')

    return new Promise((resolve, reject) => {
      meetingService.getMeetings(filter)
        .then(
          meetings => {
            commit('getMeetingsSuccess', meetings)
            resolve(meetings)
          },
          error => {
            commit('getMeetingsFailure', error)
            // eslint-disable-next-line no-console
            console.log('Error getting meetings', error)
            reject(error)
          }
        )
    })
  },

  addPositionOfTrust ({ commit }, memberPosition: AddMemberPositionRequest): Promise<MemberPositionResponse | Error> {
    return meetingService.addPositionOfTrust(memberPosition)
      .then(
        position => {
          commit('addMemberPosition', position)
          return Promise.resolve(position)
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log('Error saving position of trust', error)
          return Promise.reject(error)
        }
      ).catch(err => Promise.reject(err))
  },

  updatePositionOfTrust ({ commit }, memberPosition: UpdateMemberPositionRequest): Promise<MemberPositionResponse | Error> {
    return meetingService.updatePositionOfTrust(memberPosition)
      .then(
        position => {
          commit('updateMemberPosition', position)
          return Promise.resolve(position)
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log('Error updating position of trust', error)
          return Promise.reject(error)
        }
      ).catch(err => Promise.reject(err))
  },

  removePositionOfTrust ({ commit }, id: number): Promise<boolean | Error> {
    return meetingService.removePositionOfTrust(id)
      .then(
        ok => {
          commit('removeMemberPosition', id)
          return Promise.resolve(ok)
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log('Error removing position of trust', error)
          return Promise.reject(Error('Failed to remove position'))
        }
      ).catch(err => Promise.reject(err))
  },

  getAllPositionsOfTrust ({ commit }) {
    return meetingService.getAllPositionsOfTrust()
      .then(
        pot => {
          commit('savePositionsOfTrust', pot)
          return Promise.resolve(pot)
        }
      ).catch(err => Promise.reject(err))
  }
}

/*
MUTATIONS

Mutations modify the store data. They are synchronous operations. This is the only way to modify the state.

These are called by this.$store.commit('meetings/<nameofmutation>')
*/
const mutations: MutationTree<MeetingState> = {

  createMeeting (state, meeting) {
    state.selectedMeeting = meeting
    state.isFileUploading = false
  },

  updateMeetingDetails (state, details: MeetingDetailsData) {
    if (!state.selectedMeeting) return
    state.selectedMeeting.type.id = details.meetingType && !isNaN(details.meetingType) ? details.meetingType : MeetingType.UNKNOWN
    state.selectedMeeting.dateHeld = details.dateHeld
    state.selectedMeeting.name = details.name
    state.selectedMeeting.moreInfo = details.moreInfo
  },

  saveMeeting (state, meeting) {
    state.selectedMeeting = meeting
  },

  deleteSelectedMeeting (state) {
    state.selectedMeeting = undefined
  },

  uploadingDoc (state) {
    state.isFileUploading = true
  },

  uploadDoc (state, doc) {
    if (state.selectedMeeting) {
      state.selectedMeeting.minutes.documents.push(doc)
      state.isFileUploading = false
    }
  },

  uploadDocFailure (state, doc) {
    state.isFileUploading = false
  },

  removeDoc (state, id) {
    if (!state.selectedMeeting || !state.selectedMeeting.minutes.documents) return
    const docs = (state.selectedMeeting.minutes.documents as Array<MinutesDocumentResponse>)
    const clonedDocs = [...docs]
    const dIndex = clonedDocs.findIndex(x => x.id === id)
    if (dIndex > -1) {
      clonedDocs.splice(dIndex, 1)
    }
    // Vue sometimes doesnt pick up on array changes, so replace it with a new one.
    state.selectedMeeting.minutes.documents = clonedDocs
    state.isFileUploading = false
  },

  addOrUpdateMeetingMinutes (state, minutes) {
    if (!state.selectedMeeting || state.selectedMeeting.minutes) return
    state.selectedMeeting.minutes = minutes
    state.selectedMeeting.minutesId = minutes.id
  },

  getMeetingsRequest (state) {
    state.meetings = []
  },

  getMeetingsSuccess (state, meetings) {
    state.meetings = meetings
  },

  getMeetingsFailure (state, error) {
    state.meetings = []
  },

  addMemberPosition (state, memberPosition) {
    if (state.selectedMeeting) {
      state.selectedMeeting.memberPositions.push(memberPosition)
    }
  },

  removeMemberPosition (state, id: number) {
    if (state.selectedMeeting) {
      const index = state.selectedMeeting.memberPositions.findIndex((x: MemberPositionResponse) => {
        return x.id === id
      })
      if (index > -1) {
        state.selectedMeeting.memberPositions.splice(index, 1)
      }
    }
  },

  updateMemberPosition (state, memberPosition: MemberPositionResponse) {
    if (state.selectedMeeting && state.selectedMeeting.memberPositions) {
      const index = state.selectedMeeting.memberPositions.findIndex((x: MemberPositionResponse) => x.id === memberPosition.id)
      const clone = [...state.selectedMeeting.memberPositions]
      clone[index] = memberPosition
      state.selectedMeeting.memberPositions = clone
    }
  },

  savePositionsOfTrust (state, pot: Array<PositionResponse>) {
    state.positionsOfTrust = pot
  },

  reset (state) {
    Object.assign(state, getDefaultState())
  }
}

const getters: GetterTree<MeetingState, RootState> = {
  getActivePositionsOfTrust: (state: MeetingState) => (id: number) => {
    return state.positionsOfTrust.filter((x: PositionResponse) => x.status.id === PositionStatus.ACTIVE)
  }
}

export const meetings: Module<MeetingState, RootState> = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
}
