import { JsonDateParser } from './datehelper'
import { isRequestValidationResult } from '@/types/custom.ts'

//fetch api doesn't throw an error on non 200s. Force it to.
export function handleErrors (response: Response): Promise<Response> {
  return new Promise((resolve, reject) => {
    if (!response) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({ status: 500, statusText: 'No response returned', body: '' })
    }

    if (response.ok) {
      resolve(response)
    } else {
      let body: any = {}
      response.clone().text().then(text => {
        if (text) {
          try {
            body = JSON.parse(text)
          } catch {
            body = text
          }
        }

        if (response.status === 401) { window.location.href = '\\login' }
        // should try to always return a RequestValidationResult from errors in the back end
        if (isRequestValidationResult(body)) {
          reject(body)
        }

        // fallback
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ status: response.status, statusText: response.statusText, body: body })
      })
    }
  })
}

export function processJson (response: Response): Promise<any> | Error {
  try {
    return response.text()
      .then(result => {
        if (!result || !result.length) return {}
        return JSON.parse(result, JsonDateParser)
      },
      error => Error(error))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Failed to process json')
    return Error(error)
  }
}

export function debounce (this: any, fn: Function, delay: number): any {
  let debounceInterval: NodeJS.Timeout
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const context = this
  // @ts-ignore
  return function (...args) {
    clearTimeout(debounceInterval)
    debounceInterval = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}
