
import Vue from 'vue'

/// Example
/// <SprButton text="Click Me!" primary compact @click="console.log('hello!')" />
export default Vue.extend({
  name: 'SprButton',
  props: {
    disabled: Boolean,
    text: {
      type: String,
      default: ''
    },
    // whether the button should be shown as a main call to action
    primary: {
      type: Boolean,
      default: false
    },
    // Whether to stick to 100% of bootstrap column width (compact=false) or to be width auto with some padding (compact=true).
    compact: {
      type: Boolean,
      default: false
    },
    minPadding: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    buttonClicked ($evt: any) {
      this.$emit('click', $evt)
    }
  }
})
