// Gets the authorization token from local storage
export default function authHeader (): Headers {
  const token = localStorage.getItem('authToken') || undefined

  if (token) {
    return new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    })
  } else {
    return new Headers({})
  }
}
