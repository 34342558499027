
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import moment from 'moment'
import { MemberFlatResponse, MemberPositionResponse } from '@/types'
import { CodeCategory } from '@/types/enums'
import { finnishShortDate } from '@/helpers/datehelper'

export default Vue.extend({
  name: 'MemberDetails',
  props: {
    value: {
      type: Object as () => MemberFlatResponse,
      required: true
    }
  },
  computed: {
    emailAllowed (): TranslateResult {
      return this.booleanToString(this.value.isEmailAllowed)
    },
    smsAllowed (): TranslateResult {
      return this.booleanToString(this.value.isSmsAllowed)
    },
    contactProhibited (): TranslateResult {
      return this.booleanToString(this.value.isContactProhibited) // Invert the value! The new text is a negative.
    },
    membershipType (): TranslateResult {
      return this.value && this.value.membershipType && this.value.membershipType.id ? this.$t(`membershiptype-${this.value.membershipType.id}`) : ''
    },
    status (): TranslateResult {
      return this.value && this.value.status && this.value.status.id ? this.$t(`memberstatus-${this.value.status.id}`) : ''
    },
    membershipStatus (): TranslateResult {
      return this.value && this.value.membershipStatus && this.value.membershipStatus.id ? this.$t(`membershipstatus-${this.value.membershipStatus.id}`) : ''
    },
    paymentDate (): string {
      return this.value.paymentDate ? finnishShortDate(moment(this.value.paymentDate).toDate()) : '-'
    },
    dateOfBirth (): string {
      return this.value.dateOfBirth ? finnishShortDate(moment(this.value.dateOfBirth).toDate()) : '-'
    },
    membershipApproved (): string {
      return this.value.membershipApproved ? finnishShortDate(moment(this.value.membershipApproved).toDate()) : '-'
    }
  },
  methods: {
    booleanToString (val: boolean): TranslateResult {
      return val ? this.$t('member.allowedyes') : this.$t('member.allowedno')
    }
  }
})
