
import Vue from 'vue'

export default Vue.extend({
  name: 'SprFormCheckbox',
  inject: ['$validator'], //need to inject the parent validator or the field will not be available on the parent if it fails. Also inject into any parent components until you get to the top button
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    validations: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    labelLeft: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    checked (e: Event) {
      if (this.readonly) return
      if (e.target) {
        this.$emit('input', (e.target as HTMLInputElement).checked)
      }
    }
  }
})
