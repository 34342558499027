
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import LinkIconBox from '@/components/LinkIconBox.vue'
import DistrictPositionOfTrustErrors from '@/components/meetings/DistrictPositionOfTrustErrors.vue'
import StatisticTile from '@/components/StatisticTile.vue'
import { DistrictSummaryResponse, DistrictStatisticsResponse } from '@/types'
import { MeetingStatus, AlertTimeout } from '@/types/enums'
import { districtService } from '@/services/district.service'
import SprButton from '@/components/forms/SprButton.vue'

export default Vue.extend({
  name: 'District',
  components: {
    LinkIconBox,
    StatisticTile,
    DistrictPositionOfTrustErrors,
    SprButton
  },
  props: {
    districtId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      stats: {
        created: 0,
        submitted: 0,
        reviewed: 0,
        approved: 0,
        archived: 0,
        totalMembers: 0,
        paidMembers: 0,
        newMembers: 0
      } as DistrictStatisticsResponse
    }
  },
  mounted () {
    this.$store.commit('alert/dataLoading', null, { root: true })
    districtService.getStats(Number(this.districtId)).then((result) => {
      this.stats = result
      this.$store.commit('alert/dataLoaded', null, { root: true })
    })
  },
  methods: {
    usersClicked () {
      if (this.district !== null) {
        // @ts-ignore - object is possibly null. No it frickin isn't.
        this.$router.push(`/districts/${this.district.id}/members`)
      }
    },
    meetingsClicked () {
      if (this.district !== null) {
        // @ts-ignore
        this.$router.push(`/districts/${this.district.id}/meetings`)
      }
    },
    sectionsClicked () {
      if (this.district !== null) {
        // @ts-ignore
        this.$router.push(`/districts/${this.district.id}/sections`)
      }
    },
    actionMeetingsClicked (name: string) {
      this.$router.push(`/districts/${this.district.id}/meetings?statuses=${MeetingStatus.SUBMITTED},${MeetingStatus.REVIEWED}`)
    },
    onReset () {
      if (confirm(this.$t('districts.resetconfirm').toString())) {
        this.$store.commit('alert/dataLoading', null, { root: true })
        districtService.resetDistrict(Number(this.districtId)).then((result) => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          this.$store.dispatch('alert/success', { message: this.$t('districts.resetsuccesful'), timeout: AlertTimeout.SHORT })
        })
      }
    }
  },
  computed: {
    district (): DistrictSummaryResponse {
      if (this.$store.state.districts.districtsLoaded) {
        return this.$store.state.districts.all.find((x: DistrictSummaryResponse) => x.id === Number(this.districtId))
      }
      return {} as DistrictSummaryResponse
    },
    statMeetingsRequiringActionValue (): number {
      return this.stats.submitted + this.stats.reviewed  // Lähetetty or Tarkistettu
    },
    statMeetingsRequiringActionText (): TranslateResult {
      return this.$t('stats.meetingsrequiringaction')
    },
    statDistrictTotalMembersValue (): number {
      return this.stats.totalMembers
    },
    statDistrictTotalMembersText (): TranslateResult {
      return this.$t('stats.districttotalmembers')
    },
    statDistrictPaidMembersValue (): number {
      return this.stats.paidMembers
    },
    statDistrictPaidMembersText (): TranslateResult {
      return this.$t('stats.paidmembers')
    },
    statDistrictNewMembersValue (): number {
      return this.stats.newMembers
    },
    statDistrictNewMembersText (): TranslateResult {
      return this.$t('stats.newmembers')
    },
    canEditDistrict (): boolean {
      const claims = this.$store.state.authentication.claims
      return claims.isInRoleSuperAdmin ||
        claims.isInRoleKT ||
        claims.isInRoleDistrictAdmin
    },
    canResetDistrict (): boolean {
      return (this.$store.state.authentication.claims.isInRoleDistrictAdmin || this.$store.state.authentication.claims.isInRoleSuperAdmin)
    }
  }
})
