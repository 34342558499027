
import Vue from 'vue'
import { Alert } from '@/types/custom.ts'
import { AlertTimeout } from '@/types/enums'

export default Vue.extend({
  name: 'Alert',
  computed: {
    alerts (): Array<Alert> {
      return this.$store.state.alert.alerts
    }
  },
  data () {
    return {
      alertShouldStayUntilCleared: false
    }
  },
  watch: {
    $route (to, from) {
      // clear alert on location change, keep the alerts across route change
      this.$store.dispatch('alert/clear', true)
    },
    alerts (alerts) {
      // get the last one and start a countdown on it to make it disappear after a while
      if (alerts.length && !this.alertShouldStayUntilCleared) {
        const lastAlert = alerts[alerts.length - 1]
        if (lastAlert.timeout && lastAlert.timeout === AlertTimeout.PERMANENT) return

        window.setTimeout((alert: Alert) => {
          this.removeAlert(alert.id)
        }, lastAlert.timeout || AlertTimeout.NORMAL, lastAlert)
      }
    }
  },
  methods: {
    removeAlert (id: number) {
      const interval = setInterval((id: number) => {
        const item = document.getElementById(`alert_${id}`)
        if (item) {
          let opacity = Number(item ? item.style.opacity : 0)
          opacity -= 0.01
          // @ts-ignore because its a string officially...
          item.style.opacity = opacity
          if (opacity < 0) {
            this.$store.dispatch('alert/remove', id)
            clearInterval(interval)
          }
        }
      }, 5, id)
    }
  }
})
