
import Vue from 'vue'
import moment from 'moment'
import Back from '@/components/Back.vue'
import MeetingDetailsForm from '@/components/meetings/MeetingDetailsForm.vue'
import MeetingMinutesForm from '@/components/meetings/MeetingMinutesForm.vue'
import MeetingStatusIndicator from '@/components/meetings/MeetingStatus.vue'
import AddMemberPosition from '@/components/meetings/AddMemberPosition.vue'
import MeetingMemberList from '@/components/meetings/MeetingMemberList.vue'
import MeetingCrmErrors from '@/components/meetings/MeetingCrmErrors.vue'
import ActivePositionsOfTrust from '@/components/meetings/ActivePositionsOfTrust.vue'
import SprButton from '@/components/forms/SprButton.vue'
import { debounce } from '@/helpers/httphelper'
import { formatErrors } from '@/helpers/validationhelper'
import { meetingService } from '@/services/meeting.service'
import { AddMemberPositionRequest, UpdateMemberPositionRequest, MemberPositionResponse, MemberPositionSummaryResponse, ErrorResult, RequestValidationResult } from '@/types'
import { BoardMemberData, isRequestValidationResult, MeetingDetailsData } from '@/types/custom'
import { MeetingStatus, AlertTimeout } from '@/types/enums'

export default Vue.extend({
  name: 'EditMeeting',
  components: {
    Back,
    MeetingStatusIndicator,
    MeetingDetailsForm,
    MeetingMinutesForm,
    AddMemberPosition,
    MeetingMemberList,
    MeetingCrmErrors,
    ActivePositionsOfTrust,
    SprButton
  },
  props: {
    sectionId: {
      type: String,
      required: true
    },
    meetingId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      meetingLoaded: false,
      memberPositions: [] as Array<MemberPositionResponse>,
      debouncedFunction: Function as () => {},
      isMeetingEditable: false,
      sectionActivePositionsOfTrust: [] as Array<MemberPositionSummaryResponse>
    }
  },
  computed: {
    createdDate (): string {
      return moment(this.$store.state.meetings.selectedMeeting.created).format('DD.MM.YYYY HH:mm')
    },
    lastModifiedDate (): string {
      return moment(this.$store.state.meetings.selectedMeeting.modified).format('DD.MM.YYYY HH:mm')
    },
    reportedBy (): string {
      return this.$store.state.meetings.selectedMeeting.reportedBy
    },
    createdBy (): string {
      return this.$store.state.meetings.selectedMeeting.createdBy
    },
    sectionIdNumber (): number {
      // because sectionId from the url is a string
      return Number(this.sectionId)
    },
    meetingIdNumber (): number {
      return Number(this.meetingId)
    },
    meetingDetails (): MeetingDetailsData {
      return {
        meetingType: this.$store.state.meetings.selectedMeeting.type.id,
        dateHeld: this.$store.state.meetings.selectedMeeting.dateHeld,
        name: this.$store.state.meetings.selectedMeeting.name,
        moreInfo: this.$store.state.meetings.selectedMeeting.moreInfo
      } as MeetingDetailsData
    },
    canApprove (): boolean {
      const claims = this.$store.state.authentication.claims

      return (claims.isInRoleSuperAdmin ||
        claims.isInRoleKT ||
        claims.isInRoleDistrictAdmin)
    },
    adminCanArchive (): boolean {
      const claims = this.$store.state.authentication.claims
      return claims.isInRoleSuperAdmin && this.$store.state.meetings.selectedMeeting.status.id === MeetingStatus.PENDINGAPPROVAL
    },
    isReadOnly (): boolean {
      const claims = this.$store.state.authentication.claims

      if (
        this.$store.state.meetings.selectedMeeting.status.id === MeetingStatus.APPROVED ||
        this.$store.state.meetings.selectedMeeting.status.id === MeetingStatus.PENDINGAPPROVAL ||
        this.$store.state.meetings.selectedMeeting.status.id === MeetingStatus.ARCHIVED) {
        return true
      }

      if (
        claims.isInRoleSuperAdmin ||
        claims.isInRoleKT ||
        claims.isInRoleDistrictAdmin) {
        return false
      }

      if (claims.isInRoleSectionAdmin) {
        return this.$store.state.meetings.selectedMeeting.status.id !== MeetingStatus.DRAFT
      }

      return true
    },
    isCreatedStatus (): boolean {
      return this.$store.state.meetings.selectedMeeting.status.id === MeetingStatus.DRAFT
    },
    isSubmittedStatus (): boolean {
      return this.$store.state.meetings.selectedMeeting.status.id === MeetingStatus.SUBMITTED
    },
    isReviewedStatus (): boolean {
      return this.$store.state.meetings.selectedMeeting.status.id === MeetingStatus.REVIEWED
    },
    hasCrmErrors (): boolean {
      const potErrors = this.memberPositions.filter((x: MemberPositionResponse) => x.crmErrorMessages?.length)
      return potErrors.length > 0
    },
    potWithCrmErrors (): Array<MemberPositionResponse> {
      return this.memberPositions.filter((x: MemberPositionResponse) => x.crmErrorMessages?.length)
    }
  },
  methods: {
    loadMeeting (): void {
      this.$store.commit('alert/dataLoading', null, { root: true })
      meetingService.canEditMeeting(this.meetingIdNumber).then((result) => {
        this.isMeetingEditable = result
      })
      const promises = [
        this.$store.dispatch('sections/loadDetailed', this.sectionIdNumber),
        this.$store.dispatch('meetings/getMeeting', this.meetingIdNumber)
      ]
      Promise.all(promises)
        .then(() => {
          this.memberPositions = this.$store.state.meetings.selectedMeeting.memberPositions
          this.sectionActivePositionsOfTrust = this.$store.state.sections.selectedSection.positionsOfTrust
            .filter((s: MemberPositionSummaryResponse) => {
              return s.termEnd > new Date() &&
              s.meetingStatus.id !== MeetingStatus.DRAFT
            })
            .sort((a: MemberPositionSummaryResponse, b: MemberPositionSummaryResponse) => {
              return a.termStart.getTime() - b.termStart.getTime()
            })
          this.meetingLoaded = true
          this.$store.commit('alert/dataLoaded', null, { root: true })
        }).catch(fail => {
          this.meetingLoaded = true
          this.$store.commit('alert/dataLoaded', null, { root: true })

          if (fail.status === 500) {
            this.$store.dispatch('alert/error', { message: this.$t('editmeeting.loadfailed'), timeout: AlertTimeout.PERMANENT })
          } else {
            this.$store.dispatch('alert/error', { message: this.$t('editmeeting.loadfailed'), surviveRouteChange: true })
            this.$router.push('/')
          }
        })
    },
    formatParametrizedError (err: ErrorResult): string {
      const template = err.translationKey ? this.$t(err.translationKey) : err.error
      let errorMessage = template.toString()
      if (err.hasTranslationParameters) {
        const parameters = err.translationParameters
        parameters.forEach(parameter => {
          errorMessage = errorMessage.toString().replace(parameter.key, parameter.value)
        })
      }
      return errorMessage
    },
    getValidationHtml (this: any, err: RequestValidationResult): string {
      const hasAnyParamerizedErrors = this.hasParamerizedErrors(err)
      if (hasAnyParamerizedErrors) {
        const errorMessages = err.errors.map((x: ErrorResult) => this.formatParametrizedError(x))
        const message = this.$t('validation.htmlheadertext').toString().replace('{{ errors }}', formatErrors(errorMessages))
        return message
      }
      const errorMessages = err.errors.map((x: ErrorResult) => x.translationKey ? this.$t(`validation.${x.translationKey}`) : x.error)
      const message = this.$t('validation.htmlheadertext').toString().replace('{{ errors }}', formatErrors(errorMessages))
      return message
    },
    hasParamerizedErrors (this: any, err: RequestValidationResult): boolean {
      let anyParamerizedErrors = false
      err.errors.forEach(element => {
        if (element.hasTranslationParameters) {
          anyParamerizedErrors = true
        }
      })
      return anyParamerizedErrors
    },
    showErrors (err: any | RequestValidationResult): void {
      // Show the errors if there are any
      const checkIsRequestValidationResult = isRequestValidationResult(err)
      if (checkIsRequestValidationResult) {
        this.$store.dispatch('alert/error', { message: this.getValidationHtml(err), isHtml: true })
      } else {
        this.$store.dispatch('alert/error', { message: this.$t('editmeeting.memberpositionaddfailed') })
      }
    },
    onAddMemberPosition (memberPosition: AddMemberPositionRequest): void {
      this.memberPositions = this.$store.state.meetings.selectedMeeting.memberPositions
    },
    onUpdateMemberPosition (data: UpdateMemberPositionRequest): void {
      this.$store.commit('alert/dataLoading', null, { root: true })
      this.$store.dispatch('meetings/updatePositionOfTrust', data).then(success => {
        this.memberPositions = this.$store.state.meetings.selectedMeeting.memberPositions
        this.$store.commit('alert/dataLoaded', null, { root: true })
        this.$store.dispatch('alert/success', { message: this.$t('editmeeting.updatesuccess'), timeout: AlertTimeout.SHORT })
      }, (err: any | RequestValidationResult) => {
        this.$store.commit('alert/dataLoaded', null, { root: true })
        if (isRequestValidationResult(err)) {
          this.$store.dispatch('alert/error', { message: this.getValidationHtml(err), isHtml: true })
        } else {
          this.$store.dispatch('alert/error', { message: this.$t('editmeeting.memberpositionaddfailed') })
        }
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.$store.dispatch('alert/error', { message: this.$t('editmeeting.memberpositionaddfailed') })
      })
    },
    onRemoveMemberPosition (data: BoardMemberData): void {
      this.$store.commit('alert/dataLoading', null, { root: true })
      this.$store.dispatch('meetings/removePositionOfTrust', data.id).then(success => {
        this.memberPositions = this.$store.state.meetings.selectedMeeting.memberPositions
        this.$store.commit('alert/dataLoaded', null, { root: true })
        this.$store.dispatch('alert/success', { message: this.$t('editmeeting.updatesuccess'), timeout: AlertTimeout.SHORT })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.$store.commit('alert/dataLoaded', null, { root: true })
        this.$store.dispatch('alert/error', { message: this.$t('editmeeting.memberpositionremovefailed') })
      })
    },
    onMeetingDetailsChanged (details: MeetingDetailsData) {
      this.$store.commit('meetings/updateMeetingDetails', details)
      this.debouncedFunction()
    },
    debouncedMeetingDetailsChanged () {
      this.$store.commit('alert/dataLoading', null, { root: true })
      this.$store.dispatch('meetings/updateMeeting', this.$store.state.meetings.selectedMeeting).then(
        (result) => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          this.$store.dispatch('alert/success', { message: this.$t('editmeeting.updatesuccess'), timeout: AlertTimeout.SHORT })
        }, (err: any | RequestValidationResult) => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          if (isRequestValidationResult(err)) {
            this.$store.dispatch('alert/error', { message: this.getValidationHtml(err), isHtml: true })
          } else {
            this.$store.dispatch('alert/error', { message: this.$t('editmeeting.savefailed') })
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
          this.$store.dispatch('alert/error', { message: this.$t('editmeeting.savefailed') })
        })
    },
    onMeetingMinutesSentByMailChanged (val: boolean): void {
      this.$store.commit('alert/dataLoading', null, { root: true })
      this.editSentByMail(val).then((success) => {
        this.$store.commit('alert/dataLoaded', null, { root: true })
      })
    },
    editSentByMail (val: boolean): Promise<boolean> {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('meetings/updateMeetingMinutesSentByMail', val).then((result) => {
          this.$store.dispatch('alert/success', { message: this.$t('editmeeting.updatesuccess'), timeout: AlertTimeout.SHORT })
          resolve(result)
        }, (err: any | RequestValidationResult) => {
          this.showErrors(err)
          this.$store.commit('alert/dataLoaded', null, { root: true })
          resolve(false)
        }).catch((err: Error) => {
          // eslint-disable-next-line no-console
          console.log(err)
          this.$store.dispatch('alert/error', { message: this.$t('editmeeting.savefailed') })
          resolve(false)
        })
      })
    },
    onSubmit (): void {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.commit('alert/dataLoading', null, { root: true })
          this.$store.dispatch('meetings/submitMeeting', this.meetingId).then((success) => {
            this.$store.commit('alert/dataLoaded', null, { root: true })
            if (success) {
              this.$store.dispatch('alert/success', { message: this.$t('editmeeting.submitsuccess'), surviveRouteChange: true })
              this.$router.push(`/sections/${this.sectionId}/meetings`)
            } else {
              this.$store.dispatch('alert/error', { message: this.$t('editmeeting.submitfailed') })
            }
          }, (err: any | RequestValidationResult) => {
            this.showErrors(err)
            this.$store.commit('alert/dataLoaded', null, { root: true })
          }).catch(error => {
            // eslint-disable-next-line no-console
            console.log(error)
            this.$store.commit('alert/dataLoaded', null, { root: true })
            this.$store.dispatch('alert/error', { message: this.$t('editmeeting.submitfailed') })
          })
        }
      }, (error) => {
        this.$store.commit('alert/dataLoaded', null, { root: true })
        this.$store.dispatch('alert/error', { message: error })
      })
    },
    /* Commented this out for now (#8838). We might still need it in the future? Clean this up if not needed
    onReviewed (): void {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.commit('alert/dataLoading', null, { root: true })
          this.$store.dispatch('meetings/reviewedMeeting', this.meetingId).then((success) => {
            this.$store.commit('alert/dataLoaded', null, { root: true })
            if (success) {
              this.$store.dispatch('alert/success', { message: this.$t('editmeeting.reviewedsuccess'), surviveRouteChange: true })
              this.$router.push(`/sections/${this.sectionId}/meetings`)
            } else {
              this.$store.dispatch('alert/error', { message: this.$t('editmeeting.reviewfailed') })
            }
          }, (err: any | RequestValidationResult) => {
            this.showErrors(err)
            this.$store.commit('alert/dataLoaded', null, { root: true })
          }).catch(error => {
            // eslint-disable-next-line no-console
            console.log(error)
            this.$store.commit('alert/dataLoaded', null, { root: true })
            this.$store.dispatch('alert/error', { message: this.$t('editmeeting.reviewfailed') })
          })
        }
      }, (error) => {
        this.$store.commit('alert/dataLoaded', null, { root: true })
        this.$store.dispatch('alert/error', { message: error })
      })
    },*/
    onArchived (): void {
      if (confirm(this.$t('editmeeting.archiveconfirm').toString())) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$store.commit('alert/dataLoading', null, { root: true })
            this.$store.dispatch('meetings/archivedMeeting', this.meetingId).then((success) => {
              this.$store.commit('alert/dataLoaded', null, { root: true })
              if (success) {
                this.$store.dispatch('alert/success', { message: this.$t('editmeeting.archivedsuccess'), surviveRouteChange: true })
                this.$router.push(`/sections/${this.sectionId}/meetings`)
              } else {
                this.$store.dispatch('alert/error', { message: this.$t('editmeeting.archivefailed') })
              }
            }, (err: any | RequestValidationResult) => {
              this.showErrors(err)
              this.$store.commit('alert/dataLoaded', null, { root: true })
            }).catch(error => {
            // eslint-disable-next-line no-console
              console.log(error)
              this.$store.commit('alert/dataLoaded', null, { root: true })
              this.$store.dispatch('alert/error', { message: this.$t('editmeeting.archivefailed') })
            })
          }
        }, (error) => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          this.$store.dispatch('alert/error', { message: error })
        })
      }
    },
    onTemplate (): void {
      this.$router.push(`/sections/${this.sectionId}`)
    },
    onApproved (): void {
      if (confirm(this.$t('editmeeting.approveconfirm').toString())) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$store.commit('alert/dataLoading', null, { root: true })
            this.$store.dispatch('meetings/approveMeeting', this.meetingId).then((success) => {
              this.$store.commit('alert/dataLoaded', null, { root: true })
              if (success) {
                this.$store.dispatch('alert/success', { message: this.$t('editmeeting.approvedsuccess'), surviveRouteChange: true })
                this.$router.push(`/sections/${this.sectionId}/meetings`)
              } else {
                this.$store.dispatch('alert/error', { message: this.$t('editmeeting.approvefailed') })
              }
            }, (err: any | RequestValidationResult) => {
              this.showErrors(err)
              this.$store.commit('alert/dataLoaded', null, { root: true })
            }).catch(error => {
            // eslint-disable-next-line no-console
              console.log(error)
              this.$store.commit('alert/dataLoaded', null, { root: true })
              this.$store.dispatch('alert/error', { message: this.$t('editmeeting.approvefailed') })
            })
          }
        }, (error) => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          this.$store.dispatch('alert/error', { message: error })
        })
      }
    },
    onDelete (): void {
      if (confirm(this.$t('editmeeting.deleteconfirm').toString())) {
        this.$store.dispatch('meetings/deleteMeeting', this.meetingId).then((success) => {
          this.$store.dispatch('alert/success', { message: this.$t('editmeeting.deletesuccess'), surviveRouteChange: true })
          this.$router.push(`/sections/${this.sectionId}/meetings`)
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
          this.$store.dispatch('alert/error', { message: this.$t('editmeeting.deletefailed') })
        })
      }
    },
    onViewMeetingClicked (position: MemberPositionSummaryResponse): void {
      this.$router.push(`/sections/${this.sectionId}/meetings/${position.meetingId}`)
    }
  },
  mounted (): void {
    this.loadMeeting()

    this.debouncedFunction = debounce(this.debouncedMeetingDetailsChanged, 800)
  },
  watch: {
    $route (to, from) {
      this.loadMeeting()
    }
  }
})
