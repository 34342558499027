
import Vue from 'vue'
import LinkIconBox from '@/components/LinkIconBox.vue'
import StatisticTile from '@/components/StatisticTile.vue'
import Loading from '@/components/Loading.vue'
import { SectionDetailedResponse } from '@/types'
import { decodeToken } from '../helpers/auth/authorizationhelper'

export default Vue.extend({
  name: 'Home',
  components: {
    LinkIconBox,
    StatisticTile,
    Loading
  },
  methods: {
    districtsClicked () {
      this.$router.push('/districts')
    },
    officialLetterRequestsClicked () {
      this.$router.push('/officialletterrequests')
    },
    usersClicked () {
      this.$router.push(`/members`)
    },
    statisticClicked (name: string) {
      // eslint-disable-next-line no-console
      console.log(`${name} stat was clicked`)
    }
  },
  data () {
    return {
      loaded: false
    }
  },
  mounted () {
    // admins can see this page
    if (this.hasAdminAccess) return

    // district admin levels go to their district homepages
    if (this.hasDistrictAccess) {
      this.$router.push({ path: `/districts/${this.districtId}` })
      return
    }

    // section admin levels go to their section homepages
    if (this.hasSectionAccess) {
      this.$router.push({ path: `/sections/${this.sectionId}` })
      return
    }

    //shouldnt get here.
    this.$router.push({ path: '/404' })
  },
  computed: {
    sectionId (): number {
      return Number(decodeToken(this.$store.state.authentication.token).sectionId) || -1
    },
    districtId (): number {
      return Number(decodeToken(this.$store.state.authentication.token).districtId) || -1
    },
    hasAdminAccess (): boolean {
      // Can view all districts
      return (
        this.$store.state.authentication.claims.isInRoleSuperAdmin ||
        this.$store.state.authentication.claims.isInRoleKT)
    },
    hasDistrictAccess (): boolean {
      // can view all sections in a district
      return (
        this.$store.state.authentication.claims.isInRoleDistrictAdmin ||
        this.$store.state.authentication.claims.isInRoleDistrictUser)
    },
    hasSectionAccess (): boolean {
      // can only view the section they are in
      return (
        (
          this.$store.state.authentication.claims.isInRoleSectionUser ||
          this.$store.state.authentication.claims.isInRoleSectionAdmin
        ) && (
          !this.hasDistrictAccess &&
          !this.hasAdminAccess
        )
      )
    }
  }
})
