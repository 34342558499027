
import Vue from 'vue'
import LoginForm from '@/components/LoginForm.vue'
import { LoginRequest } from '@/types'
import { mainService } from '@/services/main.service'
import { AlertTimeout } from '@/types/enums'

export default Vue.extend({
  name: 'Login',
  components: {
    LoginForm
  },
  data () {
    return {
      submitted: false
    }
  },
  created () {
    // reset login status
    this.$store.dispatch('authentication/logout')
  },
  methods: {
    onLogin (login: LoginRequest) {
      this.submitted = true

      if (login.email && login.password) {
        this.$store.dispatch('authentication/login', login).then(success => {
          mainService.handleAfterAuthenticated().then(() => {
            if (this.$store.state.authentication.claims.passwordExpiring) {
              setTimeout(() => {
                this.$store.dispatch('alert/info', { message: this.$t('login.passwordexpiring'), timeout: AlertTimeout.LONG })
              }, 0)
            }
            this.$router.push('/')
          })
        }, fail => {
          //show noroles error, if we are returning the specific error message.
          //otherwise return the generic one
          if (fail.response.data.includes('Password has expired')) {
            this.$store.dispatch('alert/error', { message: this.$t('login.passwordexpired'), surviveRouteChange: true, isHtml: true })
          } else if (fail.response.data.includes('no roles')) {
            this.$store.dispatch('alert/error', { message: this.$t('login.noroles'), surviveRouteChange: true, isHtml: true })
          } else {
            this.$store.dispatch('alert/error', { message: this.$t('login.loginfailed') })
          }
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log('Error logging in', err)
          this.$store.dispatch('alert/error', { message: this.$t('login.loginfailed') })
        })
      }
    },
    onAdLogin () {
      const baseUrl = window.location.origin
      const adInfo = this.$store.state.authentication.activeDirectory
      const adLoginUrl = `${adInfo.loginUrl}&redirect_uri=${baseUrl}${adInfo.redirectUri}`
      window.location.href = adLoginUrl
    }
  },
  computed: {
    loggingIn (): boolean {
      return this.$store.state.authentication.status.loggingIn
    }
  }
})
