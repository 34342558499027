
import Vue from 'vue'
import Back from '@/components/Back.vue'
import SprFormTextArea from '@/components/forms/SprFormTextArea.vue'
import SprButton from '@/components/forms/SprButton.vue'
import { sectionService } from '@/services/section.service.ts'
import { RequestValidationResult, AddOfficialLetterRequestRequest } from '@/types'
import { isRequestValidationResult } from '@/types/custom.ts'

export default Vue.extend({
  name: 'AddOfficialLetterRequest',
  components: {
    Back,
    SprFormTextArea,
    SprButton
  },
  props: {
    sectionId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      request: {
        memberId: this.$store.state.authentication.claims.ossiId,
        reasonForRequest: '',
        sectionId: Number(this.sectionId)
      } as AddOfficialLetterRequestRequest
    }
  },
  methods: {
    onCancel (): void {
      this.$router.push(`/sections/${this.sectionId}/reports`)
    },
    onSend (): void {
      this.$validator.validateAll()
        .then((result) => {
          if (this.$validator.errors.items.length) return
          if (result) {
            this.$store.commit('alert/dataLoading', null, { root: true })
            return sectionService.addOfficialLetterRequest(this.request)
          }
        })
        .then((success) => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          if (success) {
            this.$store.dispatch('alert/success', { message: this.$t('addofficialletterrequest.addsuccess'), surviveRouteChange: true })
            this.$router.push(`/sections/${this.sectionId}/reports`)
          }
        })
        .catch((error: Error | RequestValidationResult) => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          if (isRequestValidationResult(error)) {
            for (const err of error.errors) {
              this.$store.dispatch('alert/error', { message: this.$t(`validation.${err.translationKey}`) })
            }
          } else {
            this.$store.dispatch('alert/error', { message: this.$t('addofficialletterrequest.addfailed') })
          }
        })
    }
  }
})
