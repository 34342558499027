<template>
  <div class="footer">
    <div class="row">
      <div class="col">
        <ul class="footernav align-middle">
          <li class="footerlink">
            {{ $t('footer.copyright') }} {{ currentYear }}
          </li>
          <!-- <li class="footerlink">
            <a href="https://www.google.com">{{ $t('footer.help') }}</a>
          </li>
          <li class="footerlink">
            <a href="https://www.google.com">Todo these links</a>
          </li> -->
          <li class="footerlink">
            <a href="https://www.punainenristi.fi/tietosuoja">{{ $t('footer.privacy') }}</a>
          </li>
          <li class="version-info">
            {{ versionInfo }}
            {{ versionInfoSeparator }}
            <a v-if="ossiWebApiVersionLink" :href="ossiWebApiVersionLink" target="_blank" class="footer-link-basic" tabindex="0">
              Ossi Web API Version?</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store'
import version from '../../../package'
import Vue from 'vue'

export default Vue.extend({
  name: 'Footer',
  data () {
    return {
      currentYear: new Date().getUTCFullYear(),
      appVersion: version
    }
  },
    computed: {
      ossiWebApiVersionLink: function () {
        const ossiWebApiVersion = `${store.state.config.ossiServiceBaseUrl}${store.state.codes.getOssiWebApiVersionUrl}`

        return ossiWebApiVersion
      },
      versionInfo: function () {
        return this.appVersion.version
      },
      versionInfoSeparator: function () {
        return '| '
      }
    }
})
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .footer {
    margin-top: 50px;
    padding: 12px 0;
    text-align: center;
    color:$brand-black;
    font-family: 'Inconsolata', monospace;
    .footernav {
      margin: 0;
      padding: 0;
      .footerlink {
        display: inline-block;
        margin-left: 20px;
        color: $brand-font-extra-light;
        font-family: "Signa-Book", sans-serif;
        a {
          color: $brand-font-extra-light;
          text-decoration: none;
        }

      }
    }
    .version-info {
      color: $brand-font-extra-light;
      display: inline-block;
      padding: 0px 20px;
    }

    .footer-link-basic {
      font-family: $font-light;
      font-size: 14px;
      color: $brand-font-extra-light;

      &:not(:first-of-type) {
        margin: 0 0 0 15px;
      }
    }
  }
</style>
