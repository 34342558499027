
import Vue from 'vue'
/*
  This component seems pointless, but is needed to validate v-validate :before and :after validations if you do not use a field with a date set.
  This forces the output to date rather than a string from a normal input hidden field.
*/
export default Vue.extend({
  name: 'SprHiddenDateField',
  props: {
    value: {
      type: Date,
      required: true
    }
  },
  methods: {
    sendValue (val: Date) {
      this.$emit('input', new Date(val))
    }
  }
})
