
import Vue from 'vue'
import { DistrictActiveRoles } from '@/types'
import SectionRoleItem from '@/components/sections/SectionRoleItem.vue'

export default Vue.extend({
  name: 'SectionRolesList',
  components: { SectionRoleItem },
  props: {
    districtId: {
      type: String,
      required: true,
      default: null
    }
  },
  data () {
    return {
      districtMemberRoles: {
        districtExist: false,
        districtId: -1,
        districtName: 'Must be fetched!'
      } as DistrictActiveRoles
    }
  },
  computed: {
    noResults (): boolean {
      return !this.districtMemberRoles || !this.districtMemberRoles.districtRoles || this.districtMemberRoles.districtRoles.length === 0
    },
    sectionName (): undefined | string {
      if (this.districtMemberRoles != null) {
        return this.districtMemberRoles.districtName
      }
      return ''
    }
  },
  created () {
    const districtId = Number(this.districtId)
    this.$store.commit('alert/dataLoading', null, { root: true })
    this.$store.dispatch('districts/getDistrictRoles', districtId).then(s => {
      this.$store.commit('alert/dataLoaded', null, { root: true })
      this.districtMemberRoles = this.$store.state.districts.districtRoles
    }).catch(err => {
      this.$store.commit('alert/dataLoaded', null, { root: true })
      // eslint-disable-next-line no-console
      console.log(err)
      this.$store.dispatch('alert/error', { message: this.$t('members.findfailed') })
      this.districtMemberRoles = {
        districtExist: false,
        districtId: -1,
        districtName: 'Must be fetched!'
      } as DistrictActiveRoles
    })
  }
})

