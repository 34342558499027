
import Vue from 'vue'
import Back from '@/components/Back.vue'
import { TranslateResult } from 'vue-i18n'
import { SectionSummaryResponse, MemberSummaryResponse, DistrictSummaryResponse } from '@/types'
import { CodeCategory } from '@/types/enums'
import { membersService } from '@/services'

export default Vue.extend({
  name: 'Sections',
  components: {
    Back
  },
  props: {
    districtId: {
      type: String,
      default: '0'
    }
  },
  computed: {
    districtIdNumber () {
      return Number(this.districtId)
    },
    sections (this: any): Array<SectionSummaryResponse> {
      if (this.districtIdNumber > 0) {
        return this.$store.state.sections.all.filter((x: SectionSummaryResponse) => x.districtId === this.districtIdNumber)
      }
      return this.$store.state.sections.all
    },
    canEditMeetings () {
      const claims = this.$store.state.authentication.claims
      return claims.isInRoleSuperAdmin ||
          claims.isInRoleKT ||
          claims.isInRoleDistrictAdmin ||
          claims.isInRoleSectionAdmin
    },
    canViewMembers () {
      const claims = this.$store.state.authentication.claims
      return claims.isInRoleSuperAdmin ||
          claims.isInRoleKT ||
          claims.isInRoleDistrictAdmin ||
          claims.isInRoleSectionAdmin
    },
    districtName (): string {
      if (this.$store.state.districts.districtsLoaded) {
        const district = this.$store.state.districts.all.find((x: DistrictSummaryResponse) => x.id === Number(this.districtIdNumber))
        return district ? district.name : ''
      }
      return ''
    }
  },
  methods: {
    onSectionClicked (section: SectionSummaryResponse): void {
      this.$router.push(`/sections/${section.id}`)
    },
    onMembersClicked (section: SectionSummaryResponse): void {
      this.$router.push(`/sections/${section.id}/members`)
    },
    onMeetingsClicked (section: SectionSummaryResponse): void {
      this.$router.push(`/sections/${section.id}/meetings`)
    },
    onAddMeetingClicked (section: SectionSummaryResponse): void {
      this.$router.push(`/sections/${section.id}/meetings/add`)
    },
    getStatus (statusId: number): TranslateResult {
      return this.$t(`sectionstatus-${statusId}`)
    }
  }
})
