
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { MeetingStatusCode } from '@/helpers/codehelper'
import { MeetingStatus, MeetingDisplayStatus, CodeCategory } from '@/types/enums'

export default Vue.extend({
  name: 'MeetingStatus',
  props: {
    status: {
      type: Number as () => MeetingStatus,
      default: 0
    }
  },
  computed: {
    statusText (this: any): TranslateResult {
      return this.$t(`meetingstatus-${this.status}`)
    },
    statusCssClass (): string {
      return `status-${this.status}`
    }
  }
})
