import { codeService } from '../services/code.service'
import { router } from '../router'
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store'
import { CodeCategory } from '@/types/enums'
import { getCode, MeetingStatusCode, MeetingTypeCode, MembershipStatusCode, MembershipTypeCode, MemberPositionStatusCode, MemberStatusCode, MinutesStatusCode, SectionStatusCode, DistrictStatusCode } from '@/helpers/codehelper'
export interface CodeState {
  meetingStatuses: Array<MeetingStatusCode> | Array<never>,
  meetingTypes: Array<MeetingTypeCode> | Array<never>,
  membershipStatuses: Array<MembershipStatusCode> | Array<never>,
  membershipTypes: Array<MembershipTypeCode> | Array<never>,
  membershipPositionStatueses: Array<MemberPositionStatusCode> | Array<never>,
  memberStatuses: Array<MemberStatusCode> | Array<never>,
  minutesStatuses: Array<MinutesStatusCode> | Array<never>,
  sectionStatuses: Array<SectionStatusCode> | Array<never>,
  districtStatuses: Array<DistrictStatusCode> | Array<never>,
  getMeetingStatusesUrl: string,
  getMeetingTypesUrl: string,
  getMembershipStatusesUrl: string,
  getMembershipTypesUrl: string,
  getMembershipPositionStatuesesUrl: string,
  getMemberStatusesUrl: string,
  getMinutesStatusesUrl: string,
  getSectionStatusesUrl: string,
  getDistrictStatusesUrl: string,
  getOssiWebApiVersionUrl: string
}

const getDefaultState = (): CodeState => {
  return {
    meetingStatuses: [],
    meetingTypes: [],
    membershipStatuses: [],
    membershipTypes: [],
    membershipPositionStatueses: [],
    memberStatuses: [],
    minutesStatuses: [],
    sectionStatuses: [],
    districtStatuses: [],
    getMeetingStatusesUrl: '/api/code/getmeetingstatus',
    getMeetingTypesUrl: '/api/code/getmeetingtype',
    getMembershipStatusesUrl: '/api/code/getmembershipstatus',
    getMembershipTypesUrl: '/api/code/getmembershiptype',
    getMembershipPositionStatuesesUrl: '/api/code/getmemberpositionstatus',
    getMemberStatusesUrl: '/api/code/getmemberstatus',
    getMinutesStatusesUrl: '/api/code/getminutesstatus',
    getSectionStatusesUrl: '/api/code/getsectionstatus',
    getDistrictStatusesUrl: '/api/code/getdistrictstatus',
    getOssiWebApiVersionUrl: '/api/ossiversion'
  }
}

export const state = getDefaultState()

const actions: ActionTree<CodeState, RootState> = {

  loadAll ({ commit }) {
    return new Promise<void>((resolve, reject) => {
      codeService.getAllCodes().then((results) => {
        commit('saveMeetingStatuses', results[0])
        commit('saveMeetingTypes', results[1])
        commit('saveMembershipStatuses', results[2])
        commit('saveMembershipTypes', results[3])
        commit('saveMembershipPositionStatuses', results[4])
        commit('saveMemberStatuses', results[5])
        commit('saveMinutesStatuses', results[6])
        commit('saveSectionStatuses', results[7])
        commit('saveDistrictStatuses', results[8])
        resolve()
      }, err => reject(err)
      )
    })
  }
}

const mutations: MutationTree<CodeState> = {

  saveMeetingStatuses (state, payload) {
    state.meetingStatuses = payload
  },

  saveMeetingTypes (state, payload) {
    state.meetingTypes = payload
  },

  saveMembershipStatuses (state, payload) {
    state.membershipStatuses = payload
  },

  saveMembershipTypes (state, payload) {
    state.membershipTypes = payload
  },

  saveMembershipPositionStatuses (state, payload) {
    state.membershipPositionStatueses = payload
  },

  saveMemberStatuses (state, payload) {
    state.memberStatuses = payload
  },

  saveMinutesStatuses (state, payload) {
    state.minutesStatuses = payload
  },

  saveSectionStatuses (state, payload) {
    state.sectionStatuses = payload
  },

  saveDistrictStatuses (state, payload) {
    state.districtStatuses = payload
  },

  reset (state) {
    Object.assign(state, getDefaultState())
  }
}

const getters: GetterTree<CodeState, RootState> = {
  getCode: (state: CodeState) => (id: number, codeCategory: CodeCategory) => {
    if (codeCategory) {
      //we can limit it
      return getCode(state, id, codeCategory)
    } else {
      // we got to search all the codes for this id
      // is there a better way of doing this??
      // Maybe fill an all codes table?
      const meetingStatus = getCode(state, id, CodeCategory.MEETING_STATUS)
      if (meetingStatus) return meetingStatus

      const meetingType = getCode(state, id, CodeCategory.MEETING_TYPE)
      if (meetingType) return meetingType

      const membershipStatus = getCode(state, id, CodeCategory.MEMBERSHIP_STATUS)
      if (membershipStatus) return membershipStatus

      const membershipType = getCode(state, id, CodeCategory.MEMBERSHIP_TYPE)
      if (membershipType) return membershipType

      const membershipPositionStatus = getCode(state, id, CodeCategory.MEMBER_POSITION_STATUS)
      if (membershipPositionStatus) return membershipPositionStatus

      const memberStatus = getCode(state, id, CodeCategory.MEMBER_STATUS)
      if (memberStatus) return memberStatus

      const minutesStatus = getCode(state, id, CodeCategory.MINUTES_STATUS)
      if (minutesStatus) return minutesStatus

      const sectionStatus = getCode(state, id, CodeCategory.SECTION_STATUS)
      if (sectionStatus) return sectionStatus

      const districtStatus = getCode(state, id, CodeCategory.DISTRICT_STATUS)
      if (districtStatus) return districtStatus

      return null
    }
  },
  getMeetingStatusById: (state: CodeState) => (id: number) => {
    return state.meetingStatuses.find((x: MeetingStatusCode) => x.id === id)
  },
  getMeetingTypesById: (state: CodeState) => (id: number) => {
    return state.meetingTypes.find((x: MeetingTypeCode) => x.id === id)
  },
  getMembershipStatusesById: (state: CodeState) => (id: number) => {
    return state.membershipStatuses.find((x: MembershipStatusCode) => x.id === id)
  },
  getMembershipTypesById: (state: CodeState) => (id: number) => {
    return state.membershipTypes.find((x: MembershipTypeCode) => x.id === id)
  },
  getMembershipPositionStatuesesById: (state: CodeState) => (id: number) => {
    return state.membershipPositionStatueses.find((x: MemberPositionStatusCode) => x.id === id)
  },
  getMemberStatusesById: (state: CodeState) => (id: number) => {
    return state.memberStatuses.find((x: MemberStatusCode) => x.id === id)
  },
  getMinutesStatusesById: (state: CodeState) => (id: number) => {
    return state.minutesStatuses.find((x: MinutesStatusCode) => x.id === id)
  },
  getSectionStatusesById: (state: CodeState) => (id: number) => {
    return state.sectionStatuses.find((x: SectionStatusCode) => x.id === id)
  },
  getDistrictStatusesById: (state: CodeState) => (id: number) => {
    return state.districtStatuses.find((x: DistrictStatusCode) => x.id === id)
  }
}

export const codes: Module<CodeState, RootState> = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
}
