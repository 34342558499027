import { render, staticRenderFns } from "./Districts.vue?vue&type=template&id=d0c18bee&scoped=true"
import script from "./Districts.vue?vue&type=script&lang=ts"
export * from "./Districts.vue?vue&type=script&lang=ts"
import style0 from "./Districts.vue?vue&type=style&index=0&id=d0c18bee&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0c18bee",
  null
  
)

export default component.exports