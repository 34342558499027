
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import SprFormDropdown from '@/components/forms/SprFormDropdown.vue'
import MeetingStatus from '@/components/meetings/MeetingStatus.vue'
import { finnishShortDate } from '../../helpers/datehelper'
import { MeetingSummaryResponse } from '@/types'
import { DropdownOptions } from '@/types/custom.ts'

export default Vue.extend({
  name: 'MeetingsList',
  components: { MeetingStatus, SprFormDropdown },
  props: {
    value: {
      type: Array,
      default: undefined
    },
    columns: {
      type: Array,
      default () {
        return ['Section', 'Name', 'Type', 'Status', 'Created', 'DateHeld', 'ReportedBy', 'CreatedBy']
      },
      required: false,
      validator (value: Array<string>) {
        return value.every(x => ['Section', 'Name', 'Type', 'Status', 'Created', 'DateHeld', 'ReportedBy', 'CreatedBy'].indexOf(x) !== -1)
      }
    }
  },
  data () {
    return {
      sortOrder: 'Created'
    }
  },
  computed: {
    isDistrictAdminAndAbove (): boolean {
      const claims = this.$store.state.authentication.claims
      return claims.isInRoleSuperAdmin ||
        claims.isInRoleKT ||
        claims.isInRoleDistrictAdmin
    },
    isOnlySectionUser (): boolean {
      const claims = this.$store.state.authentication.claims
      return claims.isInRoleSectionUser && !(claims.isInRoleSuperAdmin ||
        claims.isInRoleKT ||
        claims.isInRoleDistrictAdmin ||
        claims.isInRoleSectionAdmin)
    },
    sortOrders (): Array<DropdownOptions> {
      return [
        { text: this.$t('meeting.section'), value: 'Section' },
        { text: this.$t('meeting.name-short'), value: 'Name' },
        { text: this.$t('meeting.type-short'), value: 'Type' },
        { text: this.$t('meeting.status'), value: 'Status' },
        { text: this.$t('meeting.created'), value: 'Created' },
        { text: this.$t('meeting.dateheld'), value: 'DateHeld' },
        { text: this.$t('meeting.reportedby'), value: 'ReportedBy' },
        { text: this.$t('meeting.createdby'), value: 'CreatedBy' }
      ] as Array<DropdownOptions>
    },
    sortedList (): any {
      // @ts-ignore
      const tempSortOrder = this.sortOrder
      // @ts-ignore
      const getSection = this.getSection
      const val: Array<MeetingSummaryResponse> = this.value as Array<MeetingSummaryResponse>
      return val.sort((a: MeetingSummaryResponse, b: MeetingSummaryResponse) => {
        switch (tempSortOrder) {
          case 'Section':
            return getSection(a.sectionId).localeCompare(getSection(b.sectionId))
          case 'Name':
            return a.name.localeCompare(b.name)
          case 'Type':
            return a.type.id - b.type.id
          case 'Status':
            return a.status.id - b.status.id
          case 'DateHeld':
            return b.dateHeld.getTime() - a.dateHeld.getTime()
          case 'ReportedBy':
            return a.reportedBy.localeCompare(b.reportedBy)
          case 'CreatedBy':
            return a.createdBy.localeCompare(b.createdBy)
          default:
            return (b.created && a.created) ? b.created.getTime() - a.created.getTime() : true
        }
      })
    }
  },
  methods: {
    hasColumn (col: string): boolean {
      return this.columns.indexOf(col) >= 0
    },
    getShortDate (date: Date) {
      return finnishShortDate(date)
    },
    getSection (sectionId: number) {
      const section = this.$store.getters['sections/getById'](sectionId)
      return section?.name
    },
    onMeetingClicked (meeting: MeetingSummaryResponse) {
      this.$router.push(`/sections/${meeting.sectionId}/meetings/${meeting.id}`)
    },
    getMeetingType (typeid: number): TranslateResult {
      return this.$t(`meetingtype-${typeid}`)
    }
  }
})
