
import Vue from 'vue'
import SprFormText from '@/components/forms/SprFormText.vue'
import SprButton from '@/components/forms/SprButton.vue'
import AddressEditor from '@/components/sections/AddressEditor.vue'
import { isAddressEmpty, formatAddress } from '@/helpers/addresshelper'
import { formatErrors } from '@/helpers/validationhelper'
import { finnishShortDate } from '@/helpers/datehelper'
import { SectionDetailedResponse, AddressResponse, RequestValidationResult, ErrorResult } from '@/types'
import { isRequestValidationResult } from '@/types/custom.ts'
import {AlertTimeout } from '@/types/enums'

export default Vue.extend({
  name: 'SectionSummary',
  components: { SprFormText, SprButton, AddressEditor },
  props: {
    section: {
      type: Object as () => SectionDetailedResponse,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    numberOfActiveMembers: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      isEditing: false
    }
  },
  methods: {
    hasAddress (add: AddressResponse): boolean {
      return !isAddressEmpty(add)
    },
    formatAddress (add: AddressResponse): string {
      return formatAddress(add)
    },
    formatDate (date: Date): string {
      return finnishShortDate(date)
    },
    billingAddressChanged (newVal: AddressResponse) {
      this.localSection.billingAddress = newVal
    },
    shippingAddressChanged (newVal: AddressResponse) {
      this.localSection.shippingAddress = newVal
    },
    onReset () {
      if (confirm(this.$t('section.resetconfirm').toString())) {
        this.$store.commit('alert/dataLoading', null, { root: true })
        this.$store.dispatch('sections/resetSection', this.localSection).then(() => {
          this.$store.commit('alert/dataLoaded', null, { root: true })
          this.$store.dispatch('alert/success', { message: this.$t('section.resetsuccesful'), timeout: AlertTimeout.SHORT })
        })
      }
    }
  },
  computed: {
    localSection () {
      return this.section
    },
    languageTranslation () {
      return this.$t(`sectionlanguage-${this.section.language.id}`)
    },
    statusTranslation () { //Tila
      return this.$t(`sectionstatus-${this.section.status.id}`)
    },
    canResetSection (): boolean {
      return (this.$store.state.authentication.claims.isInRoleSectionAdmin || this.$store.state.authentication.claims.isInRoleDistrictAdmin || this.$store.state.authentication.claims.isInRoleSuperAdmin)
    }
  }
})
