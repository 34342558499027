
import Vue from 'vue'
import Vuex from 'vuex'
import SprFormFileUpload from '@/components/forms/SprFormFileUpload.vue'
import SprFormCheckbox from '@/components/forms/SprFormCheckbox.vue'
import Spinner from '@/components/Spinner.vue'
import { MinutesResponse, MinutesFileRequest, MeetingMinutesRequest, MinutesDocumentResponse } from '@/types'
import { MinutesStatus } from '@/types/enums'

export default Vue.extend({
  name: 'MeetingMinutesForm',
  components: {
    SprFormFileUpload,
    SprFormCheckbox,
    Spinner
  },
  props: {
    isReadOnly: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    localVal (): MinutesResponse {
      return this.$store.state.meetings.selectedMeeting.minutes
    }
  },
  methods: {
    onSentByMailChanged (newVal: boolean): void {
      this.$emit('sent-by-mail-changed', newVal)
    },
    processUploadedFile (name: string, type: string, rawString: string | null) {
      if (!rawString) return

      if (!this.localVal) return

      // check a file with this name has not been uploaded already
      const sameDoc = this.$store.state.meetings.selectedMeeting.minutes.documents.find((doc: MinutesDocumentResponse) => {
        return doc.name.toLowerCase() === name.toLowerCase()
      })

      if (sameDoc) {
        this.$store.dispatch('alert/error', { message: this.$t('editmeeting.minutesfileuploadduplicate') })
        return
      }

      const doc: MinutesFileRequest = {
        id: null,
        name: name,
        // @ts-ignore-next-line
        minutesId: this.localVal.id || null,
        blob: rawString ? btoa(rawString) : '',
        url: null,
        uploadedBy: null,
        fileType: type
      }

      if (doc.name.length > 800) {
        this.$store.dispatch('alert/error', { message: this.$t('editmeeting.minutesfileuploadnametoolarge') })
        return
      }

      if (doc.fileType.length > 255) {
        this.$store.dispatch('alert/error', { message: this.$t('editmeeting.minutesfileuploadtypetoolarge') })
        return
      }

      this.$store.dispatch('meetings/uploadDoc', doc).then(success => {
        this.$store.dispatch('alert/success', { message: this.$t('editmeeting.minutesfileuploadsuccess') })
      }).catch(err => {
        if (err.body) {
          this.$store.dispatch('alert/error', { message: err.body, isHtml: true, timeout: 12000 })
        }
        // eslint-disable-next-line no-console
        console.log(err)
        this.$store.dispatch('alert/error', { message: this.$t('editmeeting.minutesfileuploadfailed') })
      })
    },
    removeUploadedFile (doc: MinutesDocumentResponse) {
      this.$store.dispatch('meetings/removeDoc', doc.id).then(success => {
        this.localVal.documents = this.localVal.documents.filter((item) => {
          return item.id !== doc.id
        })
        this.$store.dispatch('alert/success', { message: this.$t('editmeeting.minutesfiledeletesuccess') })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.$store.dispatch('alert/error', { message: this.$t('editmeeting.minutesfiledeletefailed') })
      })
    },
    onMinutesFileUploaded (file: File | null) {
      if (file) {
        // if the meeting minutes do not yet exist, create it first
        if (!this.$store.state.meetings.selectedMeeting.minutes) {
          const data = {
            meetingId: this.$store.state.meetings.selectedMeeting.id,
            isSentByMail: this.localVal.isSentByMail
          } as MeetingMinutesRequest
          this.$store.dispatch('meetings/addOrUpdateMeetingMinutes', data).then((mins) => {
            this.readFile(file)
          })
        } else {
          this.readFile(file)
        }
      }
    },
    readFile (file: File) {
      const reader = new FileReader()

      reader.onload = (readerEvt: ProgressEvent<FileReader>) => {
        const raw = reader.result as string
        // var base64 = btoa(binaryString);
        this.processUploadedFile(file.name, file.type, raw)
      }

      reader.readAsBinaryString(file)
    }
  }
})
