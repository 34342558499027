
import Vue from 'vue'
import Tooltip from '@/components/Tooltip.vue'
import { DropdownOptions } from '@/types/custom'

export default Vue.extend({
  name: 'SprFormDropdown',
  components: { Tooltip },
  inject: ['$validator'],
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array as () => Array<DropdownOptions>,
      default () {
        return [] as Array<DropdownOptions>
      }
    },
    required: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    useTooltip: {
      type: Boolean,
      default: false
    },
    tooltipMessage: {
      type: String,
      default: null
    },
    validations: {
      type: String,
      default: ''
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localValue: '',
      tooltipId: 'tooltipDropDown_'
    }
  },
  methods: {
    selectValue (e: Event) {
      if (e && e.target) {
        this.localValue = (e.target as HTMLSelectElement).value
        this.$emit('input', this.localValue)
      }
    },
    generateUUID (): string {
      let d = new Date().getTime()

      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now() //use high-precision timer if available
      }

      const newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })

      return newGuid
    }
  },
  mounted () {
    this.localValue = this.value
    this.tooltipId = 'tooltipDropDown_' + this.generateUUID()
  },
  watch: {
    value (newVal) {
      this.localValue = newVal
    }
  }
})
