
import Vue from 'vue'
import { isAddressEmpty, formatAddress } from '@/helpers/addresshelper'
import { MemberPositionSummaryResponse, AddressResponse, MemberSummaryResponse, MemberSummaryDetails } from '@/types'
import { finnishShortDate } from '@/helpers/datehelper'
import { MemberPositionStatus } from '@/types/enums'

export default Vue.extend({
  name: 'ActivePositionsOfTrustSummary',
  props: {
    positions: {
      type: Array as () => Array<MemberPositionSummaryResponse>,
      required: false,
      default: []
    }
  },
  methods: {
    canSeeMemberInfo (pos: MemberPositionSummaryResponse): boolean {
      const v = pos.member as MemberSummaryDetails
      const IsMemberOnThisSection = v && !v.hasDifferentSection
      const CanViewMemberOnOtherSection = this.hasAccessToOtherSections(pos) && (v && v.hasDifferentSection)
      if (CanViewMemberOnOtherSection || IsMemberOnThisSection) {
        return true
      } else {
        return false
      }
    },
    getPositionTranslation (positionId: number) {
      const positionCode = 'positionId-' + positionId.toString()
      return this.$t(positionCode)
    },
    hasAccessToOtherSections (pos: MemberPositionSummaryResponse) {
      return this.$store.state.authentication.claims.isInRoleSuperAdmin ||
      this.$store.state.authentication.claims.isInRoleKT ||
      this.$store.state.authentication.claims.isInRoleDistrictAdmin ||
        this.$store.state.authentication.claims.isInRoleDistrictUser
    },
    formatDate (date: Date) {
      return finnishShortDate(date)
    },
    gotoMember (member: MemberSummaryResponse) {
      this.$router.push(`/sections/${member.sectionId}/members/${member.id}`)
    }
  },
  computed: {
    sortedPositions () {
      if (!this.positions) return
      return this.positions
        .filter((x: MemberPositionSummaryResponse) => {
          return x.status.id === MemberPositionStatus.ACTIVE
        })
        .sort((x: MemberPositionSummaryResponse, y: MemberPositionSummaryResponse) => {
          return y.termEnd.getTime() - x.termEnd.getTime()
        })
    }
  }
})
