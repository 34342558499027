
/*
Note, that as this date picker does not have a time component, it outputs the time as
the date at 00:00 UTC.
*/
import Vue from 'vue'
// @ts-ignore (no type def)
import TinyDatePicker from 'tiny-date-picker'
import moment from 'moment'
import 'tiny-date-picker/tiny-date-picker.css'

export default Vue.extend({
  name: 'SprDatePicker',
  inject: ['$validator'],
  props: {
    id: {
      type: String,
      default () {
        return 'sprdp-' + Math.floor(Math.random() * 1000000000)
      }
    },
    value: {
      type: Date,
      default () {
        return new Date()
      }
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    validations: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      datePicker: null
    }
  },
  mounted (this: any): void {
    const options = {
      dayOffset: 1,
      lang: {
        days: [
          this.$t('datepicker.sun'),
          this.$t('datepicker.mon'),
          this.$t('datepicker.tue'),
          this.$t('datepicker.wed'),
          this.$t('datepicker.thu'),
          this.$t('datepicker.fri'),
          this.$t('datepicker.sat')
        ],
        months: [
          this.$t('datepicker.january'),
          this.$t('datepicker.february'),
          this.$t('datepicker.march'),
          this.$t('datepicker.april'),
          this.$t('datepicker.may'),
          this.$t('datepicker.june'),
          this.$t('datepicker.july'),
          this.$t('datepicker.august'),
          this.$t('datepicker.september'),
          this.$t('datepicker.october'),
          this.$t('datepicker.november'),
          this.$t('datepicker.december')
        ],
        today: this.$t('datepicker.today'),
        clear: this.$t('datepicker.clear'),
        close: this.$t('datepicker.close')
      },
      format (date: Date) {
        return `${date.getDate()}.${(date.getMonth() + 1)}.${date.getFullYear()}`
      },
      parse (str: string) {
        const dArr = str.split('.')
        if (!(dArr.length === 3)) return new Date(str) // fallback

        const date = new Date()
        date.setFullYear(Number(dArr[2]))
        date.setMonth(Number(dArr[1]) - 1)
        date.setDate(Number(dArr[0]))
        date.setUTCHours(0)
        date.setUTCMinutes(0)
        date.setUTCSeconds(0)
        date.setUTCMilliseconds(0)
        //@ts-ignore - returns a timestamp, so isNaN works
        return isNaN(date) ? new Date() : date
      }
    }
    // @ts-ignore Datepicker stuff no ts support
    const dpid = this.id
    // @ts-ignore
    this.datePicker = TinyDatePicker(document.getElementById(dpid), options)
    // @ts-ignore
      .on('select', (_, dp: Record<string, any>) => this.onDateChanged(dp.state.selectedDate))
  },
  computed: {
    formattedDate (): string {
      const val = this.value as Date
      return `${val.getDate()}.${(val.getMonth() + 1)}.${val.getFullYear()}`
    }
  },
  methods: {
    onDateChanged (val: Date) {
      // convert to utc 00:00 date
      const d = new Date()
      d.setUTCFullYear(val.getFullYear())
      d.setUTCMonth(val.getMonth())
      d.setUTCDate(val.getDate())
      d.setUTCHours(0)
      d.setUTCMinutes(0)
      d.setUTCSeconds(0)
      d.setUTCMilliseconds(0)
      this.$emit('input', d)
    }
  }
})
