import moment from 'moment'

export function finnishDate (date: Date): string {
  const isDate = date instanceof Date && !isNaN(date.valueOf())
  if (!(isDate)) return ''

  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

export function finnishShortDate (date: Date): string {
  const isDate = date instanceof Date && !isNaN(date.valueOf())
  if (!(isDate)) return ''

  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

export function finnishShortDateAndTime (date: Date): string {
  const isDate = date instanceof Date && !isNaN(date.valueOf())
  if (!(isDate)) return ''

  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
}

export function convertToUtcZeroedDate (date: Date): Date {
  // when a user chooses a date with the datepicker, eg 13.04.2020, it returns a utc date of 12.04.2020:21:00
  // what we really want to store is just the date, so 13.04.2020:00:00.
  // This function sets this from the supplied date.
  // Warning - if you are using the time portion, this will result in bad data
  const d = new Date()
  d.setUTCFullYear(date.getFullYear())
  d.setUTCMonth(date.getMonth())
  d.setUTCDate(date.getDate())
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

export function convertUTCDateToLocalDate (date: Date): Date {
  // Convert from a UTC date into a local one, so a meeting on 12.04.2020:21:00 UTC is 13:04:2020:00:00 in local (finnish) time
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)

  const offset = date.getTimezoneOffset() / 60
  const hours = date.getHours()

  newDate.setHours(hours - offset)

  return newDate
}

export function getFirstDayOfYear (startYear?: number | undefined): Date {
  startYear = startYear || new Date().getUTCFullYear()

  const firstOfNextYear = new Date()
  firstOfNextYear.setUTCFullYear(startYear)
  firstOfNextYear.setUTCMonth(0)
  firstOfNextYear.setUTCDate(1)
  firstOfNextYear.setUTCHours(0)
  firstOfNextYear.setUTCMinutes(0)
  firstOfNextYear.setUTCSeconds(0)
  firstOfNextYear.setUTCMilliseconds(0)

  return firstOfNextYear
}

export function getLastDayOfYear (startYear?: number | undefined): Date {
  const firstDayOfYear = getFirstDayOfYear(startYear)
  firstDayOfYear.setUTCFullYear(firstDayOfYear.getUTCFullYear() + 1)
  firstDayOfYear.setDate(firstDayOfYear.getDate() - 1)

  return firstDayOfYear
}

// When you call JSON.parse() on a returned object, dates are parsed as strings.
// By using this, you can convert them automatically to dates.
// Usage: var obj = JSON.parse(json, JsonDateParser)
export function JsonDateParser (key: string, value: any): string | Date | any {
  const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.?\d*))(?:Z|(\+|-)([\d|:]*))?$/
  const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/

  if (typeof value === 'string') {
    let a = reISO.exec(value)
    if (a) {
      return new Date(value)
    }
    a = reMsAjax.exec(value)
    if (a) {
      const b = a[1].split(/[-+,.]/)
      return new Date(b[0] ? +b[0] : 0 - +b[1])
    }
  }
  return value
}