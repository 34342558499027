
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import moment from 'moment'
import SprButton from '@/components/forms/SprButton.vue'
import SprFormDatePicker from '@/components/forms/SprFormDatePicker.vue'
import MemberPositionForm from '@/components/meetings/MemberPositionForm.vue'
import { MemberPositionResponse, UpdateMemberPositionRequest } from '@/types'
import { BoardMemberData, MemberPositionFormData } from '@/types/custom.ts'
import { CodeCategory } from '@/types/enums'

export default Vue.extend({
  name: 'MeetingMemberList',
  components: { SprButton, MemberPositionForm },
  props: {
    value: {
      type: Array as () => Array<MemberPositionResponse>,
      default: undefined
    },
    sectionId: {
      type: Number,
      required: true
    },
    meetingId: {
      type: Number,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: true
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      shownRow: undefined as undefined | number,
      isEditing: false,
      memberPositionData: {
        memberId: undefined,
        positionId: undefined,
        nonMemberAddress: '',
        nonMemberPostcode: '',
        nonMemberPostoffice: '',
        nonMemberEmail: '',
        nonMemberFirstname: '',
        nonMemberLastname: '',
        nonMemberCompany: '',
        nonMemberPhone: '',
        termEnd: new Date(),
        termStart: new Date()
      } as MemberPositionFormData
    }
  },
  computed: {
    updateMemberRequest (this: any) {
      return {
        id: this.shownRow,
        memberId: this.memberPositionData.memberId,
        meetingId: this.meetingId,
        nonMember: {
          address: this.memberPositionData.nonMemberAddress,
          postcode: this.memberPositionData.nonMemberPostcode,
          postoffice: this.memberPositionData.nonMemberPostoffice,
          email: this.memberPositionData.nonMemberEmail,
          firstname: this.memberPositionData.nonMemberFirstname,
          lastname: this.memberPositionData.nonMemberLastname,
          company: this.memberPositionData.nonMemberCompany,
          phone: this.memberPositionData.nonMemberPhone
        },
        termEnd: this.memberPositionData.termEnd,
        termStart: this.memberPositionData.termStart
      } as UpdateMemberPositionRequest
    }
  },
  methods: {
    dateFormat (date: Date): string {
      return moment(date).format('D.M.YYYY')
    },
    termOrdinalFormat (ordinal: string): string {
      return `${this.$t('memberposition.termordinal')}: ${ordinal}.`
    },
    getMembershipStatus (statusId: number): TranslateResult {
      return this.$t(`membershipstatus-${statusId}`)
    },
    getPositionIdName (type: number): TranslateResult {
      return this.$t(`positionId-${type}`)
    },
    viewMember (this: any, id: number) {
      this.isEditing = false
      if (this.shownRow !== id) {
        this.shownRow = id
      } else {
        this.shownRow = null
      }
    },
    editMember (this: any, id: number) {
      this.isEditing = true
      if (this.shownRow !== id) {
        this.shownRow = id

        const memberPos = this.value.find((x: MemberPositionResponse) => x.id === this.shownRow) as MemberPositionResponse
        if (memberPos) {
          this.memberPositionData = {
            memberId: memberPos.memberId,
            positionId: memberPos.positionId,
            nonMemberCompany: memberPos.nonMember.company,
            nonMemberAddress: memberPos.nonMember.address,
            nonMemberPostcode: memberPos.nonMember.postcode,
            nonMemberPostoffice: memberPos.nonMember.postoffice,
            nonMemberEmail: memberPos.nonMember.email,
            nonMemberFirstname: memberPos.nonMember.firstName,
            nonMemberLastname: memberPos.nonMember.lastName,
            nonMemberPhone: memberPos.nonMember.phone,
            termEnd: memberPos.termEnd,
            termStart: memberPos.termStart
          }
        }
      }
    },
    showHideMoreDetails (this: any, id: number) {
      this.shownRow = (this.shownRow === id) ? undefined : id
    },
    isRowShown (this: any, id: number): boolean {
      return this.shownRow === id
    },
    removeMember (mempos: MemberPositionResponse): void {
      if (confirm(this.$t('meetingmemberlist.removeconfirm').toString())) {
        const removalData = {
          id: mempos.id,
          positionId: mempos.position?.id,
          memberId: mempos.member?.id
        } as BoardMemberData
        this.$emit('remove-member', removalData)
      }
    },
    onRemove () {
      if (!this.shownRow) return
      const mempos = this.value.find((x: MemberPositionResponse) => {
        return x.id === this.shownRow
      })
      if (mempos) {
        this.removeMember(mempos)
        this.isEditing = false
        this.shownRow = undefined
      }
    },
    onSave () {
      this.$validator.validateAll().then((result: boolean) => {
        if (result) {
          this.$emit('update-memberposition', this.updateMemberRequest)
          this.isEditing = false
          this.shownRow = undefined
        }
      })
    },
    onCancel () {
      this.isEditing = false
      this.shownRow = undefined
    }
  }
})
