
import Vue from 'vue'

export default Vue.extend({
  name: 'SprFormFileUpload',
  props: {
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onFileChange (e: Event) {
      const target = e.target as HTMLInputElement
      if (!target || !target.files) return
      const files = target.files
      if (!files.length) return
      this.$emit('file-uploaded', files[0])
      target.files = null
      target.value = ''
    }
  }
})
