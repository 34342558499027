
import Vue from 'vue'
import { StringArray, ADLoginInfo } from '@/types/custom'
import { mainService } from '@/services/main.service'
interface ADDataObject {
  adState: StringArray | null
}

export default Vue.extend({
  name: 'ADResponse',
  created () {
    this.adState = this.getAdState()
    if (this.adState && this.adState['id_token'] && this.adState['session_state']) {
      const data: ADLoginInfo = {
        token: this.adState['id_token'],
        sessionState: this.adState['session_state']
      }
      this.$store.dispatch('authentication/adlogin', data).then(success => {
        mainService.handleAfterAuthenticated().then(() => {
          this.$router.push('/')
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        if ((err + '').includes('Auth failed')) {
          this.$store.dispatch('alert/error', { message: this.$t('login.noroles'), surviveRouteChange: true, isHtml: true })
        } else {
          //this.$store.dispatch('alert/error', { message: this.$t('login.noroles'), surviveRouteChange: true, isHtml: true })
          this.$store.dispatch('alert/error', { message: this.$t('login.loginfailed'), surviveRouteChange: true })
        }
        //this.$store.dispatch('alert/error', { message: this.$t('login.loginfailed'), surviveRouteChange: true })
        this.$store.dispatch('authentication/loginFailure', err)
        this.$router.push('/login')
      })
    } else {
      this.$store.dispatch('alert/error', { message: this.$t('login.loginfailed'), surviveRouteChange: true })
      this.$store.dispatch('authentication/loginFailure')
      this.$router.push('/login')
    }
  },
  data (): ADDataObject {
    return {
      adState: null
    }
  },
  methods: {
    getAdState () {
      const uri = window.location.href
      if (!uri || uri.indexOf('#') < 0) {
        return null
      }

      const fragment = uri.split('#')[1]

      if (!fragment || fragment.indexOf('id_token=') < 0) {
        return null
      }

      const params = fragment.split('&')
      const rv: StringArray = {}
      params.forEach(function (val) {
        const pair = val.split('=')
        if (pair.length === 2) {
          rv[pair[0]] = pair[1]
        }
      })

      return rv
    }
  }
})
