import { ErrorResult, RequestValidationResult } from '@/types'
import { Validator } from 'vee-validate'
import { TranslateResult } from 'vue-i18n'

export function formatErrors (errs: Array<TranslateResult> | Array<string>): string {
  let htmlString = '<ul>'
  for (const err of errs) {
    htmlString += `<li>${err}</li>`
  }
  htmlString += '</ul>'
  return htmlString
}

const DateValidate = {
  FUTURE: -1,
  PAST: 1
}

const finnishDateStringToDate = (value: string): Date | undefined => {
  // comes in in dd.mm.yyyy format
  const dArr = value.split('.')
  if (dArr.length !== 3) return
  if (dArr.find((x: string) => isNaN(Number(x)))) { return }

  return new Date(Number(dArr[2]), Number(dArr[1]) - 1, Number(dArr[0]), 0, 0, 0, 0)
}

const getCompareDate = (d: Date, dateValidationType: number) => {
  // Get a date and add or subtract a day, depending on type
  const compare = new Date()
  compare.setDate(compare.getDate() + dateValidationType)
  compare.setHours(0)
  compare.setMinutes(0)
  compare.setSeconds(0)
  compare.setMilliseconds(0)

  return compare
}

const dateInPast = (value: string): boolean => {
  const d = finnishDateStringToDate(value)
  if (!d) return false

  const compare = getCompareDate(d, DateValidate.PAST)
  return (d.getTime() - compare.getTime()) < 0
}

const dateInFuture = (value: string): boolean => {
  const d = finnishDateStringToDate(value)
  if (!d) return false

  const compare = getCompareDate(d, DateValidate.FUTURE)
  return (compare.getTime() - d.getTime()) < 0
}

// Add a rule to vee-validate to validate that a date is not in the future.
// Usage: <input v-model="model" v-validate="finnishdateinpast" />
Validator.extend('finnishdateinpast', dateInPast)

// Usage: <input v-model="model" v-validate="finnishdateinfuture" />
Validator.extend('finnishdateinfuture', dateInFuture)
