
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { MemberRoles } from '@/types/enums'
import SprFormCheckbox from '@/components/forms/SprFormCheckbox.vue'

export default Vue.extend({
  name: 'MemberRoleDetails',
  components: { SprFormCheckbox },
  props: {
    value: {
      type: Array as () => Array<number>,
      default: () => []
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    isSaveInProgress: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      MemberRoles: MemberRoles
    }
  },
  computed: {
    isSuperAdmin (): boolean {
      return this.value.indexOf(MemberRoles.SUPER_ADMIN) > -1
    },
    isKT (): boolean {
      return this.value.indexOf(MemberRoles.KT) > -1
    },
    isDistrictAdmin (): boolean {
      return this.value.indexOf(MemberRoles.DISTRICT_ADMIN) > -1
    },
    isDistrictUser (): boolean {
      return this.value.indexOf(MemberRoles.DISTRICT_USER) > -1
    },
    isSectionAdmin (): boolean {
      return this.value.indexOf(MemberRoles.SECTION_ADMIN) > -1
    },
    isSectionUser (): boolean {
      return this.value.indexOf(MemberRoles.SECTION_USER) > -1
    },
    maxUserRole (): number {
      const userRoles = this.$store.state.authentication.rolesArray
      return Math.min(...userRoles)
    }
  },
  methods: {
    isReadOnlyCheckbox (memberRole: MemberRoles): boolean {
      return this.isSaveInProgress || (this.maxUserRole > memberRole)
    },
    getRole (roleId: number): TranslateResult {
      return this.$t(`role-${roleId}`)
    },
    toggleRole (roleId: number): void {
      const addOrRemove = (arr: Array<number>, item: number) => arr.includes(roleId) ? arr.filter((i: number) => i !== item) : [...arr, item]
      const clone = [].slice.call(this.value)
      this.$emit('input', addOrRemove(clone, roleId))
    }
  }
})
