
import Vue from 'vue'

export default Vue.extend({
  name: 'StatisticTile',
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    subtext: {
      type: String,
      default: ''
    }
  },
  methods: {
    statClicked (this: any) {
      this.$emit('click', this.name)
    }
  }
})
