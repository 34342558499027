import { membersService } from '../services'
import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { FindMemberRequest, PagedListResponse, MemberSummaryResponse } from '@/types'

export interface MemberState {
    filteredMembers: PagedListResponse<MemberSummaryResponse> | undefined,
    findDistrictMembersUrl: string,
    findSectionMembersUrl: string,
    findAllUrl: string,
    findBySectionIdUrl: string,
    getByIdUrl: string,
    getAllSectionMembersUrl: string,
    getAllDistrictMembersUrl: string,
    getDistrictMembersExportFileUrl: string,
    getSectionMembersExportFileUrl: string,
    updateRolesUrl: string
}

const getDefaultState = (): MemberState => {
  return {
    filteredMembers: undefined,
    getAllSectionMembersUrl: '/api/members/getallsectionmembers',
    getAllDistrictMembersUrl: '/api/members/getalldistrictmembers',

    findDistrictMembersUrl: '/api/members/finddistrictmembers',
    findSectionMembersUrl: '/api/members/findsectionmembers',
    findAllUrl: '/api/members/findall',
    findBySectionIdUrl: '/api/members/findbysectionid/{id}',
    getByIdUrl: '/api/members/getbyid/{id}',
    getDistrictMembersExportFileUrl: '/api/members/getdistrictexportfile',
    getSectionMembersExportFileUrl: '/api/members/getsectionexportfile',
    updateRolesUrl: '/api/members/updateRoles'
  }
}

export const state = getDefaultState()

const actions: ActionTree<MemberState, RootState> = {
  getAllSectionMembers (context, filter: FindMemberRequest) {
    context.commit('findRequest')

    return new Promise<void>((resolve, reject) => {
      membersService.getAllSectionMembers(filter)
        .then(
          (filteredMembers: PagedListResponse<MemberSummaryResponse> | Error) => {
            if (filteredMembers instanceof Error) {
              reject(Error('Failed to find members'))
            }
            context.commit('findSuccess', filteredMembers)
            resolve()
          }
        ).catch(error => {
          context.commit('findFailure', error)
          reject(error)
        })
    })
  },
  getAllDistrictMembers (context, filter: FindMemberRequest) {
    context.commit('findRequest')

    return new Promise<void>((resolve, reject) => {
      membersService.getAllDistrictMembers(filter)
        .then(
          (filteredMembers: PagedListResponse<MemberSummaryResponse> | Error) => {
            if (filteredMembers instanceof Error) {
              reject(Error('Failed to find members'))
            }
            context.commit('findSuccess', filteredMembers)
            resolve()
          }
        ).catch(error => {
          context.commit('findFailure', error)
          reject(error)
        })
    })
  },
  findDistrictMembers (context, filter: FindMemberRequest) {
    context.commit('findRequest')

    return new Promise<void>((resolve, reject) => {
      membersService.findDistrictMembers(filter)
        .then(
          (filteredMembers: PagedListResponse<MemberSummaryResponse> | Error) => {
            if (filteredMembers instanceof Error) {
              reject(Error('Failed to find members'))
            }
            context.commit('findSuccess', filteredMembers)
            resolve()
          }
        ).catch(error => {
          context.commit('findFailure', error)
          reject(error)
        })
    })
  },
  findSectionMembers (context, filter: FindMemberRequest) {
    context.commit('findRequest')

    return new Promise<void>((resolve, reject) => {
      membersService.findSectionMembers(filter)
        .then(
          (filteredMembers: PagedListResponse<MemberSummaryResponse> | Error) => {
            if (filteredMembers instanceof Error) {
              reject(Error('Failed to find members'))
            }
            context.commit('findSuccess', filteredMembers)
            resolve()
          }
        ).catch(error => {
          context.commit('findFailure', error)
          reject(error)
        })
    })
  }

}

const mutations: MutationTree<MemberState> = {

  findRequest (state) {
    state.filteredMembers = undefined
  },

  findSuccess (state, searchresult) {
    state.filteredMembers = searchresult
  },

  findFailure (state, error) {
    state.filteredMembers = undefined
  },

  reset (state) {
    Object.assign(state, getDefaultState())
  }
}

export const members: Module<MemberState, RootState> = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations
}
