
import Vue from 'vue'

export default Vue.extend({
  name: 'Back',
  methods: {
    onBack () {
      this.$router.go(-1)
    }
  }
})
