
import Vue from 'vue'
import SprFormDropdown from '@/components/forms/SprFormDropdown.vue'
import SprFormCheckboxList from '@/components/forms/SprFormCheckboxList.vue'
import SprFormDatePicker from '@/components/forms/SprFormDatePicker.vue'
import { SectionSummaryResponse, Code } from '@/types'
import { MeetingFilter, DropdownOptions, CheckboxListItem } from '@/types/custom.ts'

export default Vue.extend({
  name: 'MeetingsFilter',
  components: { SprFormDropdown, SprFormCheckboxList, SprFormDatePicker },
  props: {
    filter: {
      type: Object as () => MeetingFilter,
      required: true
    },
    sections: {
      type: Array as () => Array<SectionSummaryResponse>,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      originalFilter: {} as MeetingFilter,
      filterChanged: false
    }
  },
  created (this: any) {
    this.originalFilter = Object.assign({}, this.filter)
    this.originalFilter.statusIds = this.filter.statusIds.map((x: number) => x) // otherwise this array is passed by ref
  },
  computed: {
    sectionLabel () {
      return this.$t('meeting.section')
    },
    typeLabel () {
      return this.$t('meeting.type')
    },
    statusLabel () {
      return this.$t('meeting.status')
    },
    meetingDateLabel () {
      return this.$t('meeting.dateheld')
    },
    clearLabel () {
      return this.$t('meeting.clearfilter')
    },
    filterSectionIdStr () {
      return this.filter.sectionId?.toString()
    },
    filterTypeIdStr () {
      return this.filter.typeId?.toString()
    },
    sectionData (this: any) {
      const sections = this.sections.map((x: SectionSummaryResponse) => { return { text: x.name, value: x.id.toString() } as DropdownOptions })
      sections.splice(0, 0, { text: this.$t('meetinglist.choose'), value: '0' } as DropdownOptions)
      return sections
    },
    typeData (this: any) {
      const types = this.$store.state.codes.meetingTypes.map((x: Code) => { return { text: this.$t(`meetingtype-${x.id}`), value: x.id.toString() } as DropdownOptions })
      types.splice(0, 0, { text: this.$t('meetinglist.choose'), value: '0' } as DropdownOptions)
      return types
    },
    statusData (this: any): Array<CheckboxListItem> {
      return this.$store.state.codes.meetingStatuses.map((x: Code) => { return { label: this.$t(`meetingstatus-${x.id}`), id: x.id, checked: this.filter.statusIds.indexOf(x.id) > -1 } as CheckboxListItem })
    }
  },
  methods: {
    onSectionChosen (this: any, val: string) {
      const newFilter = Object.assign({}, this.filter)
      newFilter.sectionId = Number(val)
      this.sendUpdate(newFilter)
    },
    onTypeChosen (this: any, val: string) {
      const newFilter = Object.assign({}, this.filter)
      newFilter.typeId = Number(val)
      this.sendUpdate(newFilter)
    },
    onMeetingDateChosen (this: any, val: Date) {
      const newFilter = Object.assign({}, this.filter)
      newFilter.meetingDate = val
      this.sendUpdate(newFilter)
    },
    onStatusClicked (this: any, val: CheckboxListItem) {
      const newFilter = Object.assign({}, this.filter)
      const existingIndex = newFilter.statusIds.indexOf(Number(val.id))

      if (val.checked) {
        if (existingIndex === -1) {
          newFilter.statusIds.push(Number(val.id))
        }
      } else {
        if (existingIndex >= 0) {
          newFilter.statusIds.splice(existingIndex, 1)
        }
      }

      this.sendUpdate(newFilter)
    },
    onClear (this: any) {
      // reset
      const clone = Object.assign({}, this.originalFilter)
      clone.statusIds = this.originalFilter.statusIds.map((x: number) => x)
      this.sendUpdate(clone)
    },
    sendUpdate (this: any, newFilter: MeetingFilter) {
      this.filterChanged = true
      this.$emit('FilterChanged', newFilter)
    }
  }
})
