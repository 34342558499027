// import config from 'config'
import store from '../store'
import authHeader from '../helpers/auth/auth-header'
import { handleErrors, processJson } from '../helpers/httphelper'
import { DistrictActiveRoles, DistrictSummaryResponse, DistrictStatisticsResponse } from '@/types'

function getAll (): Promise<Array<DistrictSummaryResponse>> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.districts.allUrl}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      (districts: Array<DistrictSummaryResponse>) => {
        return districts
      }
    )
}

function getDistrictRolesById (id: number): Promise<DistrictActiveRoles> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.districts.getDistrictRolesByIdUrl}`.replace('{id}', id.toString())

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      district => {
        return district
      }
    )
}

function getStats (districtId: number): Promise<DistrictStatisticsResponse> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.districts.statsUrl}`.replace('{id}', districtId.toString())

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      (stats: DistrictStatisticsResponse) => {
        return stats
      }
    )
}

function resetDistrict (districtId: number): Promise<boolean> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.districts.resetUrl}`.replace('{id}', districtId.toString())

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      () => true
    )
}

export const districtService = {
  getAll,
  getStats,
  resetDistrict,
  getDistrictRolesById
}
