
import Vue from 'vue'
import Back from '@/components/Back.vue'
import { TranslateResult } from 'vue-i18n'
import { DistrictSummaryResponse, SectionSummaryResponse, Code } from '@/types'
import { CodeCategory, DistrictStatus, SectionStatus } from '@/types/enums'

export default Vue.extend({
  name: 'Districts',
  components: {
    Back
  },
  computed: {
    districts (this: any): Array<DistrictSummaryResponse> {
      return this.$store.state.districts.all
    },
    sortedDistricts () {
      return this.districts.slice(0).sort((a: DistrictSummaryResponse, b: DistrictSummaryResponse) => {
        return a.name.localeCompare(b.name, 'fi', { sensitivity: 'base' })
      })
    }
  },
  methods: {
    onDistrictClicked (district: DistrictSummaryResponse): void {
      this.$router.push(`/districts/${district.id}`)
    },
    onSectionClicked (section: SectionSummaryResponse): void {
      this.$router.push(`/sections/${section.id}`)
    },
    getSections (districtId: number) {
      return Object.assign({}, this.$store.getters['sections/getByDistrictId'](districtId))
    },
    getSectionStatusTranslation (statusId: number): TranslateResult {
      return this.$t(`sectionstatus-${statusId}`)
    },
    isActive (status: Code): boolean {
      return status.id === SectionStatus.ACTIVE
    },
    getDistrictClass (this: any, statusId: number): string {
      switch (statusId) {
        case DistrictStatus.ACTIVE:
          return 'status-active'

        case DistrictStatus.DISBANDED:
          return 'status-discontinued'
      }

      return ''
    },
    getSectionClass (this: any, statusId: number): string {
      switch (statusId) {
        case SectionStatus.ACTIVE:
          return 'status-active'

        case SectionStatus.MONITORED:
          return 'status-observing'

        case SectionStatus.DISBANDED:
          return 'status-discontinued'
      }

      return ''
    }
  }
})
