import { Module, ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store'

export interface ConfigState {
  ossiServiceBaseUrl: string,
  useMockUserBackend: boolean,
  language: string | null
}

export const state: ConfigState = {
  ossiServiceBaseUrl: 'https://spr-ossi-live-service.azurewebsites.net',
  // ossiServiceBaseUrl: 'https://spr-ossi-test-service.azurewebsites.net',
  // ossiServiceBaseUrl: 'http://localhost:53189', // The base url for accessing the back end service
  useMockUserBackend: false, // Whether to use a mock back end and fake the login (for testing) or use the live one configured above
  language: sessionStorage.getItem('locale') ? sessionStorage.getItem('locale') : 'fi'
}

const actions: ActionTree<ConfigState, RootState> = {
}

const mutations: MutationTree<ConfigState> = {
  setLanguage (state, lang) {
    state.language = lang
  }
}

export const config: Module<ConfigState, RootState> = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations
}
