// import config from 'config'
import store from '../store'
import authHeader from '../helpers/auth/auth-header'
import { handleErrors, processJson } from '../helpers/httphelper'
import {
  MeetingDetailResponse,
  MeetingMinutesRequest,
  MeetingSummaryResponse,
  MinutesFileResponse,
  GetMeetingsRequest,
  AddOrUpdateMeetingRequest,
  AddMemberPositionRequest,
  UpdateMemberPositionRequest,
  MemberPositionResponse,
  MinutesResponse,
  MinutesFileRequest,
  PositionResponse,
  MemberPositionErrorResponse,
  RequestValidationResult,
  CheckTermLengthRequest,
  CheckSerialTermLimitsRequest,
  CheckNonMemberSerialTermLimitsRequest
} from '@/types'

function mapAddUpdateMeetingRequest (meeting: MeetingDetailResponse) {
  return {
    id: meeting.id,
    sectionId: meeting.sectionId,
    name: meeting.name,
    meetingTypeId: meeting.type.id,
    dateHeld: meeting.dateHeld,
    reportedBy: meeting.reportedBy,
    createdBy: meeting.createdBy,
    moreInfo: meeting.moreInfo
  } as AddOrUpdateMeetingRequest
}

function addMeeting (meeting: AddOrUpdateMeetingRequest): Promise<MeetingDetailResponse> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(meeting)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.addMeetingUrl}`
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(meeting => {
      return meeting as MeetingDetailResponse
    }).catch(err => Promise.reject(err))
}

function updateMeeting (meeting: MeetingDetailResponse): Promise<boolean | Error> {
  const meetingData = mapAddUpdateMeetingRequest(meeting)
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(meetingData)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.updateMeetingUrl}`
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(response => {
      return Promise.resolve(true)
    }).catch(err => Promise.reject(err))
}

function deleteMeeting (id: number): Promise<void> {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: id.toString()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.deleteMeetingUrl}`
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(() => {
      return Promise.resolve()
    }).catch(err => Promise.reject(err))
}

function submitMeeting (id: number): Promise<boolean> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: id.toString()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.submitMeetingUrl}`
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(success => {
      return success as boolean
    }).catch(err => Promise.reject(err))
}

function reviewedMeeting (id: number): Promise<boolean> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: id.toString()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.reviewedMeetingUrl}`
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(success => {
      return success as boolean
    }).catch(err => Promise.reject(err))
}
function archivedMeeting (id: number): Promise<boolean> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: id.toString()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.archivedMeetingUrl}`
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(success => {
      return success as boolean
    }).catch(err => Promise.reject(err))
}

function approveMeeting (id: number): Promise<boolean> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: id.toString()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.approveMeetingUrl}`
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(success => {
      return Promise.resolve(success as boolean)
    }).catch(err => Promise.reject(err))
}

function addOrUpdateMeetingMinutes (minutes: MeetingMinutesRequest): Promise<MinutesResponse> {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(minutes)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.addOrUpdateMeetingMinutesUrl}`
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(processJson)
      .then(success => {
        success ? resolve(success as MinutesResponse) : reject(Error('Failed to update minutes'))
      }).catch(err => {
        reject(err)
      })
  })
}

function getMeeting (id: number): Promise<MeetingDetailResponse | Error> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.getMeetingUrl}`.replace('{meetingid}', id.toString())
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      meeting => {
        return (meeting as MeetingDetailResponse)
      }
    ).catch(err => Promise.reject(err))
}

function uploadDoc (this: any, doc: MinutesFileRequest): Promise<MinutesFileResponse> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(doc)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.uploadDocUrl}`
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      doc => {
        return (doc as MinutesFileResponse)
      }
    ).catch(err => Promise.reject(err))
}

function removeDoc (this: any, docId: number): Promise<boolean | Error> {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.removeDocUrl}`.replace('{id}', docId.toString())
  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      (result: boolean) => {
        return (result as boolean)
      }
    ).catch(err => Promise.reject(err))
}

function getMeetings (filter: GetMeetingsRequest): Promise<Array<MeetingSummaryResponse> | Error> {
  // todo : Return only meetings in the section (filter.sectionId) that this user is allowed to see
  // be sure to verify on the back end from the claim of the auth token!
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(filter)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.getMeetingsUrl}`

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then(handleErrors)
      .then(processJson)
      .then(
        meetings => {
          resolve(meetings as Array<MeetingSummaryResponse>)
        },
        fail => {
          reject(fail)
        }
      ).catch(err => {
        reject(err)
      })
  })
}

function addPositionOfTrust (memberPosition: AddMemberPositionRequest): Promise<MemberPositionResponse | Error> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(memberPosition)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.addPositionOfTrustUrl}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      position => {
        return (position as MemberPositionResponse)
      }
    ).catch(err => Promise.reject(err))
}

function removePositionOfTrust (id: number): Promise<boolean | Error> {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.removePositionOfTrustUrl.replace('{id}', id.toString())}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      (ok) => ok as boolean
    ).catch(err => Promise.reject(err))
}

function updatePositionOfTrust (memberPosition: UpdateMemberPositionRequest): Promise<MemberPositionResponse> {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(memberPosition)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.updatePositionOfTrustUrl}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(position => (position as MemberPositionResponse))
    .catch(err => Promise.reject(err))
}

function canEditMeeting (id: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.canEditMeetingUrl.replace('{id}', id.toString())}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(canedit => (canedit as boolean))
    .catch(err => Promise.reject(err))
}

function getAllPositionsOfTrust (): Promise<Array<PositionResponse>> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.getAllPositionsOfTrustUrl}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      result => {
        return (result as Array<PositionResponse>)
      }
    ).catch(err => Promise.reject(err))
}

function getDistrictCrmPositionsErrors (districtId: number): Promise<Array<MemberPositionErrorResponse>> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.getDistrictCrmPositionsErrorsUrl}`.replace('{districtId}', districtId.toString())

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      result => {
        return (result as Array<MemberPositionErrorResponse>)
      }
    ).catch(err => Promise.reject(err))
}

function checkTermLength (request: CheckTermLengthRequest): Promise<RequestValidationResult> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(request)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.checkTermLengthUrl}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      result => {
        return (result as RequestValidationResult)
      }
    ).catch(err => Promise.reject(err))
}

function checkAddSerialTermLimits (request: CheckSerialTermLimitsRequest): Promise<RequestValidationResult> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(request)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.checkAddSerialTermLimitsUrl}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      result => {
        return (result as RequestValidationResult)
      }
    ).catch(err => Promise.reject(err))
}

function checkAddNonMemberSerialTermLimits (request: CheckNonMemberSerialTermLimitsRequest): Promise<RequestValidationResult> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(request)
  }

  const url = `${store.state.config.ossiServiceBaseUrl}${store.state.meetings.checkAddNonMemberSerialTermLimitsUrl}`

  return fetch(url, requestOptions)
    .then(handleErrors)
    .then(processJson)
    .then(
      result => {
        return (result as RequestValidationResult)
      }
    ).catch(err => Promise.reject(err))
}

export const meetingService = {
  addMeeting,
  updateMeeting,
  deleteMeeting,
  submitMeeting,
  reviewedMeeting,
  archivedMeeting,
  approveMeeting,
  addOrUpdateMeetingMinutes,
  getMeeting,
  uploadDoc,
  removeDoc,
  getMeetings,
  addPositionOfTrust,
  removePositionOfTrust,
  updatePositionOfTrust,
  canEditMeeting,
  getAllPositionsOfTrust,
  getDistrictCrmPositionsErrors,
  checkTermLength,
  checkAddSerialTermLimits,
  checkAddNonMemberSerialTermLimits
}
